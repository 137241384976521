import { FilterOperators } from "@crispico/foundation-gwt-js";
import { QueryOptions } from "apollo-client";
import gql from "graphql-tag";
import { default as lodash, uniqueId } from "lodash";
import React, { ReactElement } from "react";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbSectionProps, Button, Checkbox, Dimmer, Divider, Icon, Input, Loader, Menu, MenuItemProps, Message, Modal, Segment, SemanticShorthandCollection } from "semantic-ui-react";
import { apolloClient, apolloClientHolder, ApolloContext, CatchedGraphQLError } from "../apolloClient";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { ColumnConfigDropdown, COLUMN_CONFIG_DROPDOWN_MODE, getColumnConfigForClient, sliceColumnConfigDropdown, ColumnConfigDropdownProps } from "../components/ColumnConfig/ColumnConfigDropdown";
import { ColumnConfigDropdownSource, ColumnConfigSource } from "../components/ColumnConfig/dataStructures";
import { DEFAULT_COMPOSED_FILTER } from "../components/CustomQuery/BlocklyEditorTab";
import { ClientCustomQuery } from "../components/CustomQuery/ClientCustomQuery";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "../components/CustomQuery/CustomQueryBar";
import { CustomQueryDefinition } from "../components/CustomQuery/CustomQueryDropdown";
import { Filter } from "../components/CustomQuery/Filter";
import { Sort } from "../components/CustomQuery/SortBar";
import { FileExportButtonRRC, FileExportButton, FILE_EXPORTER_MODE } from "../components/fileExportButton/FileExportButton";
import { FileImportButtonRRC, FileImportButton, FILE_IMPORTER_MODE } from "../components/fileImportButton/FileImportButton"
import { ModalExt, Severity } from "../components/ModalExt/ModalExt";
import { TabbedPageProps, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo, SliceFoundation, } from "../reduxHelpers";
import { TestUtils } from "../utils/TestUtils";
import { DELETE_ALL_FILTERED_ROWS_MENU_ENTRY, ENTITY, ENT_AUDIT, ENT_DELETE, ENT_READ, ENT_TABLE, FIELDS_WRITE, Utils } from "../utils/Utils";
import { AbstractCrudPage, AbstractCrudPageProps } from "./AbstractCrudPageNew";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { EDITOR_PAGE_ICON, entityDescriptors, ID, TABLE_PAGE_ICON } from "./entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { ADD, DUPLICATE_FROM_ID_SEARCH_PARAM } from "./EntityEditorPage";
import { ColumnDefinition, EntityTableCommonProps, EntityTableSimpleRRC, EntityTableSimpleProps, EntityTableSimple, ITableActionParamForRun } from "./EntityTableSimple";
import { FindByFilterParams } from "./FindByFilterParams";
import { ShareLinkLogic } from "./ShareLinkLogic";
import Interweave from "interweave";
import { AggregateFunctionInput } from "../apollo-gen-foundation/globalTypes";
import { RefreshButtonRRC } from "../components/RefreshButton/RefreshButton";
import { CsvExporter, CsvExporterRRC } from "../components/csvExporter/CsvExporter";
import { EntityType } from "../components/multiCsvEditor/MultiCsvEditor";
import _ from "lodash";
import { Optional } from "../CompMeta";
import { v4 as uuid } from 'uuid';
import { EntityEditorInline } from "./EntityEditorInline";
import { EntityDescriptorForServerUtils } from "../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { TableProps } from "fixed-data-table-2";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import { ScriptableUiImpl } from "@famiprog-foundation/scriptable-ui";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "../reduxReusableComponents/ReduxReusableComponents";

export const INDEX = "_index";

export enum EntityTableMode {
    NORMAL, CARDS
}

let HistoryCompare: any;
let sliceHistoryCompare: SliceFoundation;

init();

async function init() {
    const historyComparePage = await import("../pages/HistoryCompare/HistoryCompare");
    HistoryCompare = historyComparePage.HistoryCompare;
    sliceHistoryCompare = historyComparePage.sliceHistoryCompare;
}

export type EntityTableOptions = {
    /**
     * Number of entitites to load in bulk; needed for infinite scroll/progressive loading
     * default: 100
     */
    bulkSize: number,
    /**
     * Tells when to start loading another bulk of entities.
     * If we want to load when the last row is visible in table, then value = 0.
     * If we want to load before reaching the last row, then value > x (x = number of rows)
     * default: 50
     */
    rowOffsetStartLoadingBulk: number,
    /**
     * If true, table counts and displays the number of total entities.
     * If false, tables displays only loaded number of entities.
     * default: true
     */
    countMode: boolean,
}

type ShareLinkResult = {
    link: string | undefined,
    error: boolean
};

export class EntityTablePageState extends State {

    mostRecentStartIndex: number = -1; // needed for loading entities in bulks
    loaded: number = -1; // number of loaded entities
    totalCount: number = -1;
    initialLoaded: number = 0;
    initialTotalCount: number = 0;
    totalAdjustment: number = 0;
    /**
     * When new data arrives from the server, if it contains records that are already displayed: we'll discard them.
     * This may happen if the user has kept the screen open e.g. 2 minutes, he scrolls and meanwhile somebody else has
     * added records that will "shift" the current records below. This is more likely to happen when we display data
     * sorted by date/desc, and meanwhile new records have been added. But in theory may happen for all types of filters.
     */
    loadedIds: any = {};
    confirmDeleteEntity: boolean = false;

    /**
     * Heads up! The primary info exists as the prop "oneToMany" of the component. We save it in the state, i.e. create a 
     * bit of duplication because
     * otherwise it would mean a lot of modifications (i.e. all methods should somehow receive the props of the comp).
     * This is not a very good practice. Is almost like a global var in our case.
     */
    oneToManyModeCachedField: string | undefined = undefined;
    oneToManyModeCachedId: any = undefined;
    oneToManyModeEntityDescriptorName: string | undefined = undefined;
    shareLinkResult: ShareLinkResult = { link: undefined, error: false };
    goToEditIfSingleEntity: boolean = false;
    compactBarModal: boolean | [number, number] = false;
    compactBarMenuModal: boolean | [number, number] = false;
    confirmDeleteByFilter: number | undefined = undefined;
    confirmDeleteByFilterInput: string = "";
    openModalExportAsCsv: boolean = false;
    refreshUUID: Optional<string> = undefined;
    actionParam: ITableActionParamForRun | undefined = undefined;
}

export class EntityTablePageReducers<S extends EntityTablePageState = EntityTablePageState> extends Reducers<S> {

    closeCompactBarMenuModal() {
        this.s.compactBarMenuModal = false;
    }

    closeCompactBarModal() {
        this.s.compactBarModal = false;
    }
}

export type EntityTablePagePartialProps = TabbedPageProps & EntityTableCommonProps & AbstractCrudPageProps & {
    hideActionsBar?: boolean,
    hideActionsCell?: boolean,
    screen?: string,
    oneToManyMode?: OneToManyMode,
    pageHeaderClassName?: string,
    itemsHidedFromCell?: string[]
};

export type EntityTablePageProps = RRCProps<EntityTablePageState, EntityTablePageReducers> & EntityTablePagePartialProps

export type OneToManyMode = {
    field: string,
    entity: any,
    entityDescriptor: EntityDescriptor,
    entityField?: string,
    sort?: Sort | Sort[],
    /**
     * If the filter is not composed with `AND`, it wouldn't be displayed in CQ form (`Basic sort/filter` tab) 
     */
    filter?: Filter
};

export const entityTablePageTestids = FoundationUtils.createTestids("EntityTablePage", {
    tableMenu: "", openModal: "",
});

export namespace ScriptableUiEntityTablePage {
    export class Main extends ScriptableUi<Main> {
        getComponentName() { return "ScriptableUiEntityTablePage.Main" };
    }
}

export class EntityTablePage<P extends EntityTablePageProps = EntityTablePageProps> extends AbstractCrudPage<P> {

    tableSimpleClass = EntityTableSimpleRRC;

    // TODO RM35382: De eliminat si actualizat cf. pct 1/
    static tempEntityForCurrentTablePage: string | undefined = undefined;

    entityTableSimpleRef = ReduxReusableComponents.createRef<EntityTableSimple<EntityTableSimpleProps>>();
    csvExporterRef = React.createRef<CsvExporter>();

    shareLinkLogic = new ShareLinkLogic();
    protected historyCompareConnectedPageInfo: ConnectedPageInfo | undefined = undefined;

    columnConfigDropdownRef = ReduxReusableComponents.createRef<ColumnConfigDropdown<ColumnConfigDropdownProps>>();
    customQueryBarRef = ReduxReusableComponents.createRef<CustomQueryBar>();

    _options: EntityTableOptions = { bulkSize: 100, rowOffsetStartLoadingBulk: 50, countMode: true };

    loadParamType = "FindByFilterParamsInput";

    static defaultProps = {
        showDtoCrudButtons: true,
        showImportButton: true,
        showExportButton: true
    }

    getGraphQlIdType() {
        return this.props.entityDescriptor && this.props.entityDescriptor.graphQlIdType ? this.props.entityDescriptor.graphQlIdType : CrudGlobalSettings.INSTANCE.defaultGraphQlIdType;
    }

    get options(): EntityTableOptions {
        return this._options;
    }

    setOptions(value: Partial<EntityTableOptions>) {
        this._options = Object.assign(this._options, value);
        return this;
    }

    _eliminateDisabledCustomQueries(customQueries: Array<ClientCustomQuery>): Array<ClientCustomQuery> {
        return customQueries.filter(cq => cq.enabled)
    }

    /**
     * Meant to be overridden if needed.
     */
    adjustFilterBeforeLoad(filter: Filter): Filter {
        return filter;
    }

    /**
    * Meant to be overridden if needed.
    */
    adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
        return sorts;
    }

    getFilterForLoad() {
        let customQuery = this.customQueryBarRef.current?.props.dispatchers.getState().customQuery;
        let customQueryDefinition = customQuery ? customQuery.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };
        let filter: Filter = customQueryDefinition.filter;

        filter = Filter.eliminateDisabledFilters(filter)!; // root filter shouldn't be disabled; so it always exists

        filter = filter ? filter : Filter.createComposed(FilterOperators.forComposedFilter.and, []);
        const oneToManyModeField = this.props.s.oneToManyModeCachedField;
        const oneToManyModeCachedId = this.props.s.oneToManyModeCachedId;
        if (oneToManyModeField) {
            if (filter.operator !== FilterOperators.forComposedFilter.and.value) {
                filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);
            }
            this.addOneToManyModeFilters(filter.filters!, oneToManyModeField, entityDescriptors[this.props.s.oneToManyModeEntityDescriptorName!], oneToManyModeCachedId);
        }

        return filter;
    }

    addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
        filters!.push(Filter.create(oneToManyModeField, FilterOperators.forNumber.equals, oneToManyModeCachedId));
    }

    async invokeLoadQuery(options: QueryOptions<FindByFilterParams>) {
        return await apolloClientHolder.apolloClient.query(options);
    }

    getFieldsToRequestAsString(fieldsToRequest: string[]) {
        return CrudGlobalSettings.INSTANCE.fieldId + " " + this.props.entityDescriptor.getGraphQlFieldsToRequest(fieldsToRequest);
    }

    getLoadOperationName(ed: EntityDescriptor) {
        return `${lodash.lowerFirst(ed.name)}Service_findByFilter`;
    }

    getLoadQueryParams() {
        const ed = this.props.entityDescriptor;
        const loadOperationName = this.getLoadOperationName(ed);
        const columns = this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig?.configObject.columns;
        let fieldsToRequest: string[] = [];
        if (Utils.isNullOrEmpty(columns)) {
            fieldsToRequest = ed.getDefaultColumnConfig().configObject.columns!.map(column => column.name);
        } else {
            // only request the fields that are selected; including composed fields
            columns!.forEach(column => fieldsToRequest.push(column.name));
        }
        const fieldsToRequestStr = this.getFieldsToRequestAsString(fieldsToRequest);
        return {
            loadOperationName,
            loadQuery: gql(`query q($params: ${this.loadParamType}) { 
                    ${loadOperationName}(params: $params) {
                        results { ${fieldsToRequestStr} } totalCount
                    }
                }`)
        };
    }

    getCustomQueryDefinitionForLoad(): CustomQueryDefinition {
        let customQuery = this.customQueryBarRef.current?.props.dispatchers.getState().customQuery;
        let customQueryDefinition = customQuery ? customQuery.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };

        let { sorts } = customQueryDefinition;
        sorts = this.adjustSortsBeforeLoad(sorts);

        let filter = this.getFilterForLoad();
        filter = this.adjustFilterBeforeLoad(filter);

        return { filter, sorts };
    }

    getAggregateFunctions(): AggregateFunctionInput[] | null {
        return null;
    }

    async loadAttachedDashboards() {
        if (this.context.initializationsForClient.currentUser && AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, "Dashboard"])) && AppMetaTempGlobals.appMetaInstance.dashboardsAvailable && this.props.entityDescriptor.hasAttachedDashboards) {
            const query = gql(`query q($id: Long, $forEditor: Boolean, $entityName: String) { 
                    dashboardService_attachedDashboards(id: $id, forEditor: $forEditor, entityName: $entityName) {
                        id name icon width
                    }
                }`);
            const attachedDashboards = (await apolloClient.query({ query: query, variables: { forEditor: false, entityName: this.props.entityDescriptor.name }, context: { showSpinner: false } })).data["dashboardService_attachedDashboards"];
            this.props.r.setInReduxState({ attachedDashboards: attachedDashboards || [] });
        }
    }

    applyTableConfig(filter: Filter, sorts?: Array<Sort>) {
        const entityName = this.props.entityDescriptor.name;

        const cq = {} as ClientCustomQuery;
        cq.customQueryDefinitionObject = {} as CustomQueryDefinition;
        cq.customQueryDefinitionObject.filter = filter;
        cq.customQueryDefinitionObject.sorts = [];
        cq.id = -2;
        cq.screen = entityName;
        cq.name = _msg("entityCrud.table.shared.cq");
        cq.dirty = true;
        cq.fromCrudSettings = false;

        if (sorts) {
            this.customQueryBarRef.currentNotNull.props.dispatchers.sortBar.setInReduxState({ sorts: sorts });
            cq.customQueryDefinitionObject.sorts = sorts;
        }

        this.customQueryBarRef.currentNotNull.props.dispatchers.updateCustomQuery(cq);
    }

    applyContextMenu(params: { displayAsCards?: boolean }) {
        const cc = _.cloneDeep(this.columnConfigDropdownRef.currentNotNull.props.dispatchers.getState().columnConfig!);
        if (params.displayAsCards !== undefined) {
            cc.displayAsCards = params.displayAsCards;
            cc.dirty = true;
        }
        this.columnConfigDropdownRef.currentNotNull.props.dispatchers.setInReduxState({ columnConfig: cc });
    }

    applyRefreshButton(params: { refreshRate?: number, displayAsCards?: boolean }) {

        if (params.refreshRate !== undefined && params.refreshRate !== this.columnConfigDropdownRef.currentNotNull.props.dispatchers.getState().columnConfig?.autoRefreshInterval) {
            const cc = _.cloneDeep(this.columnConfigDropdownRef.currentNotNull.props.dispatchers.getState().columnConfig!);
            cc.autoRefreshInterval = params.refreshRate;
            cc.dirty = true;
            this.columnConfigDropdownRef.currentNotNull.props.dispatchers.setInReduxState({ columnConfig: cc });
        }
    }

    scriptableUiImpl: ScriptableUiImpl<ScriptableUiEntityTablePage.Main> = {
    }

    constructor(props: P) {
        super(props);
        this.provideActionsForRow = this.provideActionsForRow.bind(this);
        this.provideActionsForCell = this.provideActionsForCell.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onDoubleClickItem = this.onDoubleClickItem.bind(this);
        this.goToEditor = this.goToEditor.bind(this);
        this.onCloseEntityEditorInline = this.onCloseEntityEditorInline.bind(this);
        this.applyColumnConfigFromCustomQuery = this.applyColumnConfigFromCustomQuery.bind(this);

        if (this.props.entityDescriptor.canAddAuditTabs()) {
            this.historyCompareConnectedPageInfo = new ConnectedPageInfo(sliceHistoryCompare, HistoryCompare, "historyCompare_table_" + this.props.entityDescriptor.name);
        }
    }

    protected getScriptableUiId() {
        // see FieldEditor.getScriptableUiId() for a potential case where we might have issues
        return (this.props.oneToManyMode ? "oneToMany" : "") + this.props.entityDescriptor.name;
    }

    protected async resetStateBeforeRefresh() {
        await this.props.r.setInReduxState({ refreshUUID: uuid(), mostRecentStartIndex: -1, totalCount: -1, loaded: -1, initialLoaded: 0, initialTotalCount: 0, totalAdjustment: 0, loadedIds: {} });
        this.entityTableSimpleRef.current?.setEntities([]);
        this.entityTableSimpleRef.current?.setSelected(undefined);
    }

    /**
     * Overridable method, used to decide if the `initializeCC` method will be called at refresh of the table.
     * The current implementation doesn't seem clean. Because of the constraints of sliceFoundation and/or
     * improvable design. In theory we shouldn t have multiple calls to cc init. We expose this as a workaround,
     * because in at least one case, the double init is not good.
     */
    protected needToInitializeCC(): boolean {
        return !this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig;
    }

    async refresh() {
        if (TestUtils.storybookMode) {
            // Although we will be in storybook mode, if something happened that would trigger this function
            // and we don't reload entities, we will trigger an update for UI
            this.forceUpdate();
            return;
        }
        await this.loadAttachedDashboards();

        // field layout
        if (this.needToInitializeCC()) {
            await this.columnConfigDropdownRef.current?.props.dispatchers.initializeCC(this.props.entityDescriptor, ColumnConfigDropdownSource.TABLE);
        }
        await this.resetStateBeforeRefresh();

        await this.loadEntities({ startIndex: 0, pageSize: this.options.bulkSize, countMode: this.options.countMode });
    }

    addIndexOnEntity(entities: any[]) {
        let count = this.entityTableSimpleRef.current?.getEntities().length || 0;
        for (const entity of entities) {
            entity[INDEX] = count++;
        }
    }

    adjustEntities(entities: any[]): any[] {
        this.addIndexOnEntity(entities);
        return entities;
    }

    async loadEntities(p: { startIndex: number, pageSize: number, countMode?: boolean }) {
        const state = this.props.s;
        if (TestUtils.storybookMode) {
            return;
        }

        if (state.mostRecentStartIndex >= p.startIndex) {
            return;
        }

        this.props.r.setInReduxState({ mostRecentStartIndex: p.startIndex });

        const { filter, sorts } = this.getCustomQueryDefinitionForLoad();
        const aggregateFunctions = this.getAggregateFunctions();

        const loadQueryParams = this.getLoadQueryParams();
        const uuid: Optional<string> = this.props.s.refreshUUID;
        const { data } = (await this.invokeLoadQuery({
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    this.props.r.setInReduxState({ loaded: 0, totalCount: 0 });
                    return true;
                },
                showSpinner: false
            },
            query: loadQueryParams.loadQuery,
            variables: FindByFilterParams.create().startIndex(p.startIndex).pageSize(p.pageSize).filter(filter).sorts(sorts)
                .countMode(p.countMode).aggregateFunctions(aggregateFunctions)
        }));

        const result = data[loadQueryParams.loadOperationName];
        if (uuid !== this.props.s.refreshUUID) {
            // it means an old refresh was triggered and his answer arrived later than the latest refresh
            // so we reject the result received from it
            // case: 1/ init refresh; 2/ refresh because CQ changed -> need to keep 2/ and reject 1/
            return;
        }
        let initialLoaded = state.initialLoaded;
        let entities = this.entityTableSimpleRef.current?.getEntities() || [];
        let totalAdjustment = state.totalAdjustment;

        if (result.results.length !== 0) {
            initialLoaded = state.initialLoaded + result.results.length;
            this.props.r.setInReduxState({ initialLoaded });

            const loadedIds: any = { ...state.loadedIds };
            for (let i = 0; i < result.results.length; i++) {
                const id = this.getId(result.results[i]);
                if (loadedIds[id]) {
                    /* 
                    * discard; @see doc of loadedIds
                    * delete does not affect the structure of the array as it happens at splice, the line is replaced with undefined
                    */
                    delete result.results[i];
                } else {
                    loadedIds[id] = true;
                }
            }
            //filter is needed to remove undefined records that may occur after deleting lines from the array in the above code.
            const newEntities = this.adjustEntities(result.results.filter((x: any) => x));

            entities = this.entityTableSimpleRef.current ? this.entityTableSimpleRef.currentNotNull.getEntities().concat(newEntities) : [];
            totalAdjustment = entities.length - initialLoaded;
            this.entityTableSimpleRef.current?.setEntities(entities);
            this.props.r.setInReduxState({ loadedIds, loaded: entities.length, totalAdjustment });
        } else {
            if (state.loaded === -1) {
                this.props.r.setInReduxState({ loaded: 0 });
            }
        }

        if (!this.options.countMode) {
            this.props.r.setInReduxState({ totalCount: entities.length });
        } else {
            let initialTotalCount = state.initialTotalCount;
            if (p.countMode && state.totalCount === -1) {
                initialTotalCount = result.totalCount;
                this.props.r.setInReduxState({ initialTotalCount })
            }
            this.props.r.setInReduxState({ totalCount: initialTotalCount + totalAdjustment });
        }

        return result;
    }

    async deleteEntity(entityDescriptorName: string, id: number) {
        const permission = Utils.pipeJoin([ENT_DELETE, this.props.entityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
            return;
        }

        const removeOperationName = `${lodash.lowerFirst(entityDescriptorName)}Service_deleteById`;
        const removeMutation = gql(`mutation deleteEntity($id: ${this.getGraphQlIdType()}){${removeOperationName}(id: $id)}`);

        await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } })

        this.refresh();
    }

    async prepareDeleteEntitiesByFilter() {
        this.props.r.closeCompactBarMenuModal();
        if (this.options.countMode) {
            await this.loadEntities({ startIndex: 0, pageSize: -1, countMode: true });
        }
        this.props.r.setInReduxState({ confirmDeleteByFilter: this.props.s.totalCount >= 0 ? this.props.s.totalCount : undefined });
    }

    async confirmDeleteEntitiesByFilter() {
        const input = this.options.countMode ? _msg("entityCrud.table.deleteFilteredRows.type", String(this.props.s.confirmDeleteByFilter)) : _msg("entityCrud.table.deleteFilteredRows.type.countMode");
        if (this.props.s.confirmDeleteByFilterInput === input) {
            const name = `${lodash.lowerFirst(this.props.entityDescriptor.name)}Service_deleteByFilter`;
            const mutation = gql(`mutation deleteByFilter($params: FindByFilterParamsInput){${name}(params: $params)}`);
            await apolloClient.mutate({ mutation: mutation, variables: FindByFilterParams.create().filter(this.getCustomQueryDefinitionForLoad().filter) });
            this.refresh();
        }
        this.props.r.setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" });
    }

    getNextEntities(entity: any) {
        setTimeout(async (startIndex: number) => {
            if (this.props.s.initialLoaded >= this.options.rowOffsetStartLoadingBulk && entity[INDEX] >= this.props.s.loaded - this.options.rowOffsetStartLoadingBulk) {
                const bulkSize = this.options.bulkSize;
                if (startIndex >= bulkSize) {
                    this.loadEntities({ startIndex: startIndex, pageSize: this.options.bulkSize });
                }
            }
        }, undefined, this.props.s.initialLoaded);
    }

    getEntityAt(index: number): any | undefined {
        let entities = this.entityTableSimpleRef.current?.getEntities();
        // When we use a default CQ defined in settings for a table, its initialization can sometimes takes a while. This time, the entities
        // list is empty or undefined. So, we need to check the entities list before calling getNextEntities.
        if (index >= this.props.s.loaded || !entities || entities.length === 0) {
            return undefined;
        }
        this.getNextEntities(entities[index]);

        return entities[index];
    }

    componentDidMount() {
        if (!this.props.oneToManyMode && !this.props.embeddedMode) {
            EntityTablePage.tempEntityForCurrentTablePage = this.props.entityDescriptor.name;
            // For tempLocationForEditorBackground we need to use AppMetaTempGlobals.history.location, not this.props.currentLocation,
            // because AppMetaTempGlobals.history.location can be changed until component is rendered. E.g. when we go on user table, AppMetaTempGlobals.history.location
            // path would contain `/UserTable`, and this would be stored in currentLocation. Then, before the component would be loaded,
            // history.location will be changed to store `/UserTable/table`.
            // When the component will be mounted, if we use props.currentLocation, we will have tempLocationForEditorBackground = `/UserTable`.
            // In this case, if we open the editor for this table => table page on background without any tab selected, because we didn't have `/table`
            // in path to redirect to that tab. In future, the routing logic would be refactored.
            AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = AppMetaTempGlobals.history.location;
        }

        // This was added in order to work the hideActionsBar case, 
        // otherwise because CustomQueryDropdown.componentDidMount isn't executed, the refresh/loadEntities will not be called
        // The CQ mechaism must be refactored!
        if (this.props.hideActionsBar) {
            this.refresh();
        }

        // in the past this was done in "onMatchChanged()"; I don't see any reason to happen there (opposed to an editor page, 
        // where this is interesting, because the ID may change). And having it here also covers the cases for embedded mode
        this.componentDidUpdateInternal();
    }

    componentWillUnmount() {
        if (!this.props.oneToManyMode && !this.props.embeddedMode) {
            EntityTablePage.tempEntityForCurrentTablePage = undefined;
            AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = undefined;
        }
    }

    protected getTitle(): string | { icon: JSX.Element | string; title: JSX.Element | string; } {
        const { entityDescriptor } = this.props;
        return { icon: entityDescriptor.icon, title: entityDescriptor.getLabel() + " [" + _msg("entityCrud.editor.table") + "]" };
    }

    protected goToEditor(url: string) {
        AppMetaTempGlobals.history.push(url);
    }

    protected onDoubleClickItem(entity: any) {
        const { entityDescriptor } = this.props;
        this.goToEditor(entityDescriptor.getEntityEditorUrl(entity.id));
    }

    protected onSelectItem(entityId: any) {
        this.props.onSelectItem?.(entityId);
    }

    protected getMainRoutePath(): string {
        // don't show table tab if embededMode and no extra tabs available      
        if (this.props.embeddedMode && this.getExtraTabPanes().length === 0) { return "" };
        const { entityDescriptor } = this.props;
        return entityDescriptor.getEntityTableUrl();
    }

    protected getMainPaneSubPath() {
        return "table";
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return { icon: TABLE_PAGE_ICON, content: _msg("entityCrud.editor.table") };
    }

    protected adjustCustomQueryForOneToManyMode() {
        let entityDescriptor = this.props.entityDescriptor;
        let customQuery = _.cloneDeep(entityDescriptor.getDefaultCustomQuery());

        if (this.props.oneToManyMode && customQuery) {
            customQuery.screen = (entityDescriptor.name + "_oneToManyTable");

            let filters: Filter[] = !this.props.oneToManyMode.filter && entityDescriptor.defaultFilter ? [entityDescriptor.defaultFilter] : [];

            // We need to have a filter composed with AND operator, in order to be visible in CQ form
            customQuery.customQueryDefinitionObject.filter = this.props.oneToManyMode.filter || Filter.createComposedForClient(FilterOperators.forComposedFilter.and, filters);

            let sorts = this.props.oneToManyMode.sort || entityDescriptor.defaultSort || [];
            customQuery.customQueryDefinitionObject.sorts = !Array.isArray(sorts) ? [sorts] : sorts;

            this.customQueryBarRef.currentNotNull.props.dispatchers.updateCustomQuery(customQuery);
        }
    }

    /**
     * This is needed when we want to call it from componentDidMount also.
     * Added for extension use.
     */
    protected componentDidUpdateInternal(prevProps?: P) {
        const { props } = this;
        prevProps?.entityDescriptor.name !== props.entityDescriptor.name && AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, props.entityDescriptor.name]), true);

        super.componentDidUpdateInternal(prevProps);

        this.onLocationUpdate();

        // In order to reduce number of sets here, we need to check if the ED was changed (change current table page) or
        // if the current route is for a table (contains `/table`). We had problems in past because we didn't do the check for route.
        // E.g. User could go on `History compare` tab from user table page. Then he refresh the browser tab. After refresh, he goes to
        // table tab, open an editor and, on the background, tab is switched to `History compare` because the table page was opened
        // initially with `/historyCompare`, we didn't do the extra check for table route and we set a bad location for background.
        if (!this.props.oneToManyMode && !this.props.embeddedMode && (prevProps?.entityDescriptor.name !== this.props.entityDescriptor.name ||
            AppMetaTempGlobals.history.location.pathname.includes("/" + this.getMainPaneSubPath()))) {
            EntityTablePage.tempEntityForCurrentTablePage = this.props.entityDescriptor.name;
            AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = AppMetaTempGlobals.history.location;
        }

        /** 
         * if we need to open the editor in case of a single entity
         * 1/ check to see if entities have been loaded (loaded >= 0)
         * 2/ reset `go to editor` param so this if isn't executed on next load
         * 3/ if single entity available -> open editor
        */
        if (props.s.goToEditIfSingleEntity && !lodash.isEqual(prevProps?.s.loaded, props.s.loaded) && props.s.loaded >= 0) {
            props.r.setInReduxState({ goToEditIfSingleEntity: false });
            const entities = this.entityTableSimpleRef.current?.getEntities();
            if (entities?.length === 1) {
                this.goToEditor(this.props.entityDescriptor.getEntityEditorUrl(entities[0].id));
            }
        }

        /**
         * Refresh triggers zone; need to be sure we call `refresh()` only once. 
         */

        /**
         * To make this shorter, we can consider that we hava an async race condition. 1) Either the entity arrives first and then later comes the CQ. 
         * Or 2) first comes the CQ and then arrives the entity. 1) happens I enter the editor/form and then click on the tab w/ this class in "one-to-many mode".
         * And 2) happens if I write directly the URL of that tab.
         * So the mechanism is simple: if A arrived => is B already arrived? If yes => GO. And vice versa. 
         */
        const oneToManyModeChanged = props.oneToManyMode && props.oneToManyMode.entity?.[ID] && props.oneToManyMode.entity?.[ID] !== prevProps?.oneToManyMode?.entity?.[ID];

        if (oneToManyModeChanged) { // B just arrive; is A present?
            if (this.onCustomQueryChanged(oneToManyModeChanged)) {
                return;
            }
        }

        if (prevProps && !prevProps.s.goToEditIfSingleEntity && props.s.goToEditIfSingleEntity) {
            this.refreshIfNeeded();
        }
    }

    protected refreshIfNeeded() {
        if ((this.props.currentLocation?.pathname === this.getMainRoutePath() + "/" + this.getMainPaneSubPath()) ||
            (this.props.currentLocation?.pathname.includes("/" + this.props.entityDescriptor.getLabel(true)))) {
            // refresh only if the route points to the table (even if table is used on main page or on an editor tab), otherwise no need to refresh
            // e.g. if user is on Dashboard tab -> no refresh; when he goes to the table tab (e.g. location path changes) -> refresh
            this.refresh();
        }
    }

    protected onCustomQueryChanged(oneToManyModeChanged?: boolean) {
        const { props } = this;

        // if just changed (from undefined to something, or from something to something else)
        // OR we didn't store yet the value in the cache (so that the code can know that we are in one-to-many mode) ...
        if (props.oneToManyMode && (oneToManyModeChanged || !props.s.oneToManyModeCachedField)) {

            // ... then store it and call "refresh()" w/ "setTimeout" to let it propagate; so that the code in "refresh()" see this
            props.r.setInReduxState({
                oneToManyModeCachedField: props.oneToManyMode.field, oneToManyModeEntityDescriptorName: props.oneToManyMode.entityDescriptor.name,
                oneToManyModeCachedId: props.oneToManyMode.entity?.[props.oneToManyMode.entityField ? props.oneToManyMode.entityField : ID]
            });

            this.adjustCustomQueryForOneToManyMode();

            setTimeout(() => this.refresh());
            // prevent the normal "refresh()"
            return true;
        } // else, even if oneToMany mode, we don't need the delay, because the "oneToManyModeCached" is already populated
        if (!(this.props.currentLocation.search.length > 0)) {
            this.refresh();
            return true;
        }

        return false;
    }

    protected onCustomQueryBarChanged() {
        if (!this.props.oneToManyMode || this.props.oneToManyMode.entity?.[ID] !== undefined) {// A just arrive; is "oneToManyMode" disabled or B present? )
            this.onCustomQueryChanged();
        }
        this.props.r.closeCompactBarModal();
    }

    protected onCustomQueryFilterChanged() {
        this.onCustomQueryBarChanged();
    }

    protected onLocationUpdate() {
        const props = this.props;
        if (this.props.currentLocation.pathname.includes("Table/") && this.props.currentLocation.search.length > 0) {
            const urlSearchParams = new URLSearchParams(this.props.currentLocation.search);
            if (urlSearchParams.has("cqf") || urlSearchParams.has("cqs")) {
                const shareLink = this.shareLinkLogic.deserialize(urlSearchParams);
                if (shareLink.goToEditIfSingleEntity) {
                    props.r.setInReduxState({ goToEditIfSingleEntity: true });
                }
                this.applyTableConfig(shareLink.filter, shareLink.sorts);
            }
            AppMetaTempGlobals.history.replace(this.props.currentLocation.pathname);
        }
    }

    protected onMatchChanged(match: any) {
        this.onLocationUpdate();
    }

    protected shareLink = async () => {
        const props = this.props;
        const link = this.shareLinkLogic.createLink(true, props.entityDescriptor, this.customQueryBarRef.currentNotNull.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter,
            this.customQueryBarRef.currentNotNull.props.dispatchers.getState().sortBar.sorts);
        let error = false;
        try {
            await navigator.clipboard.writeText(link);
        } catch (e) {
            error = true;
        }
        this.props.r.setInReduxState({ shareLinkResult: { link, error } });
    }

    protected getBreadcrumbSections(): SemanticShorthandCollection<BreadcrumbSectionProps> {
        const entityDescriptor = this.props.entityDescriptor;
        return [
            { key: 'Home', content: <><Icon name="home" /><Link to="/">{_msg("HomePage.title")}</Link></> },
            { key: entityDescriptor.name, content: <>{entityDescriptor.getIcon()}{entityDescriptor.getLabel(true)}</> },
        ];
    }

    protected renderConfirmDeleteByFilterModal() {
        return <ModalExt closeIcon={true} open={this.props.s.confirmDeleteByFilter !== undefined} onClose={() => this.props.r.setInReduxState({ confirmDeleteByFilter: undefined })} >
            <Modal.Header><Icon name="warning sign" color="red" /> {_msg("entityCrud.table.danger")}</Modal.Header>
            <Modal.Content>
                <Interweave content={
                    this.options.countMode
                        ? _msg("entityCrud.table.deleteFilteredRows.message", String(this.props.s.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type", String(this.props.s.confirmDeleteByFilter)))
                        : _msg("entityCrud.table.deleteFilteredRows.message.countMode", String(this.props.s.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type.countMode"))
                } />
                <p></p>
                <Input fluid autoFocus value={this.props.s.confirmDeleteByFilterInput}
                    onChange={(data) => this.props.r.setInReduxState({ confirmDeleteByFilterInput: data.target.value })} />
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.confirmDeleteEntitiesByFilter()}>{_msg("general.delete")}</Button>
                <Button negative onClick={() => this.props.r.setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" })}>{_msg("general.cancel")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected getOneToManyModeAddParams() {
        const miniFields = this.props.oneToManyMode!.entityDescriptor.miniFields;
        const miniEntity = lodash.pick(this.props.oneToManyMode?.entity, (miniFields.includes("id") ? [] : ["id"]).concat(miniFields));
        return "?" + this.props.oneToManyMode?.field + "=" + JSON.stringify(miniEntity);
    }

    protected fileExporterRef = React.createRef<FileExportButton>();
    protected fileImporterRef = React.createRef<FileImportButton>();

    protected renderCompactBarMenuModal() {
        const entityDescriptor = this.props.entityDescriptor;
        let columns: Array<ColumnDefinition> = [];
        let columnConfig = this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig;
        if (columnConfig) {
            columns = columnConfig.configObject.columns!;
        }
        const hasDelete = AppMetaTempGlobals.appMetaInstance.hasPermission(DELETE_ALL_FILTERED_ROWS_MENU_ENTRY);
        let addPath = this.props.oneToManyMode ? entityDescriptor.getEntityEditorUrl(ADD) + "/edit" + this.getOneToManyModeAddParams() : entityDescriptor.getEntityEditorUrl(ADD);
        const addItem = <Menu.Item as={NavLink} key="add" to={addPath} onClick={this.props.r.closeCompactBarMenuModal} icon="plus" content={_msg("entityCrud.table.add")} />;
        const deleteAllFilteredRowsItem = hasDelete ?
            <Menu.Item key="deleteAllFilteredRows" className="EntityTablePage_menu_deleteByFilter" icon="remove" onClick={() => this.prepareDeleteEntitiesByFilter()} content={_msg("entityCrud.table.deleteFilteredRows")} />
            : null
        const shareLinkItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showShareLinkButton ?
            <Menu.Item key="shareLink" onClick={async () => { await this.shareLink(); this.props.r.closeCompactBarMenuModal() }} icon="share alternate" content={_msg("entityCrud.table.shareLink")} />
            : null
        const fileImportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showImportButton ?
            <FileImportButtonRRC id="fli" ref={this.fileImporterRef} key="fileImport" mode={FILE_IMPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} refresh={() => this.refresh()} />
            : null
        const fileExportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showExportButton ?
            <FileExportButtonRRC id="fle" ref={this.fileExporterRef} key="fileExport" mode={FILE_EXPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} customQueryDefinition={this.getCustomQueryDefinitionForLoad()} columns={columns} />
            : null
        const fileExportAsCsvItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showExportAsCsvButton ?
            // TODO CSR: aici cheia e necesara, caci obiectul sta intr-o lista; remind despre mesaje; de survolat bine prin toate fisierele
            <Menu.Item data-testid={entityTablePageTestids.openModal} key="exportAsCsv" icon="download" onClick={() => {
                this.props.r.setInReduxState({ openModalExportAsCsv: true, compactBarMenuModal: false })
            }} content={_msg("CsvExporter.exportAsCsv.label")} />
            : null
        const menuItems = [addItem, deleteAllFilteredRowsItem, shareLinkItem, fileImportItem, fileExportItem, fileExportAsCsvItem];
        return <Menu vertical className="wh100" borderless>
            {menuItems.filter((item: any) => item && !this.props.itemsHidedFromCell?.includes(item.key))}
            <Divider className="no-padding-margin" />
            <div className="flex-center EntityTablePage_compactMenu_additional">
                <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("ColumnConfig.displayAsCards.label")}</div>
                <Checkbox checked={this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig?.displayAsCards} onChange={(e, data) =>
                    this.applyContextMenu({ displayAsCards: data.checked })
                } />
            </div>
        </Menu>;
    }

    protected renderRefreshButton() {
        return <RefreshButtonRRC id={"refreshButton"} automaticRefresh={true}
            refresh={() => this.refresh()}
            refreshRate={this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig?.autoRefreshInterval}
            onChange={(params: { refreshRate?: number, displayAsCards?: boolean }) => this.applyRefreshButton(params)}
            openColumnConfig={() => this.columnConfigDropdownRef.currentNotNull.props.dispatchers.openEditor(false, ColumnConfigSource.EDIT, this.context.initializationsForClient.currentOrganization)}
        />
    }

    protected renderColumnConfigDropdown() {
        return <ConnectedComponentInSimpleComponent info={new ConnectedPageInfo(sliceColumnConfigDropdown, ColumnConfigDropdown, uniqueId('columnConfig-'), {
            ref: this.columnConfigDropdownRef,
            entityDescriptor: this.props.entityDescriptor,
            source: ColumnConfigDropdownSource.TABLE,
            mode: COLUMN_CONFIG_DROPDOWN_MODE.TABLE_COMPACT,
            showSearchAndOptions: true,

            onChange: (columnsWereAdded: boolean) => {
                if (columnsWereAdded) {
                    this.refreshIfNeeded();
                    return;
                }
                // Remind: a rerender happens if a prop or state changes.
                // We access the state of the CC via ref (so not a prop or state). So a change in CC won't trigger the rerender of
                // table page.
                // So, we need to force an update.
                this.forceUpdate();
            },
        })} />;
    }

    protected applyColumnConfigFromCustomQuery(customQuery: ClientCustomQuery) {
        if (customQuery.preferredColumnConfig) {
            this.columnConfigDropdownRef.currentNotNull.props.dispatchers.updateColumnConfig(getColumnConfigForClient(customQuery.preferredColumnConfig));
            this.columnConfigDropdownRef.currentNotNull.props.dispatchers.setInReduxState({
                dropdownOpened: false,
                search: ""
            });
        }
    }

    /**
     * We can change the default method for applying preferred CC from a custom query by sending
     * an applyColumnConfigFromCustomQuery function as parameter
     */
    protected renderCustomQueryBar(applyColumnConfigFromCustomQuery?: (cq: ClientCustomQuery) => void) {
        const entityDescriptor = this.props.entityDescriptor;

        return <ConnectedComponentInSimpleComponent info={new ConnectedPageInfo(sliceCustomQueryBar, CustomQueryBar, uniqueId('customQueryBar-'),
            {
                entityName: entityDescriptor.name,
                screen: this.props.screen ? this.props.screen! : entityDescriptor.name,
                mode: CUSTOM_QUERY_BAR_MODE.TABLE,
                ref: this.customQueryBarRef,
                onChangeFilter: () => this.onCustomQueryFilterChanged(),
                onChangeSort: () => this.onCustomQueryBarChanged(),
                onChangeSortDirection: () => this.onCustomQueryBarChanged(),
                applyColumnConfigFromCustomQuery: applyColumnConfigFromCustomQuery ? applyColumnConfigFromCustomQuery : this.applyColumnConfigFromCustomQuery
            })} />
    }

    protected renderCompactBar() {
        return <Segment className="less-margin-top-bottom EntityTablePage_bar" >
            <Button primary icon="bars" data-testid={entityTablePageTestids.tableMenu} onClick={(e) => {
                this.props.r.setInReduxState({ compactBarMenuModal: [e.clientX, e.clientY] })
            }} />
            {this.renderRefreshButton()}
            {this.renderColumnConfigDropdown()}
            <span className="tiny-margin-right" />
            {this.renderCustomQueryBar()}
            <span className="tiny-margin-right" />
            {this.preRenderButtons({}).map((button: any, i) => {
                let key = button?.element?.key;
                if (!key) {
                    key = button?.props?.key;
                }
                if (key) {
                    if (button.element) {
                        return button.element;
                    }
                    if (!button.props.key) {
                        button.props.key = i;
                    }
                    return React.createElement(button.elementType, button.props);
                } else {
                    return null;
                }
            })}
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.props.s.compactBarMenuModal} onClose={() => this.props.r.closeCompactBarMenuModal()}>
                {this.renderCompactBarMenuModal()}
            </ModalExt>
            {this.renderConfirmDeleteByFilterModal()}
        </Segment>;
    }

    protected renderShareLinkModal() {
        return <ModalExt severity={this.props.s.shareLinkResult.error ? Severity.WARNING : Severity.INFO}
            open={this.props.s.shareLinkResult.link !== undefined}
            onClose={() => this.props.r.setInReduxState({ shareLinkResult: { link: undefined, error: false } })}>
            <Modal.Content>
                <Modal.Description>
                    {this.props.s.shareLinkResult.error ? <Message warning>{_msg("entityCrud.table.copiedToClipboard.error")}</Message>
                        : <p>{_msg("entityCrud.table.copiedToClipboard.main")}</p>}
                    <Message data-testid="shareLinkResult">{this.props.s.shareLinkResult.link}</Message>
                    {this.props.s.shareLinkResult.link && this.props.s.shareLinkResult.link.length > 2000 ? <p>{_msg("entityCrud.table.copiedToClipboard.limit")}</p> : null}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.props.r.setInReduxState({ shareLinkResult: { link: undefined, error: false } })} primary>{_msg("general.ok")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    prepareEntityForExport(): EntityType {
        const entityDescriptor = this.props.entityDescriptor;
        const columns = Object.keys(entityDescriptor.fields)


        return {
            [0]: {
                entityName: entityDescriptor.name, rows: [], columns: columns!
            }
        }
    }

    protected renderExportAsCsvModal() {
        const { filter, sorts } = this.getCustomQueryDefinitionForLoad();

        const loadQueryParams = this.getLoadQueryParams();
        return <ModalExt severity={Severity.INFO} className="EntityTablePage_exportAsCsvModal" size='fullscreen' closeIcon={true}
            open={this.props.s.openModalExportAsCsv}
            onClose={() => this.props.r.setInReduxState({ openModalExportAsCsv: false })}>
            <Modal.Header>{_msg("CsvExporter.exportAsCsv.label")}</Modal.Header>
            <Modal.Content>
                <CsvExporterRRC id="flecsv" csvText="" entities={this.prepareEntityForExport()}
                    ref={this.csvExporterRef} filter={filter} sorts={sorts} loadQueryParams={loadQueryParams} />
            </Modal.Content>
        </ModalExt>
    }

    protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
        const entityDescriptor = this.props.entityDescriptor;

        // In past, we would check if the current field was undefined or current field was clientOnly. This was wrong because,
        // for row menu, we wouldn't have a currentField => 0 actions for row. And we needed to have 0 actions only on clientOnly fields.
        if (actionParam?.currentField && entityDescriptor.getField(actionParam.currentField).clientOnly) {
            return [];
        }

        return [
            {
                icon: EDITOR_PAGE_ICON,
                label: _msg("entityCrud.table.edit"),
                run: (param) => {
                    this.goToEditor(entityDescriptor.getEntityEditorUrl(this.entityTableSimpleRef.currentNotNull.getEntity(param.selection[0]).id));
                }
            },
            {
                icon: "clone",
                label: _msg('dto_crud.duplicate'),
                run: (param) => {
                    AppMetaTempGlobals.history.push({
                        pathname: entityDescriptor.getEntityEditorUrl(ADD), search: DUPLICATE_FROM_ID_SEARCH_PARAM + "=" + this.entityTableSimpleRef.currentNotNull.getEntity(param.selection[0]).id
                    });
                }
            },
            {
                icon: "remove",
                label: _msg("entityCrud.table.delete"),
                run: (param) => {
                    this.props.r.setInReduxState({ confirmDeleteEntity: true });
                }
            }
        ];
    }

    protected isEditableCell(initialFormikValues: any, fieldDescriptor?: FieldDescriptor): boolean {
        return initialFormikValues && fieldDescriptor && !fieldDescriptor.clientOnly;
    }

    protected hasPermissionForField(entityDescriptorName: string, fieldName: string) {
        return AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, entityDescriptorName, FIELDS_WRITE, fieldName]));
    }

    protected checkPermissionsToEditField(entityDescriptorName: string, fieldName: string) {
        // If we have a custom field, permission will be set for its id, not on name and we will need to
        // get id from settings
        let customFieldId: number | undefined = EntityDescriptorForServerUtils.getFieldId(entityDescriptorName, fieldName);

        return this.hasPermissionForField(entityDescriptorName, fieldName) || (customFieldId && this.hasPermissionForField(entityDescriptorName, customFieldId.toString()));
    }

    protected canRenderEditOption(parentEntityDescriptor: EntityDescriptor, fieldDescriptor?: FieldDescriptor) {
        return fieldDescriptor && !fieldDescriptor.clientOnly && this.checkPermissionsToEditField(parentEntityDescriptor.name, fieldDescriptor.name);
    }

    protected provideActionsForCell(actionParam: ITableActionParamForRun): IAction[] {
        let { initialFormikValues, fieldDescriptor, parentEntityDescriptor, fieldLabel } = this.getParamsForEntityEditorInline(this.entityTableSimpleRef.currentNotNull.getEntity(actionParam.selection[0]), actionParam.currentField);

        let isEditableCell = this.isEditableCell(initialFormikValues, fieldDescriptor);
        let renderEditOption = this.canRenderEditOption(parentEntityDescriptor, fieldDescriptor);

        if (!renderEditOption) {
            return [];
        }

        return [{
            icon: EDITOR_PAGE_ICON,
            isDisabled: (param) => !isEditableCell,
            label: isEditableCell ? _msg("entityCrud.inlineEditor.edit.enabled", fieldLabel) : _msg("entityCrud.inlineEditor.edit.disabled", fieldLabel),
            run: (param) => {
                if (param.eventPoint) {
                    this.props.r.setInReduxState({
                        actionParam: {
                            ...(param as ITableActionParamForRun),
                            eventPoint: param.eventPoint
                        }
                    });
                }
            }
        }];
    }

    /**
     * It's not obvious to me why this works; I mean which prop that triggers a rerender
     * makes this to be invoked as well. Initially I wanted to inject in the TableSimple this
     * prop, to make sure this gets called.
     */
    protected renderFooter() {
        return <span>{_msg(this.options.countMode ? "entityCrud.table.totalCount" : "entityCrud.table.loadedCount")} <b data-testid="recordsCount">{this.props.s.totalCount === -1 ? _msg("general.loading") : this.props.s.totalCount}</b>.&nbsp;</span>;
    }

    /**
     * Can be overrided to set tableProps to table simple
    */
    protected getTableProps(): Partial<TableProps> | undefined {
        return undefined;
    }

    /**
     * Can be overrided to set props to entity table simple
    */
    protected getTableSimpleProps(): Partial<EntityTableSimpleProps> | undefined {
        return undefined;
    }

    protected renderPane(entityDescriptor: EntityDescriptor, columns: Array<ColumnDefinition>) {
        const displayAsCards = this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig?.displayAsCards;
        return <>
            <this.tableSimpleClass id={uniqueId('entityTableSimple-')} ref={this.entityTableSimpleRef} columns={columns}
                entityDescriptor={entityDescriptor} parentProps={this.props} renderFooter={this.renderFooter} mode={displayAsCards ? EntityTableMode.CARDS : EntityTableMode.NORMAL}
                onSelectItem={(entityId: any) => this.onSelectItem(entityId)} customQueryBarRef={this.customQueryBarRef}
                onDoubleClickItem={this.onDoubleClickItem} options={this.options}
                provideActionsForRow={!this.props.hideActionsCell ? this.provideActionsForRow : undefined}
                provideActionsForCell={this.provideActionsForCell}
                screen={this.props.screen ? this.props.screen! : entityDescriptor.name}
                onColumnMoved={(event: any) => this.columnConfigDropdownRef.currentNotNull.props.dispatchers.updateColumnOrder(event)}
                onColumnResized={(width: number, name: string) => this.columnConfigDropdownRef.currentNotNull.props.dispatchers.updateColumnSize({ width: width, name: name })}
                compactBar={this.props.hideActionsBar ? null : this.renderCompactBar()} getEntityAt={(index: number) => this.getEntityAt(index)}
                getNextEntities={(entity: any) => this.getNextEntities(entity)} tableProps={this.getTableProps()}
                {...this.getTableSimpleProps()}
            />
            {this.renderShareLinkModal()}
            {this.renderExportAsCsvModal()}
        </>;
    }

    protected renderAttachedDashboard(id: number) {
        const entityDescriptor = this.props.entityDescriptor;
        return <>
            {/* <Segment className="less-margin-top-bottom EntityTablePage_bar" style={{ margin: "0 5px", width: "calc(100% - 10px)" }}>
                <CustomQueryBar key="customQueryBar" className="tiny-margin-right" applyColumnConfigFromCustomQuery={this.props.dispatchers.applyColumnConfigFromCustomQuery}
                    entityDescriptor={entityDescriptor.name} screen={this.props.screen ? this.props.screen! : entityDescriptor.name} {...this.props.customQueryBarDashboard}
                    dispatchers={this.props.dispatchers.customQueryBarDashboard} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
            </Segment> */}
            {super.renderAttachedDashboard(id, { dataExplorerFilter: {}, dataExplorerEntityDescriptor: entityDescriptor })}
        </>;
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        let historyCompareTab: TabRouterPane | null = null;
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_AUDIT, this.props.entityDescriptor.name]), false)) {
            if (this.historyCompareConnectedPageInfo && this.props.s.attachedDashboards !== undefined) {
                historyCompareTab = {
                    routeProps: { path: "/historyCompare" },
                    menuItemProps: { icon: "chart line", content: _msg("HistoryCompare.title") },
                    render: () => <ConnectedComponentInSimpleComponent info={this.historyCompareConnectedPageInfo!} entityName={this.props.entityDescriptor.name}
                        editor={this} expand={true} filter={this.customQueryBarRef.current?.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter} sorts={this.customQueryBarRef.current?.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.sorts} />
                }
            }
        }

        if (super.getExtraTabPanes().length > 0) {
            // null is added for dashboardsTabs to change the order of tabs and be displayed first
            return [...super.getExtraTabPanes()!, null].concat(historyCompareTab ? [historyCompareTab] : []);
        }

        return this.props.oneToManyMode ? [] : historyCompareTab ? [historyCompareTab] : [];
    }

    protected renderConfirmDeleteEntity() {
        const props = this.props;
        return (
            <ModalExt
                severity={Severity.INFO}
                open={props.s.confirmDeleteEntity}
                header={_msg("dto_crud.deleteConfirmation.header", this.entityTableSimpleRef.current?.getSelected(), this.props.entityDescriptor.getLabel())}
                content={_msg("dto_crud.deleteConfirmation")}
                onClose={() => props.r.setInReduxState({ confirmDeleteEntity: false })}
                actions={[
                    <Button key="close" onClick={() => props.r.setInReduxState({ confirmDeleteEntity: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={() => {
                        if (!this.entityTableSimpleRef.current?.getSelected()) { return; }
                        this.deleteEntity(this.props.entityDescriptor.name, this.entityTableSimpleRef.current?.getSelected());
                        props.r.setInReduxState({ confirmDeleteEntity: false });
                    }}>{_msg("general.ok")}</Button>
                ]}
            />
        );
    }

    protected getParamsForEntityEditorInline(entity: any, currentFieldName: string | undefined) {
        const entityDescriptor = this.props.entityDescriptor;
        let initialFormikValues = entity;
        let fieldDescriptor;
        let parentEntityDescriptor = entityDescriptor;
        let fieldLabel = "";

        if (currentFieldName) {
            fieldLabel = entityDescriptor.getComposedFieldLabel(entityDescriptor.getFieldDescriptorChain(currentFieldName));
            if (currentFieldName.includes(".")) {
                let parentFieldNames = currentFieldName.split(".");
                parentFieldNames.splice(parentFieldNames.length - 1);
                initialFormikValues = Utils.navigate(entity, parentFieldNames, false, ".");
                let parentFieldDescriptor = entityDescriptor.getParentField(currentFieldName);
                parentEntityDescriptor = entityDescriptors[parentFieldDescriptor.type]
            }
            fieldDescriptor = entityDescriptor.getField(currentFieldName);
        }

        return { initialFormikValues, fieldDescriptor, parentEntityDescriptor, fieldLabel };
    }

    protected onCloseEntityEditorInline(withRefresh?: boolean) {
        this.props.r.setInReduxState({
            actionParam: undefined
        });

        if (withRefresh) {
            this.refresh();
        }
    }

    renderTableDimmer() {
        return <Dimmer inverted active={this.props.s.loaded === -1} page={true}><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>
    }

    renderTableSimple() {
        const entityDescriptor = this.props.entityDescriptor;
        let columns: Array<ColumnDefinition> = [];
        let columnConfig = this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig;
        if (columnConfig) {
            columns = columnConfig.configObject.columns!;
        }

        let { initialFormikValues, fieldDescriptor, parentEntityDescriptor } = this.props.s.actionParam ?
            this.getParamsForEntityEditorInline(this.entityTableSimpleRef.currentNotNull.getEntity(this.props.s.actionParam!.selection[0]), this.props.s.actionParam!.currentField) : {} as any;

        return <>
            {this.renderTableDimmer()}
            {this.renderPane(entityDescriptor, columns)}
            {this.renderConfirmDeleteEntity()}
            {this.props.s.actionParam && <EntityEditorInline editorPosition={this.props.s.actionParam.eventPoint}
                fieldDescriptor={fieldDescriptor} entityDescriptor={parentEntityDescriptor} goToFullEditor={this.goToEditor}
                entity={initialFormikValues} scriptableUiId={"entityEditorInline_" + this.getScriptableUiId()} onClose={this.onCloseEntityEditorInline} />}
        </>;
    }

    renderMain() {
        return <ScriptableUiEntityTablePage.Main id={this.getScriptableUiId()} implementation={this.scriptableUiImpl}>
            {s => <>
                <div className="EntityTablePage_embbeded">{this.renderTableSimple()}</div>
            </>}
        </ScriptableUiEntityTablePage.Main>;
    }

    render() {
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, this.props.entityDescriptor.name]))) {
            return <></>;
        }
        return <AppContainerContext.Consumer>
            {context => <>
                <Utils.Observer value={context.initializationsForClient.currentOrganizationDropdownValue} didUpdate={() => this.refresh()} />
                {super.render()}
            </>}
        </AppContainerContext.Consumer>;
    }
}

export const EntityTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, EntityTablePage);"../AppContainerContext"