import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { SettingsEntityEditorPage, sliceSettingsEntityEditorPage } from "@crispico/foundation-react/pages/SettingsEntity/SettingsEntityEditorPage";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityTablePage, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { SettingsEntityDebug } from "./SettingsEntityDebug";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";

// workaround for solving cycle issue
export let settingsEntityDescriptor: EntityDescriptor;
export let organizationEntityDescriptor: EntityDescriptor;

// workaround for solving cycle issue
export let OrganizationManyToOneEditorStandalone: any;

class SettingsTablePage extends EntityTablePage {

    protected getExtraTabPanes() {
        return [{
            routeProps: { path: "/debug" }, menuItemProps: { icon: "bug", content: _msg("SettingsEntityDebug.debug.label") },
            render: () => <SettingsEntityDebug />
        }];
    }
};

const SettingsTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, SettingsTablePage);

addAfterStartupRunnable(() => {
    class SettingsEntityDescriptor extends EntityDescriptor {
        constructor() {
            super({
                name: "SettingsEntity",
                icon: "setting",
                miniFields: ["rootType"],
                defaultSort: { field: "rootType", direction: "ASC" },
                javaIdType: "Long",
                graphQlIdType: "Long",
                hasAttachedDashboards: false
            });
        }

        renderTable() {
            return <SettingsTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
        }
    }

    settingsEntityDescriptor = addEntityDescriptor(new SettingsEntityDescriptor()
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "enabled", type: FieldType.boolean })
        .addFieldDescriptor({ name: "rootType", type: FieldType.string, enabled: false })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
        .addFieldDescriptor({ name: "json", type: FieldType.string, isInDefaultColumnConfigForTable: false })
        .addFieldDescriptor({ name: "importance", type: FieldType.number })
    );

    organizationEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Organization",
        icon: "building outline",
        miniFields: ["qualifiedName"],
        defaultSort: { field: "qualifiedName", direction: "ASC" },
        javaIdType: "Long",
        graphQlIdType: "Long"
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "qualifiedName", type: FieldType.string, enabled: false })
        .addFieldDescriptor({ name: "parent", type: "Organization" })
    );

    organizationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {

        protected getExtraTabPanes(): (TabRouterPane | null)[] {
            return [
                ...super.getExtraTabPanes(),
                this.createOneToManyTabPane("Organization", "parent", { sort: { field: "name", direction: "ASC" } })
            ];
        }
    };

    settingsEntityDescriptor.infoEditor.slice = sliceSettingsEntityEditorPage.setEntityDescriptor(settingsEntityDescriptor);
    settingsEntityDescriptor.infoEditor.wrappedComponentClass = SettingsEntityEditorPage;
});"../../entity_crud/EntityDescriptor""./SettingsEntityEditorPage""../../entity_crud/entityCrudConstants""../../entity_crud/EntityTablePage""../../entity_crud/FieldType""../../reduxReusableComponents/ReduxReusableComponents""../../entity_crud/EntityEditorPage""../../components/TabbedPage/TabbedPage"