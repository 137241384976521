import React, { ReactNode, RefObject } from 'react';
import _ from 'lodash';
import { DashboardTab } from './dashboardTab/DashboardTab';

export type AbstractWidgetProps = { id: string, dashboardTabRef: RefObject<DashboardTab> };
export type AbstractWidgetState = {};

export abstract class AbstractWidget<T extends AbstractWidgetProps = AbstractWidgetProps, S extends AbstractWidgetState = AbstractWidgetState> extends React.Component<T, S> {

    componentDidMount() {
        this.refresh();
    }

    protected async refresh() {
        if (this.props.dashboardTabRef) {
            // in add mode don't have the dashboard ref
            this.props.dashboardTabRef.current!.onLoadedWidget(this.props.id);
        }
    }

    render(): ReactNode {
        return <></>;
    }
}
