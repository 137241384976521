import { apolloClient, ConnectedPageInfo, createSliceFoundation, ENT_READ, getBaseImpures, getBaseReducers, HomePageSettings, PropsFrom, Utils, VisualStyleSettings } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { DashboardTabRRC } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import gql from "graphql-tag";
import { Header, Menu, Tab, TabProps } from "semantic-ui-react";
import { Dashboard } from "./dashboard/DashboardEntityDescriptor";
import { FindByFilterParams } from "../entity_crud/FindByFilterParams";
import { ID } from "../entity_crud/entityCrudConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "../components/CustomQuery/Filter";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { dashboardEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";

export const slicePersonalHomePage = createSliceFoundation(class SlicePersonalHomePage {
    initialState = {
        dashboards: [] as Dashboard[],
        activeDashboardTabIndex: 0
    }

    reducers = {
        ...getBaseReducers<SlicePersonalHomePage>(this),
    }

    impures = {
        ...getBaseImpures<SlicePersonalHomePage>(this),

        async loadDashboards(param: [{ id: number }]) {
            const query = `query dashboardService_findByFilter($params: FindByFilterParamsInput) {
                dashboardService_findByFilter(params: $params) { 
                    results { 
                        id, name, icon, color
                    } 
                }
            }`;
            const dashboards: Dashboard[] = (await apolloClient.query({
                query: gql(query), variables: FindByFilterParams.create().filter(Filter.create(ID, FilterOperators.forNumber.in, param.map(item => item.id).join(",")))
            })).data.dashboardService_findByFilter.results;

            this.getDispatchers().setInReduxState({ dashboards });
        },

    }
});
export type PersonalHomePageProps = PropsFrom<typeof slicePersonalHomePage>;

/**
 * TODO: probably needs a bit of work to properyl work in any FBA. Not a lot. The heavy work/separation should
 * be pretty OK.
 */
export class PersonalHomePage<P extends PersonalHomePageProps = PersonalHomePageProps> extends TabbedPage<P> {

    constructor(props: P) {
        super(props);
        this.onTabChange = this.onTabChange.bind(this);
    }

    protected getTitle() {
        return { icon: "home", title: _msg("HomePage.title") };
    }

    protected getClassName() {
        return "PersonalHomePage";
    }

    protected refreshDashboards() {
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, dashboardEntityDescriptor.name]))) {
            return;
        }
        const homePageDashboards = this.context.initializationsForClient.homePageSettings.homePageDashboards;
        if (homePageDashboards) {
            this.props.dispatchers.loadDashboards(homePageDashboards);
        }
    }

    protected renderHeader() {
        return <div className="flex-container PersonalHomePage_header">
            <Header as="h1" className="PersonalHomePage_welcome_header">{_msg('PersonalHomePage.welcome.header', this.context.initializationsForClient.currentUser?.firstName || this.context.initializationsForClient.currentUser?.username)}</Header>
            <Header as="h1" className="PersonalHomePage_welcome_subheader">{_msg('PersonalHomePage.welcome.subheader')}</Header>
        </div>
    }

    protected renderDashboard(index: number, dashboard: Dashboard) {
        return <DashboardTabRRC id={"personalHomepageDashboard_" + index + "_" + dashboard.id} dashboardId={dashboard.id} />;
    }

    protected backgroundImageURL() {
        const homeBackgroundImage = this.context.initializationsForClient.visualStyleSettings.homeBackgroundImage;
        return homeBackgroundImage && "url(" + Utils.adjustUrlToServerContext(homeBackgroundImage) + ")";
    }

    protected onTabChange(event: React.MouseEvent<HTMLDivElement>, data: TabProps) {
        if (data.activeIndex as number === this.props.activeDashboardTabIndex) {
            return;
        }
        this.props.dispatchers.setInReduxState({ activeDashboardTabIndex: data.activeIndex as number });
    }

    protected getTabs() {
        const tabs: any = [];
        const lst = this.context.initializationsForClient.homePageSettings.homePageDashboards.map(item => item.id);
        lst && Object.values(this.props.dashboards).sort((a: Dashboard, b: Dashboard) => lst.indexOf(a.id) - lst.indexOf(b.id)).forEach((dashboard, index) =>
            tabs.push({
                menuItem:
                    <Menu.Item className="less-padding" fitted="vertically" index={index} key={index}>
                        {dashboard.name}
                    </Menu.Item>,
                render: () =>
                    <Tab.Pane className="flex-container flex-grow PersonalHomePage_dashboardTabWrapper" style={{ backgroundImage: this.backgroundImageURL() }}>
                        {this.renderDashboard(index, dashboard)}
                    </Tab.Pane>
            })
        );

        return tabs;
    }

    protected renderContent() {
        if (this.props.dashboards.length > 1) {
            return <Tab menu={{ attached: true, tabular: true, className: "flex-wrap" }} className="PersonalHomePage_dashboardTabWrapper flex-container flex-grow" panes={this.getTabs()} activeIndex={this.props.activeDashboardTabIndex} onTabChange={this.onTabChange} />
        }

        return <div className="PersonalHomePage_dashboardTabWrapper" style={{ backgroundImage: this.backgroundImageURL() }}>
            {this.props.dashboards.length === 1 ? this.renderDashboard(0, this.props.dashboards[0]) : null}
        </div>
    }

    protected renderMain() {
        return <AppContainerContext.Consumer>
            {context =>
                <>
                    <Utils.Observer value={context.initializationsForClient.currentOrganizationDropdownValue} didUpdate={() => this.refreshDashboards()} />
                    <div className={this.getClassName() + " flex-container"}>
                        {this.renderHeader()}
                        {this.renderContent()}
                    </div></>
            }</AppContainerContext.Consumer>;
    }
}

export const infoPersonalHomePage = new ConnectedPageInfo(slicePersonalHomePage, PersonalHomePage, "PersonalHomePage");
infoPersonalHomePage.routeProps = { path: "/personalHomePage", exact: true, homePageType: "personal" };"./..""../components/TabbedPage/TabbedPage""./dashboard/dashboardTab/DashboardTab""../AppContainerContext""../AppMetaTempGlobals""../FoundationEntityDescriptors"