import { searchOptions } from "@crispico/foundation-react";
import { atLeastOneSubmenuVisible } from "@crispico/foundation-react/utils/MenuUtils";
import lodash from 'lodash';
import React from "react";
import { Icon, Menu, Message } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { ReduxReusableComponents, RRCProps } from "../../reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "../../utils/Utils";
import { NavLinkWithPermission } from "../NavLinkWithPermission";
import { SearchEntityLink } from "./SearchEntityLink";
import { AdditionalParamsForReducers, Expanded, LinearizedItem, PropsNotFromState, Tree, TreeReducers, TreeState } from "../TreeRRC/Tree";
import { TreeMenuSearch } from "./TreeMenuSearch";

export class TreeMenuState extends TreeState {
}

export class TreeMenuReducers<S extends TreeMenuState = TreeMenuState> extends TreeReducers<S> {

    protected _hasChildren(item: any) {
        return item instanceof Array || item.submenus !== undefined;
    }

    protected _getChildren(item: any, additional: AdditionalParamsForReducers) {
        if (item.submenus) {
            const ch = (item.submenus as Array<any>).map((el, index) => ({ localId: "submenus" + Utils.defaultIdSeparator + String(index), item: el }));
            return ch;
        } else {
            return super._getChildren(item, additional);
        }
    }

    protected _matchesSearchExpression(currentItem: any): boolean {
        const content: string | undefined = currentItem.content;
        return typeof content === "string" &&  content.toLowerCase().includes(this.s.searchExpression.toLowerCase()) || false;
    }

}

export type TreeMenuProps = PropsNotFromState & {
    onMenuItemClick?: (isComposed: boolean) => void;
    showSearchBar?: boolean;
    multiSearch?: boolean;
} & RRCProps<TreeMenuState, TreeMenuReducers>;

// TODO doesn't have TestsAreDemo; cypress.js should be used as inspiration, then let's delete it.
export class TreeMenu extends Tree<TreeMenuProps> {

    static defaultProps = {
        showSearchBar: true
    };

    constructor(props: TreeMenuProps) {
        super(props);
        this.collapsedIcon = "caret right";
        this.expandedIcon = "caret down";
    }

    protected renderSearchEntityLinks() {
        return Object.keys(searchOptions).length > 0 && <div className="ui vertical menu">{Object.keys(searchOptions).map(entityName => {
            return <SearchEntityLink key={entityName} searchString={this.props.s.searchExpression} entityName={entityName} fields={searchOptions[entityName]} onClick={() => this.props.onMenuItemClick && this.props.onMenuItemClick(false)} />;
        })}</div>;
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>) {
        return (<>
            {this.props.showSearchBar ? <TreeMenuSearch onChange={value => this.modifySearchExpression(value)} /> : null}
            {this.props.multiSearch && this.props.s.searchExpression.length > 0 ? this.renderSearchEntityLinks() : null}
            {mainChildren.length > 0 ? <Menu {...mainProps} children={mainChildren} vertical={true} data-cy="treeMenu" /> : <Message data-cy="warningMsg" warning>{_msg("TreeMenu.expressionNotFound")}</Message>}
        </>);
    }

    protected createItemWrapperProps(linearizedItem: LinearizedItem) {
        // deactivate the super behavior; not needed
        return null as any;
    }

    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any): any {
        const expandedIcon = this.getChildrenIcon(linearizedItem);
        const { icon, content, ...menuItemProps } = Utils.navigate(this.getRoot(), linearizedItem.itemId);

        // Avoid rendering empty submenus
        if (expandedIcon && !atLeastOneSubmenuVisible(menuItemProps)) {
            return null;
        }

        menuItemProps.onClick = () => {
            expandedIcon && this.props.r.reveal(this.getRoot(), [linearizedItem.itemId], true, true, true, this.getAdditionalParamsForReducers());
            (this.props as any).onMenuItemClick?.apply(null, [expandedIcon ? true : false]);
        };
        menuItemProps.key = linearizedItem.itemId;
        if (!menuItemProps.style) { menuItemProps.style = {}; }
        menuItemProps.style.paddingLeft = (this.paddingLeftBase + linearizedItem.indent * this.paddingLeftFactor) + "em";

        if (linearizedItem.expanded === Expanded.EXPANDED) {
            menuItemProps.style.background = this.hoverColor;
        }
        if (!menuItemProps.children) {
            menuItemProps.children = (<>
                {typeof icon === 'string' ? <Icon className="TreeMenu_normalMenuItem" name={icon as SemanticICONS} /> : icon}
                {content}
                {expandedIcon && <Icon name={expandedIcon} />}
            </>);
        }

        if (!menuItemProps.as) {
            if (!expandedIcon) {
                // i.e. normal entry
                menuItemProps.as = NavLinkWithPermission;
            } else {
                // i.e. submenu
                menuItemProps.link = true;
            }
        }

        // else 'as' is specified => we are in the custom mode; so we don't enrich much
        // NOTE: this branch wasn't tested/used a lot; if we really need to customize, maybe 
        // additional modifs are needed to this function

        if (menuItemProps.external) {
            return <a href={menuItemProps.to} className="item" style={menuItemProps.style}>{menuItemProps.children}</a>;
        }

        return React.createElement(Menu.Item, menuItemProps);
    }

}

export const TreeMenuRRC = ReduxReusableComponents.connectRRC(TreeMenuState, TreeMenuReducers, TreeMenu);"../..""../../utils/MenuUtils"