import { FilterOperators, Operator } from "@crispico/foundation-gwt-js";
import { EntityDescriptor } from "../../entity_crud/EntityDescriptor";
import { Utils } from "../../utils/Utils"
import { ReactNode } from 'react';
import { AUDITABLE_ENTITY_TYPE, AUDITABLE_FIELD_TYPE, AuditUtils } from '@crispico/foundation-react/pages/Audit/AuditUtils';
import { EntityDescriptorForServerUtils } from '@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils';
import moment from "moment";

export interface Filter {

    field?: string;
    operator: string;
    value?: string;
    filters?: Array<Filter>;
    timezone?: string;

    /**
     * Only in JS!
     */
    enabled?: boolean;

    /**
     * Only in JS!
     */
    label?: string
}

export class Filter {
    static AND_DELIMITER = " AND ";
    static BETWEEN_DELIMITER = ',';
    static IN_DELIMITER = ',';

    // This function doesn't simply create an object based on the input. It may perform adjustments. E.g. filters for date fields using relative offsets (e.g. "today") 
    // are converted to something else containing absolute times. W/o this, "today" for an user in GMT + 10 means something else as "today" for a server w/ GMT + 0. 
    // And if we transmit to the server only "today", it won't have this info of + 10. Hence the need to use absolute dates.
    // Because of the above, please treat Filter objects as immutable. Don't modify them after they are created by this function (because the above logic won't be reapplied). 
    // We don't have code that enforces the immutability (because the immutability would be lost when we store a filter in the Redux state); so the callers should follow this convention.
    static create(field: string, operator: Operator, value?: string) {
        let filter = { field, operator: operator.value, value } as Filter;
        if (FilterOperators.getOperatorsForType(FilterOperators.TYPE_DATE).getOperators().find(op => op.value === operator.value)) {
            filter.timezone = Utils.getTimeZone();
        }
        return filter;
    }

    static createComposed(operator: Operator, filters: Filter[]) {
        return { operator: operator.value, filters: filters } as Filter
    }

    // Unfortunately the "enabled" implementation detail, which should have been an "internal" hack, spilled out of the filter UI. 
    // And sometimes the users HAVE TO supply it w/ true, or else it doesn't work. That's why we created a second set of function `...ForClient()`. 
    // When we clean the implementation, we should get rid of them. 
    static createForClient(field?: string, operator?: Operator, value?: string, enabledOnlyForJs?: boolean, label?: string) {
        const filter = Filter.create(field || '', operator || { value: '' }, value);
        filter.enabled = enabledOnlyForJs || true; 
        filter.label = label;
        return filter;
    }

    static createComposedForClient(operator: Operator, filters?: Filter[], enabledOnlyForJs?: boolean) {
        const filter = Filter.createComposed(operator, filters || []);
        filter.enabled = enabledOnlyForJs || true; 
        return filter;
    }

    static renderAsText(filter: Filter, ed: EntityDescriptor | undefined, foundAuditEntityMappingId?: string, paranthesis?: boolean) {
        let result: ReactNode[] = [];
        if (filter.enabled) {
            if (filter.label && filter.label.length > 0) {
                result.push(<b>{filter.label}</b>);
            } else if (filter.operator === FilterOperators.forComposedFilter.and.value || filter.operator === FilterOperators.forComposedFilter.or.value) {
                if (paranthesis) {
                    result.push("(");
                }

                const r: ReactNode[] = [];

                filter.filters?.forEach((f, index) => {
                    const result = this.renderAsText(f, ed, foundAuditEntityMappingId, filter.filters!.length > 1);
                    if (result.length > 0) {
                        r.push(result);
                    }
                });

                r.forEach((f, index) => {
                    result.push(f);
                    if (index + 1 < r.length) {
                        result.push(<i key={index}> {_msg("Filter.operator." + filter.operator)} </i>);
                    }
                });

                if (paranthesis) {
                    result.push(")");
                }
            } else if (filter.field && filter.field.length > 0) {
                const fd = ed?.getField(filter.field);
                let value: any = filter.value;
                if (value === true) {
                    value = "TRUE";
                } else if (value === false) {
                    value = "FALSE";
                } else if (value === 0) {
                    value = 0;
                } else if (fd && fd.type === AUDITABLE_ENTITY_TYPE) {
                    const ed = EntityDescriptorForServerUtils.getEntityDescriptor(value);
                    if (ed) {
                        value = ed.getLabel();
                    }
                } else if (fd && fd.type === AUDITABLE_FIELD_TYPE && foundAuditEntityMappingId) {
                    value = AuditUtils.getFieldLabelsFromIds(foundAuditEntityMappingId, value)
                } else if (fd && fd.type === 'date' && !FilterOperators.dateOperatorsWithNumberValue.find(operator => operator.value === filter.operator)
                && !FilterOperators.noValueOperators.find(operator => operator.value === filter.operator)) {
                    if (FilterOperators.twoValuesOperators.find(operator => operator.value === filter.operator)) {
                        const values = value.split(Filter.AND_DELIMITER);
                        const date1 = moment(values[0], moment.ISO_8601).format(Utils.dateTimeFormat);
                        const date2 = moment(values[1], moment.ISO_8601).format(Utils.dateTimeFormat);
                        value = date1 + " " + _msg("Filter.operator.and") + " " + date2;
                    } else if (filter.operator === FilterOperators.forDate.dayOf.value) {
                        value = moment(value, moment.ISO_8601).format(Utils.dateFormat);
                    } else {
                        value = moment(value, moment.ISO_8601).format(Utils.dateTimeFormat);
                    }
                } else {
                    if (value && value.length && value.split("|/|").length === 3) {
                        value = value.split("|/|")[1];
                    }
                }
                const fieldDescriptorChain = filter.field && ed?.getFieldDescriptorChain(filter.field);
                const composedFieldLabel = fieldDescriptorChain && ed?.getComposedFieldLabel(fieldDescriptorChain);
                result.push(<b key="field">{composedFieldLabel ? composedFieldLabel : filter.field} </b>);
                result.push(_msg("Filter.operator." + filter.operator));
                if (!FilterOperators.noValueOperators.find(operator => operator.value === filter.operator)) {
                    result.push(<b key="value"> {value}</b>);
                } else {
                    result.push();
                }
            }
        }
        return result;
    }

    static eliminateDisabledFilters(filter: Filter) {
        if (!filter?.enabled) { return undefined; }
        const operator: Operator = { value: filter.operator };
        let copy: Filter;
        if (FilterOperators.getOperatorsForType(FilterOperators.TYPE_COMPOSED_FILTER).getOperators().find(op => op.value === operator.value)) {
            copy = Filter.createComposed(operator, []);
        } else {
            copy = Filter.create(filter.field || '', operator, filter.value);
        }
        if (!filter.filters) { return copy; } // has no subfilters; so exit now
        for (let childFilter of filter.filters) {
            const childCopy = this.eliminateDisabledFilters(childFilter);
            if (childCopy) {
                copy.filters!.push(childCopy);
            } // else undefined => the filter was disabled; so we have nothing to add
        }
        if (copy.filters!.length === 0) { return undefined; }
        return copy;
    }

    static enableAllFilters(filter: Filter | undefined) {
        if (!filter) { return filter; }
        const copy: Filter = Filter.createForClient(filter.field, { value: filter.operator }, filter.value);
        if (!filter.filters) { return copy; }
        copy.filters = [];
        for (let childFilter of filter.filters) {
            const childCopy = Filter.enableAllFilters(childFilter);
            if (childCopy) {
                copy.filters!.push(childCopy);
            }
        }
        return copy;
    }
}
"../../pages/Audit/AuditUtils""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils"