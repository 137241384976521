import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { AuthenticationStatus, LoginParamsInput } from "@crispico/foundation-react/apollo-gen-foundation/globalTypes";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import React from "react";
import { Modal } from "semantic-ui-react";
import { ModalExt } from "@famiprog-foundation/scriptable-ui";
import { Link, Redirect } from "react-router-dom";
import { Button, CheckboxProps, Form, Grid, Icon, InputOnChangeData, Label, Message, Segment, Tab, TabProps, SemanticShorthandItem, TabPaneProps } from "semantic-ui-react";
import { login, loginVariables } from "../../apollo-gen-foundation/login";
import { HomePage, HomePageProps, infoHomePage } from "../HomePage";
import { expiredPasswordResetPageUrl, passwordResetBaseUrl } from "../passwordReset/PasswordResetFinishPage";
import { passwordResetInitPageUrl, passwordResetInitPageUrl_twoManyFailedLogins } from "../passwordReset/PasswordResetInitPage";
import { LOGIN } from "./queries";
import { userEntityDescriptor } from "../user/userEntityDescriptor";

const cookieMaxAge = `;max-age=2592000`;

export const LoginTypeEnum = {
    STANDARD: "STANDARD",
    LDAP: "LDAP"
  } as const;
export type LoginTypeEnum = typeof LoginTypeEnum[keyof typeof LoginTypeEnum];


export const sliceLoginPage = createSliceFoundation(class SliceLoginPage {

    reducers = {
        ...getBaseReducers<SliceLoginPage>(this),
    }
    impures = {
        ...getBaseImpures<SliceLoginPage>(this),

        async login(loginParams: LoginParamsInput, callback: (status: AuthenticationStatus, message?: string) => void) {
            const response = (await apolloClient.mutate<login, loginVariables>({
                mutation: LOGIN,
                variables: { loginParams }
            })).data?.authService_login;


            if (response!.token) {
                // when processing a GraphQL request server-side, we don't have a access to a HTTP response
                // to append a cookie, so it is set here, after a succesful login with remember-me selected.
                document.cookie = `auth_token=` + response!.token + cookieMaxAge;
            }

            if (response!.status === AuthenticationStatus.SUCCESS) {
                await AppMetaTempGlobals.appMetaInstance.afterLogin();
            }
            const list = response!.messageParams ? response!.messageParams : [];
            callback(response!.status!, _msg(response!.messageKey || "general.error", ...list));
        }
    }
})

type LocalState = {
    username: string,
    password: string,
    rememberMe: boolean,
    showPassword: boolean,
    loggedInStatus: string | null,
    error: string | undefined,
    loginType: string,
    disableLoginBtn: boolean,
    otpToken: string,
    showOtpTokenModal: boolean
};
type LoginPageProps = PropsFrom<typeof sliceLoginPage> & HomePageProps;

export class LoginPage extends HomePage<LoginPageProps, LocalState> {

    constructor(props: LoginPageProps) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onTabChanged = this.onTabChanged.bind(this);
        this.loginCallback = this.loginCallback.bind(this);
        this.createLoginParams = this.createLoginParams.bind(this);

        const loginTypeFromSession = window.sessionStorage.getItem("login.type");
        this.state = {
            username: "", password: "", rememberMe: false, showPassword: false, loggedInStatus: null, error: undefined,
            loginType: loginTypeFromSession ? loginTypeFromSession! : LoginTypeEnum.STANDARD, disableLoginBtn: false,
            otpToken: "", showOtpTokenModal: false
        };
    }

    protected onTabChanged(event: React.MouseEvent<HTMLDivElement>, data: TabProps) {
        const activePane = data.panes![data.activeIndex as number].pane! as TabPaneProps;
        let loginType = activePane ? activePane.loginType : LoginTypeEnum.STANDARD;
        // keep login type in session storage
        window.sessionStorage.setItem("login.type", loginType);
        this.setState({ username: "", password: "", showPassword: false, loggedInStatus: null, error: undefined, loginType: loginType, otpToken: "" });
    }

    protected verifyCredentials(username: string, password: string, otpToken: string) {
        if (username === "" || password === "" || (this.state.showOtpTokenModal && otpToken === "")) {
            this.loginCallback(AuthenticationStatus.FAILURE, _msg("login.notnull", this.state.showOtpTokenModal ? _msg("login.otpToken") : ""));
            return false;
        }
        return true;
    }

    protected loginCallback(status: AuthenticationStatus, message: any) {
        if (status === AuthenticationStatus.FAILURE) {
            this.setState({ error: message, showOtpTokenModal: false, otpToken: "" });
        } else if (status == AuthenticationStatus.REQUIRE_OTP_CODE) {
            this.setState({ showOtpTokenModal: true });
        } else {
            this.setState({ loggedInStatus: status, password: "", otpToken: "", showOtpTokenModal: false });
        }
    }

    protected async onSubmit() {
        // disable button after pressing, until request is over
        this.setState({ disableLoginBtn: true })

        if (this.verifyCredentials(this.state.username, this.state.password, this.state.otpToken)) {
            await this.props.dispatchers.login(this.createLoginParams(), this.loginCallback);
        }

        this.setState({ disableLoginBtn: false });
    }

    protected showPassword(prevShowPassword: boolean) {
        this.setState({ showPassword: !prevShowPassword })
    }

    // extra function to be easy to extend and overwrite
    protected getPanes(): {
        pane?: SemanticShorthandItem<TabPaneProps>
        menuItem?: any
        render?: () => React.ReactNode
    }[] {
        const panes = [
            {
                pane: { loginType: LoginTypeEnum.STANDARD as string },
                menuItem: { key: LoginTypeEnum.STANDARD as string, icon: userEntityDescriptor.getIcon(), content: _msg("login.standard") },
                render: () => this.renderStandardLoginTab()
            }
        ]
        if (this.context.initializationsForClient.ldapAvailable) {
            panes.push({
                pane: { loginType: LoginTypeEnum.LDAP as string },
                menuItem: { key: LoginTypeEnum.LDAP as string, icon: userEntityDescriptor.getIcon(), content: _msg("login.ldap") },
                render: () => this.renderLdapLoginTab()
            });
        }

        return panes;
    }

    protected renderUsernameInput(placeholder: string, autoFocus?: boolean) {
        return <Form.Input fluid icon='user' iconPosition='left' placeholder={placeholder} value={this.state.username}
            autoFocus={autoFocus}
            onChange={(evt: any, data: InputOnChangeData) => this.setState({ username: data.value })} />;
    }

    protected renderPasswordInput(placeholder: string, autoFocus?: boolean) {
        return <Form.Input fluid iconPosition='left' labelPosition='right corner' placeholder={placeholder} type={this.state.showPassword ? 'text' : 'password'} value={this.state.password}
            onChange={(evt: any, data: InputOnChangeData) => this.setState({ password: data.value })}>
            <Icon name='lock' />
            <input autoFocus={autoFocus} autoComplete='off' />
            <Label attached="top right" style={{ background: "transparent" }}><Icon name={this.state.showPassword ? 'eye slash' : 'eye'} className="no-margin" link onClick={() => this.showPassword(this.state.showPassword)} /></Label>
        </Form.Input>
    }

    protected renderTokenInput(placeholder: string, autoFocus?: boolean) {
        return <Form.Input fluid iconPosition='left' labelPosition='right corner' required placeholder={placeholder} type='text' value={this.state.otpToken}
            onChange={(evt: any, data: InputOnChangeData) => this.setState({ otpToken: data.value })}>
            <Icon name='lock' />
            <input autoFocus={autoFocus} autoComplete='off' />
            <Label attached="top right" style={{ background: "transparent" }} />
        </Form.Input>
    }

    protected renderStandardLoginTab() {
        return <>
            <Form error onSubmit={this.onSubmit} className="attached segment">
                {this.renderUsernameInput(_msg("login.username"), true)}
                {this.renderPasswordInput(_msg("login.password"))}
                {this.context.initializationsForClient.rememberMeEnabled ? <Form.Checkbox label={_msg("login.rememberMe")}
                    onChange={(evt: any, data: CheckboxProps) => this.setState({ rememberMe: data.checked ? data.checked : false })} /> : null}
                <Button disabled={this.state.disableLoginBtn} primary fluid size='large'>{_msg("login.signIn")}</Button>
                {this.state.error ? <Message style={{ textAlign: 'left' }} error header={_msg("alert.loginFailed")} content={this.state.error} /> : null}
            </Form>
            <Message attached="bottom" warning style={{ fontWeight: 'bold' }}>
                <Link to={passwordResetInitPageUrl}>
                    <span>{_msg("login.didYouForgetYourPassword")}</span>
                </Link>
            </Message>
            {this.context.initializationsForClient.oktaAuthenticationAvailable ? <Segment>
                <h4>{_msg("login.signInWith")}: </h4>
                {/* In development change the client port to server port (3000 -> 8080) to the link to use Okta */}
                <a className="ThirdPartyLoginButton" href={Utils.adjustUrlToServerContext("oauth2/authorization/okta")}><img src="https://www.okta.com/themes/custom/okta_www_theme/images/logo.svg?v2" width="60px" alt="Sign in with Okta" /></a>
            </Segment> : null}
        </>
    }

    protected renderLdapLoginTab() {
        return <Form error onSubmit={this.onSubmit} className="attached segment">
            {this.renderUsernameInput(_msg("login.ldap") + " " + _msg("login.username"), true)}
            {this.renderPasswordInput(_msg("login.password"))}
            <Button disabled={this.state.disableLoginBtn} primary fluid size='large'>{_msg("login.signIn")}</Button>
            {this.state.error ? <Message style={{ textAlign: 'left' }} error header={_msg("alert.loginFailed")} content={this.state.error} /> : null}
        </Form>
    }

    protected renderUnderJumbotron() {
        const panes = this.getPanes();
        var tabs = null;

        if (panes.length > 1) {
            tabs = <Tab menu={{ tabular: false, attached: true }} onTabChange={this.onTabChanged} activeIndex={panes.findIndex(p => (p.pane as TabPaneProps)?.loginType === this.state.loginType)} panes={panes} />;
        } else {
            tabs = panes[0].render?.();
        }

        return (<Grid textAlign='center' verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 450 }}>
                    {tabs}
                </Grid.Column>
            </Grid.Row>          
            {this.renderAdditionalRows()}
        </Grid>)
    }

    protected renderAdditionalRows() {
    }

    private getRedirectFromPrevious() {
        const from = this.props.location?.state?.from;
        if (!from || !from.pathname || from.pathname === "/") {
            return undefined;
        }
        return from.pathname + (from.search ? from.search : "");
    }

    private getRedirect() {
        return this.getRedirectFromPrevious() || { pathname: '/' };
    }

    private isRedirectAllowedWithoutUser() {
        const redirectFromPrevious = this.getRedirectFromPrevious();
        if (redirectFromPrevious?.startsWith(passwordResetBaseUrl)) {
            return true;
        }
        return false;
    }

    protected createLoginParams(): LoginParamsInput {
        const { username, password, rememberMe, loginType, otpToken } = this.state;
        return { username, password, rememberMe, loginType, otpToken };
    }

    render() {
        if (this.context.initializationsForClient.currentUser || this.isRedirectAllowedWithoutUser()) {
            return <Redirect to={this.getRedirect()} />;
        }
        if (this.state.loggedInStatus) {
            var redirectTo;
            if (this.state.loggedInStatus === AuthenticationStatus.SUCCESS) {
                redirectTo = this.getRedirect();
            } else if (this.state.loggedInStatus === AuthenticationStatus.SUCCESS_WITH_EXPIRED_PASSWORD) {
                redirectTo = expiredPasswordResetPageUrl + "?username=" + this.state.username;
            } else { //AuthenticationStatus.FAILURE_TOO_MANY_FAILED_LOGINS
                redirectTo = passwordResetInitPageUrl_twoManyFailedLogins;
            }
            return <Redirect to={redirectTo} />
        }
        return (<>
            <ModalExt open={this.state.showOtpTokenModal} moveable={false} closeIcon closeOnDimmerClick={false} width="30%" className="LoginPage_Token_Modal" onClose={() => this.setState({ otpToken: "", showOtpTokenModal: false })}>
                <Modal.Header>{_msg("login.OTP.header")}</Modal.Header>
                <Modal.Content className="flex-container gap5">
                    {_msg("login.OTP.description")}
                    {this.renderTokenInput("")}
                    <Button onClick={this.onSubmit} primary fluid size='large'>{_msg("general.ok")}</Button>
                </Modal.Content>
            </ModalExt>

            {super.renderMain()}
        </>);
    }

}

export const infoLoginPage = new ConnectedPageInfo(sliceLoginPage, LoginPage, "LoginPage", undefined, false);
infoLoginPage.routeProps = { path: "/login", exact: true };
"../..""../../apollo-gen-foundation/globalTypes""../../AppMetaTempGlobals"