import gql from "graphql-tag";

export const LOAD_NOTIFICATIONS = gql`query notificationService_findByFilter($params: CustomFindByFilterParamsInput) {
  notificationService_findByFilter(params: $params) {
    totalCount
    results {
        id creationDate entityName entityId code severity description deletionDate
    }
  }
}`;

export const READ_NOTIFICATIOS = gql`mutation notificationAndUserService_readNotifications($ids: [Long], $readDate: Date) {
  notificationAndUserService_readNotifications(ids: $ids, readDate: $readDate)
}`;