import { push } from "connected-react-router";
import { DocumentNode } from "graphql";
import { Location } from "history";
import lodash from "lodash";
import { RedirectProps, RouteProps } from "react-router-dom";
import { ENT_READ, Optional, Utils } from "..";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { ModalExtOpen } from "../components/ModalExt/ModalExt";
import { TabbedPage, TabbedPageLocationState, TabbedPageProps, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { Dashboard } from "../pages/dashboard/DashboardEntityDescriptor";

import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "../reduxHelpers";
import { ID } from "./entityCrudConstants";
import { EntityDescriptor } from "./EntityDescriptor";

let DashboardTabRRC: any;

init();

async function init() {
    const tab = await import("../pages/dashboard/dashboardTab/DashboardTab");
    DashboardTabRRC = tab.DashboardTabRRC;
}

export type AbstractCrudPageLocationState = TabbedPageLocationState & {
    dontCloseDrawer?: boolean
}

// TODO RM35382: De eliminat intreg fisierul dupa migrarea la RRC si a EntityEditorPage, cf. pct 2/
export class SliceAbstractCrudPage {

    // TODO by CS: these are not used any more in the table; because they are calculated at each invocation;
    // I think we should remove from the editor page as well; and then from here
    loadQuery!: DocumentNode;
    loadOperationName!: string;

    loadParamType!: string;
    _entityDescriptor!: EntityDescriptor

    /**
     * Getters and setters to be able to override them w/ error, in the case of sliceEntityEditorPageOnlyForExtension
     */
    get entityDescriptor(): EntityDescriptor {
        return this._entityDescriptor;
    }

    set entityDescriptor(value: EntityDescriptor) {
        this._entityDescriptor = value;
    }

    /**
     * And this builder style setters saves one line of code in some places.
     */
    setEntityDescriptor(entityDescriptor: EntityDescriptor) {
        this.entityDescriptor = entityDescriptor;
        return this;
    }

    /**
     * Returns fields in column name format, example: ["name", "color", "object.type"]
     * Extend this method to add additional fields
     */
    getFieldsToRequest() {
        return Object.keys(this.entityDescriptor.fields);
    }

    /**
     * Returns fields in GraphQL format, example: `object { name color }`
     * Override this if the fields/expression cannot be added with the method above
     */
    getAdditionalGraphQl() {
        return "";
    }

    /**
     * Called when the component is created. The overridden function will create the query(es), if they are
     * not already created. This way, this happens as late as possible, when all the descriptors are "stable", i.e.
     * the ones needing override were already overridden. And this, because this process also looks at other
     * descriptors (e.g. Leave -> LeaveType) in getFieldsToRequest(), so the descriptors need to be both there; otherwise
     * the loading order would matter, and there would of course be "race" cases.
     */
    initIfNeeded(): void { };

    getId(entity: any) {
        return entity[ID];
    }

    initialState = {
        modalOpen: false as ModalExtOpen,
        attachedDashboards: [] as Dashboard[]
    }

    reducers = {
        ...getBaseReducers<SliceAbstractCrudPage>(this),

        /**
         * Now it doesn't do much. But this is an API method called by users; and maybe in
         * the future (when many users will use it) we'll want to do staff here.
         */
        setModalOpen(state: StateFrom<SliceAbstractCrudPage>, modalOpen: ModalExtOpen) {
            state.modalOpen = modalOpen;
        }
    }

    impures = {
        ...getBaseImpures<SliceAbstractCrudPage>(this),
    }

}

export const sliceAbstractCrudPageOnlyForExtension = createSliceFoundation(class extends SliceAbstractCrudPage { get entityDescriptor(): EntityDescriptor { throw new Error("This instance is only an utility for extension; it cannot be used.") } }, true);

export type AbstractCrudPageProps = PropsFrom<SliceAbstractCrudPage> & TabbedPageProps;

export abstract class AbstractCrudPage<P extends AbstractCrudPageProps> extends TabbedPage<P> {

    constructor(props: P) {
        super(props);
        (props as any).dispatchers.getSlice().initIfNeeded(); // too much work to have a proper type here; hence "any"
        this.onModalClose = this.onModalClose.bind(this);
    }

    protected onModalClose() {
        this.props.dispatchers.setModalOpen(false);
    }

    componentDidUpdate(prevProps: any) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: P) {
        const { props } = this;

        const locationState: Optional<AbstractCrudPageLocationState> = props.location?.state;
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, "Dashboard"])) && props.dispatchers.getSlice().entityDescriptor.hasAttachedDashboards && AppMetaTempGlobals.appMetaInstance.dashboardsAvailable &&
            locationState?.redirectToMain && !lodash.isEqual(prevProps?.attachedDashboards, props.attachedDashboards)) {
            // it's important to give location.state in order to have the values declared in 
            props.dispatchers.dispatch(push({ pathname: this.getMainRoutePath(), state: locationState }));
            return;
        }
    }

    protected renderAttachedDashboard(id: number, additionalProps: {}) {
        return <DashboardTabRRC id={"attachedDashboard_" + id} dashboardId={id} {...additionalProps} />
    }

    protected getRedirectToFirstPaneRouteProps(redirectProps: RedirectProps): RouteProps {
        const location = this.props.location;
        // prepare redirect location props by getting them from current location
        const redirectToLocation = redirectProps.to as Location<any>;
        if (location?.search) {
            redirectToLocation.search = location.search;
        }
        (redirectToLocation.state as AbstractCrudPageLocationState).dontCloseDrawer = (location?.state as AbstractCrudPageLocationState)?.dontCloseDrawer;

        return super.getRedirectToFirstPaneRouteProps(redirectProps);
    }

    render() {
        if (this.propsCasted.modalProps) {
            // not 100% fond of this way of passing params to super; but let's leave it like this for now
            this.modalProps = { ...this.propsCasted.modalProps, open: this.props.modalOpen, onClose: this.onModalClose };
        }
        return super.render();
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const dashboardTabs: Optional<(TabRouterPane | null)[]> = [];
        this.props.attachedDashboards?.forEach((dashboard, index) => {
            dashboardTabs.push({
                routeProps: { path: "/dashboard_" + (index + 1) },
                menuItemProps: { icon: dashboard.icon ? dashboard.icon : "chart pie", content: dashboard.name ? dashboard.name : _msg("Dashboard.label") },
                render: () => this.renderAttachedDashboard(dashboard.id, {})
            });
        });

        return dashboardTabs;
    }

}