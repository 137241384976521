import React, { ElementType, useEffect, useRef, useState } from "react";
import { ResponsivePie, PieSvgProps, DatumId, PieTooltipProps, ComputedDatum, DataProps, DefaultRawDatum } from "@nivo/pie";
import { ResponsiveLine, LineSvgProps, Point, PointTooltip, PointTooltipProps } from "@nivo/line";
import { Theme } from "@nivo/core";
import moment from "moment";
import { Utils } from "..";
import { Filter } from "./CustomQuery/Filter";
import { createPortal } from "react-dom";

export type PieDatum = {
    id: DatumId;
    label: DatumId;
    value: number;
    color?: string;
    filter?: Filter;
}

export type ResponsivePieProps = Omit<PieSvgProps<PieDatum>, "width" | "height"> & { renderTooltipContent?: (value: ComputedDatum<PieDatum>) => React.ReactNode };

export const customTheme: Theme = {
    textColor: 'var(--textColor)',
    labels: {
        text: {
            fontWeight: 900,
            fontSize: 12,
            fill: '#000'
        }
    }
}

export class CenteredMetric {
    fontSize: number = 52;

    constructor(fontSize?: number) {
        if (fontSize) {
            this.fontSize = fontSize;
        }
    }

    get() {
        return (data: { dataWithArc: PieDatum[], centerX: number, centerY: number, fontSizeForTotal?: number }) => {
            let total = 0
            data.dataWithArc.forEach(datum => {
                total += datum.value
            })

            return (
                <text
                    x={data.centerX}
                    y={data.centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: this.fontSize + 'px',
                        fontWeight: 600,
                        fill: 'var(--textColor)'
                    }}
                >
                    {total}
                </text>
            )
        }
    }
}

/**
 * nivo library has some issues displaying the tooltip. 
 * Because they don't seem to work on them soon, we created this component that uses a portal.
 * Inspired from https://github.com/plouc/nivo/issues/2648
 * 
 * Nivo tooltip issues: 
 * https://github.com/plouc/nivo/issues/580
 * https://github.com/plouc/nivo/issues/2648
 */
const ResponsivePieExtTooltipWrapper: React.FC<{ renderTooltipContent: any, data: PieDatum; position: { x: number; y: number } }> = ({ renderTooltipContent, data, position }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [tooltipWidth, setTooltipWidth] = useState(0);

    useEffect(() => {
        if (tooltipRef.current) {           
            setTooltipWidth(tooltipRef.current.getBoundingClientRect().width);
        }
    }, [data]);

    return createPortal(
        <div ref={tooltipRef} className="flex-container-row"            
            style={{ position: 'absolute', top: position.y + 10, left: position.x + 10, 
            // because the widget has a z-index = 1 (2 on hover), so we want this tooltip to be on top of it
            zIndex: 10 }}>
            {renderTooltipContent?.call(null, data)}
        </div>,
        document.body
    );
};

export const ResponsivePieExt = (props: ResponsivePieProps) => {
    if (props.tooltip) {
        throw Error("`tooltip` has been disabled! Please use `renderTooltipContent` instead!");
    }
    const [tooltipData, setTooltipData] = useState<ComputedDatum<PieDatum> | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    return (<><ResponsivePie margin={{ left: 10, right: 10, top: 10, bottom: 10 }} colors={{ scheme: 'category10' }}
        arcLinkLabelsDiagonalLength={5} arcLinkLabelsColor={{ from: 'color', modifiers: [] }} arcLabelsSkipAngle={10} arcLinkLabelsSkipAngle={5}
        innerRadius={0.5} padAngle={2} cornerRadius={0} sortByValue={true} fit={true}
        borderWidth={1} borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }} theme={customTheme}
        isInteractive={true} activeInnerRadiusOffset={0} activeOuterRadiusOffset={8}
        {...props}
        tooltip={(p: PieTooltipProps<PieDatum>) => {
            setTooltipData(p.datum);
            return null; // Prevent the default tooltip rendering
        }}
        onMouseMove={(datum, event) => { 
            setTooltipPosition({ x: event.clientX, y: event.clientY }); 
            props.onMouseMove?.call(null, datum, event); 
        }}
        onMouseLeave={(datum, event) => { 
            setTooltipData(null);
            props.onMouseLeave?.call(null, datum, event); 
        }}
    />
    {tooltipData && (
        <ResponsivePieExtTooltipWrapper data={tooltipData} position={tooltipPosition} renderTooltipContent={props.renderTooltipContent} />
    )}</>);
}

export type ResponsiveLineExtProps = LineSvgProps & { renderTooltipContent?: (value: PointTooltipProps) => React.ReactNode, legendX?: string, legendY?: string, xScaleFormat?: string, minYScale?: number, maxYScale?: number };

export const ResponsiveLineExt = (props: ResponsiveLineExtProps) => {
    // after upgrade to nivo 0.7.2, if dataset empty => error; hence the check for data.length > 0
    // I tried to reproduce this w/ constant data in the "code sandbox" of nivo; w/o success. I think it's somehow
    // the fact that we have initial empty data + it arrives later: this causes maybe the issue
    return (!(props.data?.reduce((previousValue, currentData) => previousValue + currentData.data.length, 0)) ? null : <div id="ResponsiveLineChartContainer" className="ResponsiveLineChartContainer"><ResponsiveLine
        margin={{ top: 50, bottom: 80, right: 110, left: 60 }} useMesh={true} enableSlices={false}
        xScale={{ type: 'time', format: props.xScaleFormat ? props.xScaleFormat : "%Q", precision: 'second' }} xFormat="time:%Y-%m-%d %H:%M:%S"
        axisBottom={{ legend: props.legendX, format: '%H %M', legendOffset: 35 }}

        yScale={{ type: 'linear', stacked: false, min: props.minYScale !== undefined ? props.minYScale : "auto", max: props.maxYScale !== undefined ? props.maxYScale : "auto" }}
        axisLeft={{ legend: props.legendY, legendOffset: -40 }}

        legends={[{
            anchor: 'bottom-right', direction: 'column', translateX: 100, itemWidth: 80, itemHeight: 20, itemOpacity: 0.75, symbolSize: 12,
            symbolShape: 'circle', symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [{ on: 'hover', style: { itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 } }]
        }]}

        tooltip={(value) => ResponsiveLineExtTooltipWrapper(props.renderTooltipContent?.call(null, value), value)}
        colors={{ datum: 'color' }}
        pointSize={4.5} pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]], }}
        {...props}
    /></div>);

}

export const legendsBottom = [
    {
        anchor: 'bottom',
        direction: 'row',
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000'
                }
            }
        ]
    }
]

const ResponsiveLineExtTooltipWrapper = (content: React.ReactNode, value: PointTooltipProps) => {
    const point = value.point;
    return (<div style={{ transform: value.point.x <= 200 ? "translate(0, 0)" : "translate(-200px, 0)" }} >
        <div style={{ position: 'absolute', background: 'white', padding: '5px 9px', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px' }}>
            {content ? content :
                <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + point.id}>
                    <span><strong>{point.data.y}</strong></span>
                    <span> [{_msg("general.at", moment(point.data.x).format(Utils.dateTimeWithSecFormat))}]</span>
                </div>
            }
        </div>
    </div>);
};

export const helper = (list: string[]) => {
    if (list.length === 0) return ''
    list[list.length - 1] = list[list.length - 1].slice(0, list[list.length - 1].length - 2)
    return list
}