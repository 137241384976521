import { AppContainerContext, AppContainerContextValue } from "@crispico/foundation-react/AppContainerContext";
import { Organization, OrganizationFilter } from "@crispico/foundation-react/AppMeta";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { AdditionalParamsForReducers, LinearizedItem, OnSelectParams, PropsNotFromState, RenderItemParams, Tree, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { Scriptable, ScriptableContainer } from "@famiprog-foundation/scriptable-ui";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Button, Checkbox, CheckboxProps, Segment, Divider, Icon, Label, Input, InputOnChangeData, Message, MessageHeader, SegmentGroup, LabelDetail, Popup, Dimmer, SemanticSIZES, ButtonGroup } from "semantic-ui-react";

export const NO_ORGANIZATION = { key: "organizationsTreeAllOrgsItem", text: _msg("Organization.all"), value: undefined }
export const OTHER_CHECKED_ORGANIZATION_COLOR = "var(--lightBlue)";
export const COMBINE_CHECK = "combineCheck";
export const FILTER_LEFT_BAR_CHECK = "filterLeftBarCheck";
export const FILTER_LEFT_BAR_ORGANIZATIONS = "filterLeftBarOrganizations";
export const LEFT_BAR_FONT_SIZE = "7pt";

export function setCombineCheck(combineCheck?: boolean) {
    try {
        window.sessionStorage.setItem(COMBINE_CHECK, JSON.stringify(combineCheck || false));
    } catch (e) {
        console.log(e);
    }
}

export function getCombineCheck(): boolean {
    try {
        const objectFromSessionStorage = window.sessionStorage.getItem(COMBINE_CHECK);
        if (objectFromSessionStorage) {
            return JSON.parse(objectFromSessionStorage);
        }
        setCombineCheck(false);
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export function setFilterLeftBarCheck(filterLeftBarCheck?: boolean) {
    try {
        window.sessionStorage.setItem(FILTER_LEFT_BAR_CHECK, JSON.stringify(filterLeftBarCheck || false));
    } catch (e) {
        console.log(e);
    }
}

export function getFilterLeftBarCheck(): boolean {
    try {
        const objectFromSessionStorage = window.sessionStorage.getItem(FILTER_LEFT_BAR_CHECK);
        if (objectFromSessionStorage) {
            return JSON.parse(objectFromSessionStorage);
        }
        setFilterLeftBarCheck(false);
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export function setFilterLeftBarOrganizations(filterLeftBarOrganizations: string[]) {
    try {
        window.sessionStorage.setItem(FILTER_LEFT_BAR_ORGANIZATIONS, JSON.stringify(filterLeftBarOrganizations));
    } catch (e) {
        console.log(e);
    }
}

export function getFilterLeftBarOrganizations(): string[] {
    try {
        const objectFromSessionStorage = window.sessionStorage.getItem(FILTER_LEFT_BAR_ORGANIZATIONS);
        if (objectFromSessionStorage) {
            return JSON.parse(objectFromSessionStorage);
        }
        setFilterLeftBarOrganizations([]);
        return [];
    } catch (e) {
        console.log(e);
        return [];
    }
}

class OrganizationTreeBaseState extends TreeState { }
class OrganizationTreeBaseReducers<S extends OrganizationTreeBaseState = OrganizationTreeBaseState> extends TreeReducers<S> {

    protected _getChildren(item: Organization | Organization[], additional: AdditionalParamsForReducers): { localId: string, item: Organization; }[] {
        if (item instanceof Array) {
            return item.map((o: Organization) => ({ localId: o.id.toString(), item: o }));
        }
        if (item.children) {
            return _.sortBy(item.children!, (o: Organization) => { o.name?.toLowerCase(); }).map((o: Organization) => { return { localId: o.id.toString(), item: o } });
        }
        return [];
    }

    protected _hasChildren(item: Organization | Organization[], additional: AdditionalParamsForReducers): boolean {
        if (item instanceof Array) {
            return item.length > 0
        }
        return (item!.children?.length || 0) > 0;
    }

    protected _matchesSearchExpression(currentItem: Organization): boolean {
        return currentItem.qualifiedName.toLowerCase().includes(this.s.searchExpression.toLowerCase()) || false;
    }
}
type OrganizationTreeBaseProps = PropsNotFromState & RRCProps<OrganizationTreeBaseState, OrganizationTreeBaseReducers>;
class OrganizationTreeBase extends Tree<OrganizationTreeBaseProps> {
    protected createItemWrapperProps(linearizedItem: LinearizedItem): any {
        return { ...super.createItemWrapperProps(linearizedItem), className: linearizedItem.itemId === this.props.s.selectedId ? "OrganizationTree_selectedItem" : undefined }
    }
}
const OrganizationTreeBaseRRC = ReduxReusableComponents.connectRRC(OrganizationTreeBaseState, OrganizationTreeBaseReducers, OrganizationTreeBase);

export type OrganizationDropdownValue = {
    organization: Optional<string>,
    includeMyOtherOrganizations: boolean,
    alsoIncludeOrganizations: string[]
}

export type OrganizationTreeRoot = {
    children: Optional<Organization[]>,
}

export class OrganizationTreeState extends State {
    itemIds: string[] = [];
    organizationDropdownValue: OrganizationDropdownValue = { organization: undefined, alsoIncludeOrganizations: [], includeMyOtherOrganizations: false };
    expandedIds: { [key: string]: boolean; } = {};
}

export class OrganizationTreeReducers<S extends OrganizationTreeState = OrganizationTreeState> extends Reducers<S> {
    updateOrganizationDropdownValue(organizationDropdownValue: OrganizationDropdownValue) {
        this.s.organizationDropdownValue.includeMyOtherOrganizations = organizationDropdownValue.includeMyOtherOrganizations;
        this.s.organizationDropdownValue.organization = organizationDropdownValue.organization;
        this.updateAlsoIncludeOrganizations(organizationDropdownValue.alsoIncludeOrganizations);
    }

    updateIncludeMyOtherOrganizations(includeMyOtherOrganizations: boolean) {
        this.s.organizationDropdownValue.includeMyOtherOrganizations = includeMyOtherOrganizations;
    }

    updateAlsoIncludeOrganizations(alsoIncludeOrganizations: string[]) {
        this.s.organizationDropdownValue.alsoIncludeOrganizations = Array.from(new Set(alsoIncludeOrganizations));
    }
}

export type OrganizationTreePropsNotFromState = {
    root: Optional<OrganizationTreeRoot>,
    filterLeftBarCheck?: boolean,
    combineCheck?: boolean,
    organizationDropdownValue?: Optional<OrganizationDropdownValue>,
    asLeftBar?: boolean
    onSelectOrganizationItem?: (organizationDropdownValue: OrganizationDropdownValue) => void,
};

export type OrganizationTreeProps = OrganizationTreePropsNotFromState & RRCProps<OrganizationTreeState, OrganizationTreeReducers>;
export class OrganizationTree extends React.Component<OrganizationTreeProps> {
    protected refOrganizationTree = React.createRef<OrganizationTreeBase>();
    static contextType = AppContainerContext;
    context!: AppContainerContextValue;
    static defaultProps = {
        filterLeftBarCheck: false,
        combineCheck: false,
    };

    constructor(props: OrganizationTreeProps) {
        super(props);
        this.renderOrganizationTreeItem = this.renderOrganizationTreeItem.bind(this);
        this.getOrganizationTreeItemStyleProps = this.getOrganizationTreeItemStyleProps.bind(this);
        this.onSelectOrganizationItem = this.onSelectOrganizationItem.bind(this);
        this.renderMainElementFunction = this.renderMainElementFunction.bind(this);
    }

    async componentDidMount(): Promise<void> {
        // The initialization of these fields from state is necessary because based on them some methods will be executed and will return some props,
        // that will be passed to the internal component of the tree and these will be only used in componentDinMount().
        if (this.props.organizationDropdownValue) {
            this.props.r.updateOrganizationDropdownValue({ ...this.props.organizationDropdownValue });
        }
        await this.setItemIdsForRoot(this.props.root);
        this.props.r.setInReduxState({ expandedIds: this.getInitialExpandedIds() });
    }

    componentDidUpdate(prevProps: Readonly<OrganizationTreeProps>, prevState: Readonly<{}>): void {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    protected async componentDidUpdateInternal(prevProps?: Readonly<OrganizationTreeProps>, prevState?: Readonly<{}>) {
        if (!_.isEqual(this.props.root, prevProps?.root)) {
            this.setItemIdsForRoot(this.props.root);
        }
        if (!_.isEqual(this.props.organizationDropdownValue, prevProps?.organizationDropdownValue) && this.props.organizationDropdownValue) {
            this.props.r.updateOrganizationDropdownValue({ ...this.props.organizationDropdownValue });
            this.refOrganizationTree.current!.setSelectedId(this.getInitialSelectedId());
        }
        if (this.props.combineCheck != prevProps?.combineCheck || this.props.filterLeftBarCheck != prevProps?.filterLeftBarCheck) {
            if (this.props.filterLeftBarCheck) {
                if (this.props.organizationDropdownValue?.organization) {
                    const organization = this.getAllOrganizationsThatUserHasAccess().find(org => org.qualifiedName == this.props.s.organizationDropdownValue.organization)!
                    this.checkOrganization(organization, true);
                }
            } else if (this.props.combineCheck) {
                this.checkAll();
            } else {
                this.uncheckAll();
            }
            this.props.r.updateIncludeMyOtherOrganizations(this.props.combineCheck || false);
        }
    }

    modifySearchExpression(value: string) {
        this.refOrganizationTree.current?.modifySearchExpression(value);
    }

    getCurrentOrganizationDropdownValue(): OrganizationDropdownValue {
        return this.props.s.organizationDropdownValue;
    }

    checkAll() {
        this.checkOrUncheckAll(true);
    }

    uncheckAll() {
        this.checkOrUncheckAll(false);
    }

    combineAll() {
        this.combineOrSeparateAll(true);
    }

    separateAll() {
        this.combineOrSeparateAll(false);
    }

    expandAll() {
        this.expandArCollapseAll(true);
    }

    collapseAll() {
        this.expandArCollapseAll(false);
    }

    protected checkOrUncheckAll(checkAll: boolean) {
        const organization: Organization = this.getAllOrganizationsThatUserHasAccess().find(org => org.qualifiedName == this.props.s.organizationDropdownValue.organization)!;
        this.props.r.updateAlsoIncludeOrganizations(checkAll ? this.getQualifiedNameForOrganizationsThatUserHasAccess() : this.getOrganizationAndChildrenQualifiedNames(organization));
    }

    protected combineOrSeparateAll(combineAll: boolean) {
        this.checkOrUncheckAll(combineAll);
        this.props.r.updateIncludeMyOtherOrganizations(combineAll);
    }

    protected expandArCollapseAll(expandAll: boolean) {
        const expandedIds: { [key: string]: boolean; } = {};
        this.props.s.itemIds.forEach(itemId => expandedIds[itemId] = expandAll);
        this.props.r.setInReduxState({ expandedIds });
        if (expandAll) {
            this.refOrganizationTree.current!.setSelectedId(this.getInitialSelectedId());
        }
    }

    protected getIncludeMyOtherOrganizations(): boolean {
        return this.getCurrentOrganizationDropdownValue().includeMyOtherOrganizations;
    }

    /**
     * For the left bar will return only for those checked organizations.
     */
    protected getQualifiedNameForOrganizationsThatUserHasAccess() {
        return (this.props.asLeftBar ? AppMetaTempGlobals.appMetaInstance.getLeftBarOrganizations(this.getCurrentOrganizationDropdownValue(), this.context.organizations) : this.context.organizations || []).filter(organization => organization.id != NO_ORGANIZATION.key).map(organization => organization.qualifiedName);
    }

    protected getCurrentOrganization(): Optional<Organization> {
        return this.context.initializationsForClient.currentOrganization;
    }

    protected getAllOrganizationsThatUserHasAccess(): Organization[] {
        return this.context.organizations || [];
    }

    protected getInitialExpandedIds() {
        const expandedIds: { [key: string]: boolean; } = {};
        if (!this.props.asLeftBar) {
            const currentOrganizationDropdownValue = this.getCurrentOrganizationDropdownValue();
            (currentOrganizationDropdownValue.organization ? [currentOrganizationDropdownValue.organization] : []).concat(currentOrganizationDropdownValue.alsoIncludeOrganizations).forEach(qualifiedName => {
                const organization: Optional<Organization> = this.getAllOrganizationsThatUserHasAccess().find(organization => organization.qualifiedName == qualifiedName);
                if (organization) {
                    var itemId: string | undefined = this.props.s.itemIds.find(itemId => Utils.substringAfter(itemId, Utils.defaultIdSeparator, true) == organization.id.toString());
                    if (itemId) {
                        expandedIds[Utils.substringBefore(itemId, Utils.defaultIdSeparator)] = true;
                        while (itemId.includes(Utils.defaultIdSeparator)) {
                            expandedIds[itemId] = true;
                            itemId = Utils.substringBefore(itemId, Utils.defaultIdSeparator, true);
                        }
                    }
                }
            });
        }
        return expandedIds;
    }

    private async setItemIdsForRoot(root: Optional<OrganizationTreeRoot>) {
        var itemIds: string[] = [];
        root?.children?.forEach((organization: Organization) => {
            itemIds = itemIds.concat(this.getItemIdsForOrganizationAndChildren(organization));
        });
        await this.props.r.setInReduxState({ itemIds: itemIds });
        this.refOrganizationTree.current!.setSelectedId(this.getInitialSelectedId());
    }

    private getItemIdsForOrganizationAndChildren(organization: Organization, parentItemId?: string): string[] {
        const currentItemId: string = parentItemId ? parentItemId + Utils.defaultIdSeparator + organization.id : organization.id.toString();
        var ids: string[] = [currentItemId];
        organization.children?.forEach((child: Organization) => {
            ids = ids.concat(this.getItemIdsForOrganizationAndChildren(child, currentItemId));
        })
        return ids;
    }

    protected getOrganizationFromTreeItemId(treeItemId: string): Optional<Organization> {
        const itemId = Utils.substringAfter(treeItemId, Utils.defaultIdSeparator, true);
        if (itemId == NO_ORGANIZATION.key) {
            return { id: NO_ORGANIZATION.key } as Organization
        }
        return this.getAllOrganizationsThatUserHasAccess().find(o => o.id.toString() === itemId);
    }

    protected getOrganizationAndChildrenQualifiedNames(organization: Organization) {
        let organizationsQualifiedNames: string[] = [organization.qualifiedName];
        organization.children?.forEach(org => {
            organizationsQualifiedNames = organizationsQualifiedNames.concat(this.getOrganizationAndChildrenQualifiedNames(org));
        });
        return organizationsQualifiedNames
    }

    protected checkOrganization(changedOrganization: Organization, checked: boolean) {
        const organizations: string[] = this.getOrganizationAndChildrenQualifiedNames(changedOrganization);
        this.props.r.updateAlsoIncludeOrganizations(checked ?
            this.props.s.organizationDropdownValue.alsoIncludeOrganizations.concat(organizations)
            : this.props.s.organizationDropdownValue.alsoIncludeOrganizations.filter(org => !organizations.includes(org)));
    }

    protected renderCheckForAlsoIncludedOrganizations(organization: Optional<Organization>) {
        if (!(this.props.filterLeftBarCheck && !this.props.asLeftBar)) {
            return <></>
        }
        const organizationDropdownValue = this.getCurrentOrganizationDropdownValue();
        // if this is the current org or if it's parent is already checked
        const disabled = organizationDropdownValue.organization == organization?.qualifiedName || organizationDropdownValue.alsoIncludeOrganizations.includes(organization?.parent?.qualifiedName || "");
        return <Scriptable id={organization?.qualifiedName || NO_ORGANIZATION.key} onChange={(organization: Organization, checked: boolean) => this.checkOrganization(organization, checked)}>
            {scriptable =>
                organization?.id != NO_ORGANIZATION.key ?
                    <Checkbox data-testId={"otherOrgCheck_" + (organization?.qualifiedName || NO_ORGANIZATION.key)} className="align-self-center" checked={disabled || organizationDropdownValue.alsoIncludeOrganizations.includes(organization?.qualifiedName || "")}
                        onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
                        disabled={disabled}
                        onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => scriptable.proxy.onChange(organization, data.checked)} />
                    : <Checkbox indeterminate disabled onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()} />}
        </Scriptable>
    }

    protected renderOrganizationTreeItem({ props, linearizedItem }: RenderItemParams) {
        const itemId = Utils.substringAfter(linearizedItem.itemId, Utils.defaultIdSeparator, true);
        const organization: Optional<Organization> = this.getOrganizationFromTreeItemId(linearizedItem.itemId);
        return <div className={this.props.asLeftBar ? "flex-container flex-center" : "flex-container-row gap3"}>
            {this.renderCheckForAlsoIncludedOrganizations(organization)}
            {AppMetaTempGlobals.appMetaInstance.getOrganizationTreeIcon(itemId, organization, this.getCurrentOrganization(), { size: "large" })}
            <span className={this.props.asLeftBar ? "OrganizationTreeLeftArea_text" : ""}>
                {organization?.id === NO_ORGANIZATION.key ? NO_ORGANIZATION.text : organization?.name}
            </span>
        </div>;
    }

    private getOrganizationTreeItemStyleProps({ props, linearizedItem }: RenderItemParams) {
        const organization: Optional<Organization> = this.getOrganizationFromTreeItemId(linearizedItem.itemId);
        const style = {};
        const isSelected = props.s.selectedId == linearizedItem.itemId;
        const isHovered = linearizedItem.itemId == props.s.hoveredId;
        Object.assign(style, {
            flexDirection: "row", borderRadius: '2px 0px 0px 2px',
            paddingBottom: '5px', paddingTop: '5px', fontSize: '12pt',
            ...(!isSelected ? (isHovered ? { backgroundColor: "lightgrey" } : { backgroundColor: 'rgba(255, 255, 255, 0.25)' }) : {}),
        })
        if (this.props.asLeftBar) {
            const ids = linearizedItem.itemId.split(Utils.defaultIdSeparator);
            Object.assign(style, {
                paddingLeft: '7px',
                flexDirection: "column", fontSize: LEFT_BAR_FONT_SIZE,
                marginLeft: ids.length > 1 ? (ids.length - 1) * 10 + "px" : undefined,
                ...(isSelected ? { borderRight: "solid", borderBottom: "solid", borderRightColor: "lightgray", borderBottomColor: "lightgray" } : {}),
            })
        }
        if (this.props.combineCheck && organization && this.getCurrentOrganizationDropdownValue().alsoIncludeOrganizations.includes(organization.qualifiedName)) {
            Object.assign(style, { backgroundColor: OTHER_CHECKED_ORGANIZATION_COLOR, color: "black" });
        }
        return style;
    }

    protected getInitialSelectedId(): string | undefined {
        return !this.getCurrentOrganization() ? NO_ORGANIZATION.key : this.props.s.itemIds.find(itemId => Utils.substringAfter(itemId, Utils.defaultIdSeparator, true) == this.getCurrentOrganization()!.id.toString());
    }

    async onSelectOrganizationItem(params: OnSelectParams) {
        if (!this.props.onSelectOrganizationItem) {
            return;
        }
        const org: Optional<Organization> = this.getOrganizationFromTreeItemId(params.itemId);
        const organizationDropdownValue: OrganizationDropdownValue = { ...this.getCurrentOrganizationDropdownValue() };
        // Force the current organization and its children to be included in the other organizations if is not the All organizations
        if (!Utils.isNullOrEmpty(organizationDropdownValue.organization)) {
            if (this.props.filterLeftBarCheck) {
                const organization: Organization = this.getAllOrganizationsThatUserHasAccess().find(org => org.qualifiedName == organizationDropdownValue.organization)!;
                organizationDropdownValue.alsoIncludeOrganizations = organizationDropdownValue.alsoIncludeOrganizations.concat(this.getOrganizationAndChildrenQualifiedNames(organization));
            }
            organizationDropdownValue.alsoIncludeOrganizations = organizationDropdownValue.alsoIncludeOrganizations.concat(organizationDropdownValue.organization!);
        }
        // Set the new current organization
        organizationDropdownValue.organization = org?.qualifiedName;
        const newCurrentOrgIsAllOrganizations = Utils.isNullOrEmpty(organizationDropdownValue.organization);
        // Force the new selected current organization and its children to be included in the other organizations if is not the All organizations
        if (!Utils.isNullOrEmpty(organizationDropdownValue.organization)) {
            if (this.props.filterLeftBarCheck) {
                const organization: Organization = this.getAllOrganizationsThatUserHasAccess().find(org => org.qualifiedName == organizationDropdownValue.organization)!;
                organizationDropdownValue.alsoIncludeOrganizations = organizationDropdownValue.alsoIncludeOrganizations.concat(this.getOrganizationAndChildrenQualifiedNames(organization));
            }
            organizationDropdownValue.alsoIncludeOrganizations = organizationDropdownValue.alsoIncludeOrganizations.concat(organizationDropdownValue.organization!);
        }
        setFilterLeftBarOrganizations(newCurrentOrgIsAllOrganizations ? [] : (this.props.filterLeftBarCheck ? organizationDropdownValue.alsoIncludeOrganizations : []));
        // For left bar we need to remove the combined mode and keep it just the current org
        if (this.props.asLeftBar || newCurrentOrgIsAllOrganizations) {
            organizationDropdownValue.includeMyOtherOrganizations = false;
            organizationDropdownValue.alsoIncludeOrganizations = [];
        } else {
            organizationDropdownValue.alsoIncludeOrganizations = organizationDropdownValue.includeMyOtherOrganizations ? organizationDropdownValue.alsoIncludeOrganizations : [];
        }
        setFilterLeftBarCheck(newCurrentOrgIsAllOrganizations ? false : (this.props.filterLeftBarCheck || false));
        setCombineCheck(newCurrentOrgIsAllOrganizations ? false : organizationDropdownValue.includeMyOtherOrganizations);
        await this.props.r.updateOrganizationDropdownValue(organizationDropdownValue);
        await this.props.onSelectOrganizationItem(organizationDropdownValue);
    }

    protected renderMainElementFunction(params: { mainProps: any; mainChildren: ReactElement[]; linearizedItems: LinearizedItem[] }) {
        return React.createElement(SegmentGroup, { ...params.mainProps, className: ("no-margin " + (this.props.asLeftBar ? "" : "OrganizationTreeTopRightArea")) }, params.mainChildren);
    }

    render(): React.ReactNode {
        return <ScriptableContainer id="OrganizationTreeRRC">
            <Scriptable id="organizationTreeBaseRRC" onSelectItem={(params: OnSelectParams) => this.onSelectOrganizationItem(params)}>{scriptable =>
                <OrganizationTreeBaseRRC id="organizationTreeBaseRRC" ref={this.refOrganizationTree} root={this.props.root}
                    initialExpandedIds={this.props.s.expandedIds}
                    renderItemFunction={this.renderOrganizationTreeItem}
                    styleItemWrapperFunction={this.getOrganizationTreeItemStyleProps}
                    onSelectItem={(params) => scriptable.proxy.onSelectItem(params)}
                    renderMainElementFunction={this.renderMainElementFunction} />}
            </Scriptable>
        </ScriptableContainer>
    }
}

export const OrganizationTreeRRC = ReduxReusableComponents.connectRRC(OrganizationTreeState, OrganizationTreeReducers, OrganizationTree);

export class OrganizationSelectorState extends State {
    filterLeftBarCheck: boolean = false;
    combineCheck: boolean = false;
}
export class OrganizationSelectorReducers<S extends OrganizationSelectorState = OrganizationSelectorState> extends Reducers<S> { }
type OrganizationSelectorPropsNotFromState = OrganizationTreePropsNotFromState & { onApply?: () => void }
type LocalState = {
    inputValue: string,
}
export type OrganizationSelectorProps = OrganizationSelectorPropsNotFromState & RRCProps<OrganizationSelectorState, OrganizationSelectorReducers>;
export class OrganizationSelector extends React.Component<OrganizationSelectorProps, LocalState> {
    protected refOrganizationTree = React.createRef<OrganizationTree>();
    protected inputRef = React.createRef<Input>();
    static contextType = AppContainerContext;
    context!: AppContainerContextValue;

    constructor(props: OrganizationSelectorProps) {
        super(props);
        this.state = { inputValue: "" };
    }

    getCurrentOrganizationDropdownValue(): OrganizationDropdownValue {
        const currentOrganizationDropdownValue: OrganizationDropdownValue = this.refOrganizationTree.current!.getCurrentOrganizationDropdownValue();
        setFilterLeftBarCheck(this.props.s.filterLeftBarCheck);
        setFilterLeftBarOrganizations(this.props.s.filterLeftBarCheck ? currentOrganizationDropdownValue.alsoIncludeOrganizations : []);
        setCombineCheck(this.props.s.combineCheck);
        return { ...currentOrganizationDropdownValue, alsoIncludeOrganizations: currentOrganizationDropdownValue.includeMyOtherOrganizations ? currentOrganizationDropdownValue.alsoIncludeOrganizations : [] };
    }

    componentDidMount(): void {
        this.inputRef.current?.focus();
        this.props.r.setInReduxState({ combineCheck: getCombineCheck(), filterLeftBarCheck: getFilterLeftBarCheck() })
    }

    protected renderBottomFilterContainer(): React.ReactNode {
        return <Segment id="organizationDropdownBottomFilterContainer" className="min-height-auto less-padding less-margin-bottom w100 flex-container gap5" attached="bottom">
            <div className="flex-container-row gap5">
                <Label basic className="less-padding" style={{ maxWidth: "fit-content" }}
                    detail={<LabelDetail className="small-margin-left" content={<Popup trigger={<Icon name="info" size="small" circular inverted color="grey" fitted />}
                        content={<span style={{whiteSpace: 'pre-line'}}>{_msg("OrganizationDropdown.filterLeftBarExplanation")}</span>}
                    />} />}
                    content={<Checkbox fitted checked={this.props.s.filterLeftBarCheck} disabled={Utils.isNullOrEmpty(this.props.organizationDropdownValue?.organization)}
                        label={<label>
                            <strong>{_msg("general.filter")}</strong>
                            {this.context.initializationsForClient.organizationSettings?.globalOrganizationFilter != OrganizationFilter.NONE
                                ? <strong>{" " + _.lowerFirst(_msg("OrganizationDropdown.leftBar"))}</strong>
                                : null}
                        </label>}
                        onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => this.props.r.setInReduxState({ filterLeftBarCheck: data.checked })} />}
                />
                <Button compact size="small" disabled={!this.props.s.filterLeftBarCheck} content={_msg("general.check") + " " + _.lowerFirst(_msg("general.all"))} onClick={() => this.refOrganizationTree.current!.checkAll()} />
                <Button compact size="small" disabled={!this.props.s.filterLeftBarCheck} content={_msg("general.uncheck") + " " + _.lowerFirst(_msg("general.all"))} onClick={() => this.refOrganizationTree.current!.uncheckAll()} />
            </div>

            <Divider fitted/>
            <CombineCheck checked={this.props.s.combineCheck} filterLeftBarCheck={this.props.s.filterLeftBarCheck}
                onDisabled={() => this.props.r.setInReduxState({ combineCheck: false })}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => this.props.r.setInReduxState({ combineCheck: data.checked })} />
        </Segment>
    }

    protected renderHeader(): React.ReactNode {
        const currentOrganization: Optional<Organization> = this.context.initializationsForClient.currentOrganization;
        return <div className="flex-container justify-content-space-between w100 gap5">
            <Message className="flex-container less-padding gap5" attached="top">
                <MessageHeader>{currentOrganization ? _msg("OrganizationDropdown.currentOrganization") + ":" : null}</MessageHeader>
                <MessageHeader className="link item no-padding-top-bottom OrganizationDropdown_topBar">
                    {AppMetaTempGlobals.appMetaInstance.getOrganizationTreeIcon(this.context.initializationsForClient.currentOrganization?.id || NO_ORGANIZATION.key, this.context.initializationsForClient.currentOrganization || {}, this.context.initializationsForClient.currentOrganization, { size: "large" }, true)}
                    {currentOrganization ? currentOrganization.qualifiedName : NO_ORGANIZATION.text}
                </MessageHeader>
            </Message>
            <span>{_msg("OrganizationDropdown.chooseAnotherOrganization")}<i>{` (${_.lowerFirst(_msg("OrganizationDropdown.searchClickInTree"))}):`}</i></span>
            <div className="flex-container-row gap3">
                <Input ref={this.inputRef} className="w100" placeholder={_msg("general.search")} icon='search' iconPosition='left' onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                    this.setState({ inputValue: data.value }, () => this.refOrganizationTree.current?.modifySearchExpression(data.value));
                }} />
                <div className="margin-auto">
                    <ExpandCollapseButtons refOrganizationTree={this.refOrganizationTree} />
                </div>
            </div>
        </div>
    }

    render(): React.ReactNode {
        return <ScriptableContainer id="organizationSelector">
            <div className="flex-container OrganizationSelectorContainer gap5" data-testid="OrganizationSelectorContainer">
                {this.renderHeader()}
                <OrganizationTreeRRC id="OrganizationTreeRRC" ref={this.refOrganizationTree} root={this.props.root} filterLeftBarCheck={this.props.s.filterLeftBarCheck} combineCheck={this.props.s.combineCheck}
                    onSelectOrganizationItem={this.props.onSelectOrganizationItem} organizationDropdownValue={this.props.organizationDropdownValue} />
                {this.renderBottomFilterContainer()}
                <Scriptable id="apply" onClick={() => this.props.onApply?.()} >{scriptable => <Button className="min-height-auto" content={_msg("general.apply")} positive onClick={(e) => scriptable.proxy.onClick()} />}</Scriptable>
            </div>
        </ScriptableContainer>
    }
}

export const OrganizationSelectorRRC = ReduxReusableComponents.connectRRC(OrganizationSelectorState, OrganizationSelectorReducers, OrganizationSelector);

type CombineCheckProps = {
    checked?: boolean,
    filterLeftBarCheck?: boolean,
    fontSize?: string,
    onChange?: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void
    onDisabled?: () => void
}
export class CombineCheck extends React.Component<CombineCheckProps, { disabled: boolean }> {
    static contextType = AppContainerContext;
    context!: AppContainerContextValue;

    constructor(props: Readonly<CombineCheckProps>) {
        super(props)
        this.state = { disabled: false };
    }

    componentDidUpdate(prevProps: Readonly<CombineCheckProps>, prevState: Readonly<{ disabled: boolean; }>): void {
        const disabled = (Boolean(this.context.initializationsForClient.allOrganizationsAccess) && !this.props.filterLeftBarCheck) || Utils.isNullOrEmpty(this.context.initializationsForClient.currentOrganization);
        if (disabled != this.state.disabled) {
            this.setState({ disabled: disabled }, () => {
                if (disabled) {
                    this.props.onDisabled?.();
                }
            })
        }
    }

    render(): React.ReactNode {
        return <Label className="less-padding" style={{ maxWidth: "fit-content", backgroundColor: OTHER_CHECKED_ORGANIZATION_COLOR, fontSize: this.props.fontSize }}
            detail={<LabelDetail className="small-margin-left" content={
                <Popup trigger={<Icon name="info" size="small" circular inverted color="grey" fitted />}
                    content={<span style={{ whiteSpace: 'pre-line' }}>{_msg("OrganizationDropdown.combineExplanation")}</span>}
                />
            } />}
            content={<Checkbox fitted checked={this.props.checked} label={_msg("general.combine")}
                disabled={this.state.disabled} onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => this.props.onChange?.(event, data)} />}
        ></Label>
    }
}

type ExpandCollapseButtonsProps = {
    refOrganizationTree: React.RefObject<OrganizationTree>,
    fontSize?: string
    size?: SemanticSIZES
}
export class ExpandCollapseButtons extends React.Component<ExpandCollapseButtonsProps> {

    render(): React.ReactNode {
        return <Button.Group className="w100" size={this.props.size || "small"} style={{ fontSize: this.props.fontSize }}>
            <Button icon="plus" color="blue" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.props.refOrganizationTree?.current!.expandAll()} />
            <Button icon="minus" color="red" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.props.refOrganizationTree?.current!.collapseAll()} />
        </Button.Group>
    }
}
"../../AppContainerContext""../../AppMeta""../../AppMetaTempGlobals""../../CompMeta""../../components/TreeRRC/Tree""../../reduxReusableComponents/ReduxReusableComponents""../../utils/Utils"