import { zeroTrainingArticle } from "@crispico/foundation-react/zeroTraining/dataStructures";
//@ts-ignore
import StoryRouter from 'storybook-react-router';
import { DashboardMode } from "../DashboardContants";
import { DashboardRowHeight, DashboardTab, DashboardTabRRC, DashboardTabState } from "./DashboardTab";
import { configTestState0, testState0 } from "./testStates";
import { Dashboard } from "../DashboardEntityDescriptor";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";

export default zeroTrainingArticle("Dashboard.label", "Dashboard.label", {
    // decorators: [StoryRouter({}, { initialEntries: [dashboardEntityDescriptor.getEntityEditorUrl("undefined") + "/chart"] })]
});

const testState = {};
Object.keys(testState0).forEach(key => {
    Object.assign(testState, testState0, {["undefined." + key + ".0"]: (testState0 as any)[key] });
});

// This succesfully opens the dashboard storybook, but the widgets are not populated with the correct state;
// I expected `preloadedStateRRC = { "undefined.widgetId.0": myTestState }` to work, but it doesn't.
// dashboardId is undefined, because not entity was provided to DashboardTab
function Template(p: {additional: Partial<DashboardTabState>}) {
    return <ReduxReusableComponents.WrapWithEnhancedStore  preloadedStateRRC={{ dashboard: {
        zeroTrainingMode: true, layouts: configTestState0.layouts, widgetWrapperConfigs: configTestState0.widgetWrapperConfigs,
        currentBreakpoint: "lg", ...p.additional}, ...testState
    }}>
        <div className="flex-container flex-grow" style={{ width: "100%", height: "100%" }}><DashboardTabRRC id="dashboard"
            dashboardId={0} editable={true} /></div>
    </ReduxReusableComponents.WrapWithEnhancedStore>
}

// export const DemoWidgetsViewMode = () => <Template additional={{ mode: DashboardMode.VIEW }} />;
// export const DemoWidgetsEditMode2 = () => <Template additional={{ mode: DashboardMode.EDIT, wizardOpen: true }} />;





// export const DemoWidgetsEditMode = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, {mode: DashboardMode.EDIT},
//     { wrapInRouter: true, additionalProps, globalState: testState });

// export const DemoWidgetsEditMode3 = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, {mode: DashboardMode.EDIT, wizardOpen: true, wizard: {currentStepIndex: 1}, widgetActionOrType: COPY},
//     { frozenReducer: true, wrapInRouter: true, additionalProps, globalState: testState });

// export const DemoWidgetsEditMode4 = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, {mode: DashboardMode.EDIT, wizardOpen: true, wizard: {currentStepIndex: 1}, widgetActionOrType: MOVE},
//     { frozenReducer: true, wrapInRouter: true, additionalProps, globalState: testState });




// These are from zt, don't delete

// SnapDemoWidget.story = {
//     name: _msg("Dashboard.widget.demoWidget.description"),
//     parameters: joyrideDecoratorParams("Dashboard", [])
// }

// export const SnapDashboardCharts = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, testState6,
//     { frozenReducer: true, wrapInRouter: false, additionalProps: { zeroTrainingMode: true, config: config2 }, globalState: { pieCountByCriteria0: testState1, pieCountByCriteria1: testState3, pieCountByCriteria2: testState4, pieCountByCriteria3: testState5 } });

// SnapDashboardCharts.story = {
//     name: _msg("ZeroTraining.elements"),
//     parameters: joyrideDecoratorParams("Dashboard", ["page", "layout", "toolbar"])
// }

// export const SnapDashboardCharts_EditMode = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, { ...testState6, ...{ mode: DashboardMode.EDIT } },
//     { frozenReducer: false, wrapInRouter: false, additionalProps: { zeroTrainingMode: true, config: config2 }, globalState: { pieCountByCriteria0: testState1, pieCountByCriteria1: testState3, pieCountByCriteria2: testState4, pieCountByCriteria3: testState5 } });

// SnapDashboardCharts_EditMode.story = {
//     name: _msg("Dashboard.mode.edit"),
//     parameters: joyrideDecoratorParams("Dashboard", ["toolbar.edit", "widget", "widget.button"])
// }

// export const SnapDashboardCharts_EditWidget = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, { ...testState6, ...{ mode: DashboardMode.EDIT, editorOpen: 0 } },
//     { frozenReducer: true, wrapInRouter: false, additionalProps: { zeroTrainingMode: true, config: config2 }, globalState: { pieCountByCriteria0: testState1, pieCountByCriteria1: testState3, pieCountByCriteria2: testState4, pieCountByCriteria3: testState5 } });

// SnapDashboardCharts_EditWidget.story = {
//     name: _msg("Dashboard.widget.modification"),
//     parameters: joyrideDecoratorParams("Dashboard.widget.modification", ["description"])
// }

// export const SnapDashboardCharts_AddWidget = () => TestUtils.wrapWithStoreForSlice(sliceDashboardTab, DashboardTab, { ...testState6, ...{ mode: DashboardMode.EDIT, wizardOpen: true } },
//     { frozenReducer: true, wrapInRouter: false, additionalProps: { zeroTrainingMode: true, config: config2 }, globalState: { pieCountByCriteria0: testState1, pieCountByCriteria1: testState3, pieCountByCriteria2: testState4, pieCountByCriteria3: testState5 } });

// SnapDashboardCharts_AddWidget.story = {
//     name: _msg("Dashboard.widget.wizard"),
//     parameters: joyrideDecoratorParams("Dashboard.widget.modification", ["add", "description"])
// }"../../../zeroTraining/dataStructures""../../../reduxReusableComponents/ReduxReusableComponents"