
import { FieldRendererProps } from "../fieldRenderersEditors";
import { ModalExt } from "../../components/ModalExt/ModalExt";
import { Modal, Form, TextArea, Button } from "semantic-ui-react";
import StringFieldRenderer from "./StringFieldRenderer";

export class TextFieldRenderer<P extends FieldRendererProps = FieldRendererProps> extends StringFieldRenderer<P, { modalOpen: boolean }> {

    constructor(props: P) {
        super(props);
        this.state = { modalOpen: false };
    }

    getText() {
        return this.props.value ? String(this.props.value) : "";
    }

    protected getModalContext() {
        const text = this.props.value ? String(this.props.value) : "";

        return <TextArea rows={5}>{text}</TextArea>;
    }

    protected getLabelText() {
        return super.render();
    }

    protected getStopPropagationFunction() {
        return (event: MouseEvent) => { event.stopPropagation(); };
    }

    render() {
        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation(); }} >{this.getLabelText()}</span>
            {/** We need to send also `modalDimmerProps` because the renderer is used in table (for fields) and we need to stop right click
             * and double click actions from table rows
             */}
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'
                modalDimmerProps={{ onDoubleClick: this.getStopPropagationFunction(), onContextMenu: this.getStopPropagationFunction() }}>
                <Modal.Content>
                    <Form className="wh100">
                        {this.getModalContext()}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>{_msg("general.close")}</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

export default TextFieldRenderer;