import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CrudHeader } from "@crispico/foundation-react/entity_crud/CrudHeader";
import { CrudViewerInEditor } from "@crispico/foundation-react/entity_crud/CrudViewerInEditor";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, SliceFoundation, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { ENTITY, ENT_ADD, ENT_AUDIT, ENT_SAVE, FIELDS_READ, ENT_EXTERNAL_LINK, Utils, ENT_READ } from "@crispico/foundation-react/utils/Utils";
import { MutationOptions, QueryOptions } from "apollo-client";
import { push } from "connected-react-router";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import lodash from "lodash";
import React, { ReactNode } from "react";
import { Link, RedirectProps, RouteProps } from "react-router-dom";
import { BreadcrumbSectionProps, Button, Container, Icon, Label, MenuItemProps, Message, Segment, SemanticICONS, SemanticShorthandCollection } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { apolloClientHolder, ApolloContext, apolloGetExtensionFromError, apolloGlobalErrorHandler, CatchedGraphQLError, GraphQLErrorExtensions } from "../apolloClient";
import { sliceColumnConfigDropdown } from "../components/ColumnConfig/ColumnConfigDropdown";
import { ColumnConfigDropdownSource } from "../components/ColumnConfig/dataStructures";
import { Filter } from "../components/CustomQuery/Filter";
import { OverrideableElement, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { Dashboard } from "../pages/dashboard/DashboardEntityDescriptor";
import { AbstractCrudPage, AbstractCrudPageLocationState, AbstractCrudPageProps, SliceAbstractCrudPage, sliceAbstractCrudPageOnlyForExtension } from "./AbstractCrudPage";
import { CrudFormInEditor, CrudFormInEditorProps } from "./CrudFormInEditor";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { EDITOR_PAGE_ICON, entityDescriptors, ID } from "./entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { SaveFieldsParams } from "./SaveFieldsParams";
import { ShareLinkLogic } from "./ShareLinkLogic";
import { v4 as uuid } from 'uuid';
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import StringFieldRenderer from "./fieldRenderers/StringFieldRenderer";
import { AssociationFieldRenderer } from "./fieldRenderers/AssociationFieldRenderer";
import { ColumnDefinition } from "./EntityTableSimple";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { AuditTablePageRRC } from "../pages/Audit/auditEntityDescriptor";
import { OneToManyMode } from "./EntityTablePage";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { Location } from "history";

let AuditGraphRRC: any;
let HistoryCompare: any;
let sliceHistoryCompare: SliceFoundation;
let getAdditionalFieldsToRequest: (entity: Dashboard) => string[];
let DashboardTabRRC: any;

init();

async function init() {
    const auditGraphPage = await import("../pages/Audit/AuditGraph");
    AuditGraphRRC = auditGraphPage.AuditGraphRRC;
    const historyComparePage = await import("../pages/HistoryCompare/HistoryCompare");
    HistoryCompare = historyComparePage.HistoryCompare;
    sliceHistoryCompare = historyComparePage.sliceHistoryCompare;
    const ed = await import("../pages/dashboard/DashboardEntityDescriptor");
    getAdditionalFieldsToRequest = ed.getAdditionalFieldsToRequest;
    const dashboardTab = await import("../pages/dashboard/dashboardTab/DashboardTab");
    DashboardTabRRC = dashboardTab.DashboardTabRRC;
}

export const ADD = "add";

//*Editor/add?duplicateFromId=-1
export const DUPLICATE_FROM_ID_SEARCH_PARAM = "duplicateFromId";

//*Editor/-1/edit?focusField=firstName
export const FOCUS_FIELD_SEARCH_PARAM = "focusField";

export enum EditMode { ADD, EDIT_LOADING, EDIT, SAVING };

export type SaveParams = {
    performNavigationAtEnd?: boolean,
    initialFieldsAndValues?: any,
    componentProps?: EntityEditorPageProps
};

export type Message = {
    uid: string,
    text: string,
    icon?: SemanticICONS,
    color?: SemanticCOLORS,
    timeout?: number
};

export class SliceEntityEditorPage extends SliceAbstractCrudPage {
    saveOperationName!: string;
    saveMutation!: DocumentNode;
    loadAttachedDashboardsOperationName: string = `dashboardService_attachedDashboards`;
    loadAttachedDashboardsQuery!: DocumentNode;
    // these are populated in AppMeta, to avoid circularity issues
    static sliceColumnConfigDropdown: any;
    static ColumnConfigDropdown: any;

    onBeforeMergeByConnectedPageHelper() {
        const ns = (this.nestedSlices as any);
        ns.columnConfigDropdown = SliceEntityEditorPage.sliceColumnConfigDropdown;
        // ns.dashboardTab = SliceEntityEditorPage.sliceDashboardTab;
        if (!this.entityDescriptor) {
            // I didn't have time to see if this check is OK
            // It happens on prot, somehow related to the CustomQueryEditor, embedded in the 
            // CQ dropdown, embedded in the CQ bar, embedded in a TablePage
            return;
        }
    }

    initIfNeeded() {
        if (this.saveMutation) { return; }

        this.initQueries();
    }

    getSaveOperationName(): string {
        return `${lodash.lowerFirst(this.entityDescriptor.name)}Service_save`
    }

    getJavaIdType() {
        return this.entityDescriptor && this.entityDescriptor.javaIdType ? this.entityDescriptor.javaIdType : CrudGlobalSettings.INSTANCE.defaultJavaIdType;
    }

    getGraphQlIdType() {
        return this.entityDescriptor && this.entityDescriptor.graphQlIdType ? this.entityDescriptor.graphQlIdType : CrudGlobalSettings.INSTANCE.defaultGraphQlIdType;
    }

    initQueries() {
        this.saveOperationName = this.getSaveOperationName();
        this.saveMutation = gql(`mutation q($params: SaveParams_${this.getJavaIdType()}Input) { 
            ${this.saveOperationName}(params: $params) { ${ID} }
        }`);

        this.loadAttachedDashboardsQuery = gql(`query q($id: Long, $forEditor: Boolean, $entityName: String) { 
            ${this.loadAttachedDashboardsOperationName}(id: $id, forEditor: $forEditor, entityName: $entityName) {
                id name icon width configJson
            }
        }`);
    }

    /**
     * This method is meant to be overwritten by the editors that need to handle additional data received from the save operation.
     *
     * @author Daniela Buzatu
     */
    handleDataReturnedBySaveOperation(dataReturnedBySave: any): void {
    }

    /**
     * This method is meant to be overwritten by the editors that needs to enable save no matter the permissions of the logged in user.
     *
     * @author Daniela Buzatu
     */
    public isSaveAuthorized(editMode: any, showErrorMessageIfNoPermission?: boolean): boolean {
        const ed = this.entityDescriptor;
        const permission = Utils.pipeJoin([editMode === EditMode.ADD ? ENT_ADD : ENT_SAVE, ed.name]);
        return AppMetaTempGlobals.appMetaInstance.hasPermission(permission, showErrorMessageIfNoPermission);
    }

    /**
     * Temporary till the general validation error mechanism for an entity editor will be implemented.
     * Should be erased afterwards
     *
     * @author Daniela Buzatu
     */
    isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
        return false;
    }

    nestedSlices = {
        // Because of the cycle: EntityEditorPage has a ColumnConfigDropdown which has a ColumnConfigEntityEditorPage which extends EntityEditorPage, there is a discussion:
        // this may or may not be here; actually it exists always except for ColumnConfigEntityEditorPage
        // having this dinamically here got us through a lot of problems:
        // 1) we had circularity issues on TS level
        // 2) circularity issues at runtime / imports
        // 3) infinite loop because of the merging/expanding algorithm in ConnectedPageHelper

        // columnConfigDropdown: sliceColumnConfigDropdown
    }

    initialState = {
        ...sliceAbstractCrudPageOnlyForExtension.initialState,
        // it's undefined during a few renders, until onMatchChanged() is called
        entity: undefined as any,
        mode: EditMode.EDIT_LOADING,
        // TODO CS: not used (yet); maybe we'll need it in the future?
        // fieldChanges: {} as { [fieldName: string]: any }

        /**
         * For subclasses, this needs to be updated. If the editor (subclass) has data that is not always
         * in the redux state, then `isDirty()` needs to be also overridden. 
         * 
         * @see isDirty()
         */
        dirty: false,

        /**
         * Keeps the id of the entity that is duplicated.
         * Be aware: if id = 0, typescript condition `duplicateFromId && ...` will return false! KO
         * In theory, an id shouldn't be 0!
         */
        duplicateFromId: undefined as number | undefined,

        messages: [] as Message[]
    }

    reducers = {
        ...sliceAbstractCrudPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceEntityEditorPage>(this),

        /**
         * Clears from the state: the entity. If extra data exists => should be overridden to clear it
         * as well.
         */
        clearStateBeforeAddOrEdit(state: StateFrom<SliceEntityEditorPage>) {
            state.dirty = false;
            state.mode = EditMode.EDIT_LOADING;
            state.entity = undefined;
        },

        /**
         * Called on mod ADD.
         * 
         * Instantiates a new entity and sets it in the state. May be overridden if extra data
         * needs to be generated (for a freshly created entity, which is probably = {}). This seems to be a scenario
         * with low probability.
         */
        onModeAdd(state: StateFrom<SliceEntityEditorPage>, p: { params: string | undefined, currentOrganization: Optional<Organization> }) {
            state.mode = EditMode.ADD;
            state.entity = this.getSlice().entityDescriptor.createNewEntity();
            p.params && new URLSearchParams(p.params).forEach((value, key) => {
                try {
                    state.entity[key] = JSON.parse(value);
                } catch {
                    state.entity[key] = value;
                }
            });
            state.entity.organization = p.currentOrganization;
        },

        /**
         * Called on mode EDIT or ADD + duplicate (which is practically an EDIT). But for duplicate,
         * this method sets the mode to ADD.
         * 
         * Stores the entity in the state. Should be overridden if extra data exits. E.g. convert a JSON field in
         * a real object and store it in the state.
         */
        onModeLoaded(state: StateFrom<SliceEntityEditorPage>, entity: any) {
            state.mode = state.duplicateFromId !== undefined ? EditMode.ADD : EditMode.EDIT;
            state.entity = entity;
        }
    }

    impures = {
        ...sliceAbstractCrudPageOnlyForExtension.impures,
        ...getBaseImpures<SliceEntityEditorPage>(this),

        async invokeLoadQuery(options: QueryOptions) {
            return await apolloClientHolder.apolloClient.query(options);
        },

        getLoadQueryParams(columns?: ColumnDefinition[] | null) {
            const ed = this.getSlice().entityDescriptor;
            const loadOperationName = `${lodash.lowerFirst(ed.name)}Service_findById`;
            let state = undefined;
            try {
                state = this.getState();
            } catch {
                // method called from another screen, this.getState() throws error
            }
           
            const additionalFieldsToRequest = state?.attachedDashboards ? state.attachedDashboards.map(dashboard => getAdditionalFieldsToRequest(dashboard)) : [];
            let fields = Array.prototype.concat.apply(this.getSlice().getFieldsToRequest(), additionalFieldsToRequest);
            if (columns) {
                // We need to include for query fields that are not currently in fields of the current descriptor
                // or in `additionalFieldsToRequest`
                columns.map(column => {
                    if (!fields.includes(column.name)) {
                        fields.push(column.name);
                    }
                });
            }
            const fieldsToRequestStr = ed.getGraphQlFieldsToRequest(fields) + " " + this.getSlice().getAdditionalGraphQl();

            return {
                loadOperationName,
                loadQuery: gql(`query q($id: ${this.getSlice().getGraphQlIdType()}!) { 
                    ${loadOperationName}(id: $id) {
                        ${fieldsToRequestStr}
                    }
                }`)
            };
        },

        async load(id: typeof ADD | any, columns: Optional<ColumnDefinition[]>, currentOrganization: Optional<Organization>, location?: Location<any>) {
            this.getDispatchers().clearStateBeforeAddOrEdit();

            if (id === ADD && this.getState().duplicateFromId === undefined) {
                this.getDispatchers().onModeAdd({ params: location?.search, currentOrganization: currentOrganization });
                return;
            }

            if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, "Dashboard"])) && AppMetaTempGlobals.appMetaInstance.dashboardsAvailable && this.getSlice().entityDescriptor.hasAttachedDashboards) {
                const attachedDashboards: Dashboard[] = (await this.invokeLoadQuery({ query: this.getSlice().loadAttachedDashboardsQuery, variables: { id, forEditor: true, entityName: this.getSlice().entityDescriptor.name } })).data[this.getSlice().loadAttachedDashboardsOperationName] || [];
                this.getDispatchers().setInReduxState({ attachedDashboards: attachedDashboards });
            }

            const loadQueryParams = this.getLoadQueryParams(columns);
            const entity = (await this.invokeLoadQuery({ query: loadQueryParams.loadQuery, variables: { id, pageSize: 20 } })).data[loadQueryParams.loadOperationName];

            this.getDispatchers().onModeLoaded(entity);
            return entity;
        },

        async invokeSaveMutation(options: MutationOptions<any, { params: SaveFieldsParams }>) {
            return await apolloClientHolder.apolloClient.mutate(options);
        },

        /**
         * If there is extra data that needs to be embedded in the entity before save => this needs to be overridden.
         * Modify the entity (via copy, because the object comes from props, so is unmodifiable) and then call super. 
         * This entity will then be set in the state.
         */
        // TODO by CS: de unde e apelat cu acel navigate (de care deocamdata nu se mai tine cont); dar cu customFields?
        // I added params at the end; if needed move these 2 filds in it
        async save(entity: any, navigateToTable = true, customFieldsToUpdate?: string[], params?: SaveParams) {
            const initialMode = this.getState().mode;
            this.getDispatchers().setInReduxState({ mode: EditMode.SAVING })

            const ed = this.getSlice().entityDescriptor;

            if (!this.getSlice().isSaveAuthorized(initialMode, true)) {
                // TODO by CS: 1) mai bine faceam asta la inceputul metodei; 2) nu ar fi oricum ascuns butonul? Ala e nivelul de protectie client. Apoi va fi si cel de server;
                // cred ca e inutil verificarea de aici
                return;
            }

            const fields = customFieldsToUpdate ? customFieldsToUpdate : Object.keys(ed.fields).filter(f => ed.fields[f].enabled && !ed.fields[f].clientOnly);

            let fieldsAndValues: { [key: string]: any } = params?.initialFieldsAndValues ? params.initialFieldsAndValues : {};

            fields.forEach(f => {
                if (f !== ID) {
                    fieldsAndValues[f] = ed.fields[f] ? ed.fields[f].getFieldValue(entity) : entity[f];
                    // If fieldsAndValues[f] is an array, we will have values for an one-to-many/many-to-many field, having a list with partial entities (id + miniFields).
                    // But for save we need to use only id. So, we need to convert the list of partial objects to a list of ids.
                    if (Array.isArray(fieldsAndValues[f])) {
                        fieldsAndValues[f] = fieldsAndValues[f].map((fieldsAndValue: any) => fieldsAndValue[ID]);
                    }
                }
            });

            const duplicate = this.getState().duplicateFromId !== undefined;
            const saveFieldsParams: SaveFieldsParams = {
                id: duplicate ? null : entity.id,
                duplicateFromId: duplicate ? entity.id : null,
                fieldsAndValues
            };

            const { data } = (await this.invokeSaveMutation({
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({ mode: initialMode });

                        if ("ValidationException" === apolloGetExtensionFromError(e, GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME)) {
                            // TODO by CS: aici vine codul care baga in state erorile
                            // TODO DB: Temporary till the mechanism for showing validation errors for entity editor will be implemented
                            return this.getSlice().isDefaultErrorHandlerShownInCaseOfValidationException();
                        } else {
                            apolloGlobalErrorHandler(e);
                            return true;
                        }
                    }
                },
                mutation: (this.getSlice() as SliceEntityEditorPage).saveMutation,
                variables: { params: saveFieldsParams },
            }));

            // If the corresponding table page is opened in the background, refresh the table after the entity is saved.            
            // We did this hack here (entityDescriptors[ed.name] despite of using ed directly) because sometimes
            // entityDescriptors[ed.name].entityTablePage != ed.entityTablePage. This may appear because we take ed from slice in editor and
            // there the ed is set from EntityDescriptor.getInfoEditor and is not updated after that moment. And, because ed is not in props
            // in editor, any changes to ED are not visible.
            // TODO: Let's try to use ed.entityTablePage after we migrate editor page to RRC. I think the issue should be solved because we
            // would have ed in props
            entityDescriptors[ed.name].entityTablePage.current?.refresh();

            const returnedId = data[(this.getSlice() as SliceEntityEditorPage).saveOperationName].id;

            this.getSlice().handleDataReturnedBySaveOperation(data);

            // we need to update the entity in the state, because maybe the subclass has modified it, w/ extra data
            this.getDispatchers().setInReduxState({ entity, mode: EditMode.EDIT, dirty: false });

            if (params?.componentProps?.modalProps || params?.performNavigationAtEnd === false) {
                return;
            }

            // when ADD / creating a new entity => redirects to newly created entity page,
            // when ADD / duplicating an existing entity => redirects to newly duplicated entity
            if (initialMode === EditMode.ADD) {
                this.getDispatchers().dispatch(push(ed.getEntityEditorUrl(returnedId)));
                // this.getDispatchers().setInReduxState({ mode: EditMode.EDIT, duplication: false });
            }
            return data;
        },

        async duplicate() {
            const ed = this.getSlice().entityDescriptor;
            this.getDispatchers().dispatch(push({
                pathname: ed.getEntityEditorUrl(ADD), search: DUPLICATE_FROM_ID_SEARCH_PARAM + "=" + this.getState().entity.id
            }));
        },
    }

}

/**
 * As it's name suggests, this INSTANCE is provided for convenience for extension. In normal operation,
 * a new INSTANCE is created per entity.
 */
export const sliceEntityEditorPageOnlyForExtension = createSliceFoundation(class extends SliceEntityEditorPage { get entityDescriptor(): EntityDescriptor { throw new Error("This instance is only an utility for extension; it cannot be used.") } }, true);

export type EntityEditorPageProps = PropsFrom<SliceEntityEditorPage> & AbstractCrudPageProps & {
    columnConfigDropdown?: PropsFrom<typeof sliceColumnConfigDropdown>,
    // it seems that the 2 dispatchers are not merged; hence I comment and use "any" where needed
    // dispatchers: { columnConfigDropdown?: DispatchersFrom<typeof sliceColumnConfigDropdown> }

    pageHeaderClassName?: string,
    renderHeaderParams?: CrudEditorPageRenderHeaderParams

    onApply?: (entity: any) => void
    onSave?: (entity: any) => void

    scrollOnlyContentInEditor?: boolean
};

export type CrudEditorPageRenderHeaderParams = {
    columnConfig?: boolean
    hideDuplicate?: boolean
}

export const entityEditorPageTestids = createTestids("EntityEditorPage", {
    apply: "", cancel: "", revert: "", save: ""
});

export class EntityEditorPage<P extends EntityEditorPageProps = EntityEditorPageProps> extends AbstractCrudPage<P> {

    editorFormSimpleClass = CrudFormInEditor;

    protected refFormSimple = React.createRef<CrudFormInEditor>();
    protected historyCompareConnectedPageInfo: ConnectedPageInfo | undefined = undefined;

    // need to keep track of the timers used in this page in order to clear them at unmount
    private timers: { [key: string | number]: any } = {};

    constructor(props: P) {
        super(props);

        this.onApply = this.onApply.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDuplicate = this.onDuplicate.bind(this);
        this.onRevert = this.onRevert.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onGotoTable = this.onGotoTable.bind(this);

        if (props.dispatchers.getSlice().entityDescriptor.canAddAuditTabs()) {
            this.historyCompareConnectedPageInfo = new ConnectedPageInfo(sliceHistoryCompare, HistoryCompare, "historyCompare_editor_" + props.dispatchers.getSlice().entityDescriptor.name);
        }
    }

    componentDidMount(): void {
        AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_ADD, this.props.dispatchers.getSlice().entityDescriptor.name]), true);
    }

    componentDidUpdate(prevProps: EntityEditorPageProps): void {
        const fields = this.props.dispatchers.getSlice().getFieldsToRequest();
        prevProps.dispatchers.getSlice().entityDescriptor.name !== this.props.dispatchers.getSlice().entityDescriptor.name && AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_ADD, this.props.dispatchers.getSlice().entityDescriptor.name]), true);
        if (this.props.entity && this.props.columnConfigDropdown?.columnConfig?.configObject.columns
            && prevProps.columnConfigDropdown?.columnConfig?.configObject.columns
            && lodash.differenceBy(this.props.columnConfigDropdown.columnConfig.configObject.columns.filter(column => !fields.includes(column.name)),
                prevProps.columnConfigDropdown.columnConfig.configObject.columns.filter(column => !fields.includes(column.name)), "name").length) {
            // We will reload the entity if the composed fields from column config were changed because
            // their values may not be loaded in entity. 
            this.load(this.props.entity.id);
        }
    }

    componentWillUnmount() {
        Object.keys(this.timers).forEach(t => clearTimeout(this.timers[t]));
    }

    protected async load(id: typeof ADD | any) {
        if (this.props.columnConfigDropdown && !this.props.columnConfigDropdown.columnConfig) {
            // When we have a default column config for an entity (set via settings), different than the default one,
            // entity isn't loaded with the fields from that CC, when `load` method is called from `onMatchChanged`,
            // because that method is called before CC initialization. In this case, we need to force the initialization,
            // to take the column config here
            await (this.props.dispatchers as any).columnConfigDropdown.initializeCC(this.props.dispatchers.getSlice().entityDescriptor, ColumnConfigDropdownSource.EDITOR);
        }
        return await this.props.dispatchers.load(id,
            this.props.columnConfigDropdown!.columnConfig?.configObject.columns,
            this.context.initializationsForClient.currentOrganization,
            this.props.location
        );
    }

    protected onMatchChanged(match: any) {
        const duplicateFromIdSearchParam = new URLSearchParams(this.props.location?.search).get(DUPLICATE_FROM_ID_SEARCH_PARAM)
        const duplicateFromId = duplicateFromIdSearchParam != null ? parseInt(duplicateFromIdSearchParam) : undefined;
        this.props.dispatchers.setInReduxState({ duplicateFromId });
        this.load(duplicateFromId !== undefined ? duplicateFromId : match.id);
    }

    protected getRedirectToFirstPaneRouteProps(redirectProps: RedirectProps): RouteProps {
        const props = super.getRedirectToFirstPaneRouteProps(redirectProps);
        props.exact = this.props.mode !== EditMode.ADD;
        return props;
    }

    protected getTitle(): string | { icon: JSX.Element | string; title: JSX.Element | string; } {
        const props = this.props;
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return { icon: entityDescriptor.icon, title: (props.entity ? entityDescriptor.toMiniString(props.entity) : entityDescriptor.name) + " [" + _msg("entityCrud.editor.editor") + "]" };
    }

    /**
     * The form holds a temporary state (different from the Redux state). This is to avoid
     * updating the big state at each key stroke. The form transfer the data + dirty status
     * during `commitMain()` (i.e. on unmount/tab change or at the end, before save).
     * 
     * Hence, if we are asked about `isDirty()` before `commitMain()`, we need to extract
     * the info from the form itself, in addition to the `dirty` flag. Which is still needed
     * e.g. for the scenario: tab 1, modify, tab 2, tab 1. Now the form of tab 1 wouldn't be dirty.
     * 
     * If subclasses have similar components (such as forms), then this needs to be overridden.
     */
    protected isDirty() {
        if (this.props.mode === EditMode.ADD || this.props.mode === EditMode.EDIT) {
            return this.props.dirty || this.refFormSimple.current?.formikContext.dirty;
        }
        return false;
    }

    protected async onApply() {
        const { props } = this;
        await Utils.setTimeoutAsync();
        this.triggerCommitForAll();
        if (props.onApply) {
            // within setTimeout to wait for the state to propagate; also note that I use on purpose this.props
            await Utils.setTimeoutAsync();
            props.onApply!(this.props.entity);
        }
        if (this.modalProps) {
            props.dispatchers.setModalOpen(false);
        }
    }

    protected async onSave() {
        await this.onApply();
        await this.onSaveInternal();
        if (this.props.onSave) {
            // within setTimeout to wait for the state to propagate; also note that I use on purpose this.props
            await Utils.setTimeoutAsync();
            this.props.onSave!(this.props.entity);
        }
        this.onAfterSave();
    }

    protected async onSaveInternal() {
        await this.props.dispatchers.save(this.props.entity, undefined, undefined, { componentProps: this.props });
    }

    protected onAfterSave() {
        const message: Message = { text: _msg("entityCrud.editor.succesfullyUpdated"), icon: "check", color: "green", uid: uuid() };
        this.props.dispatchers.setInReduxState({ messages: this.props.messages.concat(message) });
        this.timers[message.uid] = setTimeout(() => this.props.dispatchers.setInReduxState({
            messages: this.props.messages.filter(m => m.uid !== message.uid)
        }), message.timeout ? message.timeout : 2000);
    }

    /**
     * Only for modal mode.
     */
    protected onCancel() {
        this.props.dispatchers.setModalOpen(false);
    }

    protected async onDuplicate() {
        await this.onApply();
        this.props.dispatchers.duplicate();
    }

    protected async onAddDashboard() {
        // TODO LA: 4) this one wasn't implemented? + I wasn't able to modify an editor dashboard, the Edit button doesn't seem to work
    }

    protected async onRevert() {
        await this.load(this.props.entity.id);
    }

    onBack() {
        AppMetaTempGlobals.history.goBack();
    }

    onGotoTable() {
        this.props.dispatchers.dispatch(push(this.props.dispatchers.getSlice().entityDescriptor.getEntityTableUrl()));
    }

    protected preRenderButtons(params: CrudEditorPageRenderHeaderParams): Array<OverrideableElement> {
        const props = this.props;
        const ed = this.props.dispatchers.getSlice().entityDescriptor;
        return [
            props.onApply && { elementType: Button, props: { "data-testid": entityEditorPageTestids.apply, key: "apply", content: _msg("general.apply"), color: "green", onClick: this.onApply } },
            this.isSaveEnabled() && { elementType: Button, props: { "data-testid": entityEditorPageTestids.save, key: "save", content: _msg("general.save"), primary: true, onClick: this.onSave } },
            // TODO by CS: disabled for embedded mode, because not yet had a case, to properly test all cases, redirects, etc
            !params.hideDuplicate && !this.embeddedMode && (props.mode === EditMode.EDIT || props.mode === EditMode.EDIT_LOADING) && ed.hasDuplicateButton && { elementType: Button, props: { key: "duplicate", content: _msg('dto_crud.duplicate'), icon: "clone", disabled: this.props.mode !== EditMode.EDIT, onClick: this.onDuplicate } },
            // LA: option to add attached dashboards from editor page is not implemented at the moment, so I disabled the button
            // props.mode === EditMode.EDIT && ed.hasAttachedDashboards && { elementType: Button, props: { key: "addDashboard", content: 'Add dashboard', icon: "dashboard", disabled: this.props.mode !== EditMode.EDIT, onClick: this.onAddDashboard } },
            (props.mode === EditMode.EDIT || props.mode === EditMode.EDIT_LOADING) && { elementType: Button, props: { "data-testid": entityEditorPageTestids.revert, key: "revert", content: _msg("general.revert"), onClick: this.onRevert, disabled: this.props.mode !== EditMode.EDIT } },
            this.modalProps && { elementType: Button, props: { "data-testid": entityEditorPageTestids.cancel, key: "cancel", content: _msg("general.cancel"), onClick: this.onCancel } }
        ];
    }

    protected renderPageHeader() {
        const props = this.props;
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const fieldsInHeaderInfo = entityDescriptor.getFieldsFromSettings(entityDescriptor.entityDescriptorSettings?.fieldsInHeader, props.entity);
        let hasFieldsInHeader = fieldsInHeaderInfo.fields.length > 0;
        let fieldsInHeader: string[] = fieldsInHeaderInfo.fields;
        if (!hasFieldsInHeader) {
            entityDescriptor.entityDescriptorSettings?.fieldDescriptorSettings.map(fds => {
                if (fds.inHeaderOrderIndex != undefined && fds.inHeaderOrderIndex != null) {
                    fieldsInHeader[fds.inHeaderOrderIndex] = fds.fieldRef;
                    hasFieldsInHeader = true;
                }
            });
        }
        if (!hasFieldsInHeader && fieldsInHeaderInfo.defaultFields.length > 0) {
            fieldsInHeader = fieldsInHeaderInfo.defaultFields
            hasFieldsInHeader = true;
        }

        let lineWithFields = !hasFieldsInHeader ? <></> : <>
            {fieldsInHeader.map(f => {
                const fd: FieldDescriptor = entityDescriptor.getField(f);
                // CS: I don't think is 100% correct. We don't know if the field has a renderer compatible w/ StringFieldRenderer. But even if not, it doesn't harm
                return <div key={fd.getFieldName()}>{fd.isCustomField || AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, entityDescriptor.name, FIELDS_READ, f])) ? fd.renderField(props.entity, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true, showIcon: true, showTooltip: true, showMeasurementUnit: true })) : null}</div>;
            })}
        </>;
        let editorHeaderImagePath = this.context.initializationsForClient.crudSettings?.forEntities.find(fe => fe.entityName === entityDescriptor.name)?.editorHeaderImage;
        return <CrudHeader
            content={{
                backgroundImage: editorHeaderImagePath ? Utils.adjustUrlToServerContext(editorHeaderImagePath) : undefined,
                component: this.renderPageHeaderContent(lineWithFields, undefined),
                className: (props.pageHeaderClassName || "") + (hasFieldsInHeader ? " CrudHeader_editorWithFieldsInHeader" : "CrudHeader_editorHeight")
            }}
            miniContent={{ component: this.renderPageHeaderMainContent() }}
            minMaxButtonClassName={!entityDescriptor.infoEditor.routeProps?.routeIsModal ? "CrudHeader_minimizeButton_smaller_right" : undefined}
        />;
    }

    protected getBreadcrumbSections(): SemanticShorthandCollection<BreadcrumbSectionProps> {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return [
            { key: 'Home', content: <><Icon name="home" /><Link to="/">{_msg("HomePage.title")}</Link></> },
            { key: "table", content: <>{entityDescriptor.getIcon()}<Link to={entityDescriptor.getEntityTableUrl()}>{entityDescriptor.getLabel(true)}</Link></> },
            { key: "editor", content: _msg("entityCrud.editor.subheader2") },
        ];
    }

    protected getHeaderIcon() {
        return <Icon size="big" name={EDITOR_PAGE_ICON} className="EntityCrudHeader_white" />;
    }

    protected renderPageHeaderMainContent(): ReactNode {
        const { props } = this;
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        var rawExternalLink = this.context.initializationsForClient.crudSettings?.forEntities.find(fe => fe.entityName === entityDescriptor.name)?.externalLink;
        return (
            <div className="flex-container-row flex-center gap5">
                {this.getHeaderIcon()}
                <h2 className="EntityCrudHeader_white no-margin">{props.mode === EditMode.EDIT && props.entity ? entityDescriptor.toMiniString(props.entity) : entityDescriptor.getLabel()}</h2>
                {props.entity?.id && props.entity?.organization
                    ? entityDescriptor.getField("organization").renderField(props.entity, FieldDescriptor.castAdditionalFieldRendererProps(AssociationFieldRenderer, {
                        url: new ShareLinkLogic().createLink(false, entityDescriptor, Filter.enableAllFilters(Filter.create("organization.id", FilterOperators.forNumber.equals, props.entity.organization.id))),
                        asLabel: true, showIcon: true, showTooltip: true
                    }))
                    : null}
                {AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_EXTERNAL_LINK, entityDescriptor.name])) && !Utils.isNullOrEmpty(rawExternalLink)
                    ? <Label>
                        <Icon name="external alternate" link className="no-margin" onClick={() => { this.composeExternalLinkAndOpen(entityDescriptor, rawExternalLink!) }} />
                    </Label>
                    : null}
            </div>);
    }

    protected renderPageHeaderContent(lineWithFields: ReactNode, additionalContent?: ReactNode): ReactNode {
        return <Segment className="EntityEditorPage_header_content">
            {this.renderPageHeaderMainContent()}
            {additionalContent}
            <div className="flex-container-row flex-center gap5" style={{ marginTop: '5px' }}>
                {this.props.entity?.id ? lineWithFields : null}
            </div>
        </Segment >;
    }

    composeExternalLinkAndOpen(entityDescriptor: EntityDescriptor, rawExternalLink: string) {
        // match words between "${" and "}", will come like this "${testField}"
        const entityFieldsParams = rawExternalLink!.match(new RegExp('\[${](.*?)\}', "g"))
        if (Utils.isNullOrEmpty(entityFieldsParams)) {
            return;
        }

        entityFieldsParams!.forEach(param => {
            // param = ${testField} || ${anotherEntity.testField}
            // field = testField || anotherEntity.testField
            const field = param.replace("$", "").replace("{", "").replace("}", "");
            let fieldSplit = field.split(".");
            // "testField": fieldDescriptor for "testField"
            // "entity.testField": the first fieldDescriptor for "anotherEntity"
            let fieldDescriptor = entityDescriptor.getField(field);
            // get value for "testField" from entity 
            // get the anotherEntity {id: -1, testField: "value"}
            let valueOrEntity = fieldDescriptor.getFieldValue(this.props.entity);
            if (fieldSplit.length == 1) {
                rawExternalLink = rawExternalLink!.replace(param, String(valueOrEntity));
            } else {
                // entityDescriptors["AnotherEntity"].getField("testField").getFieldValue({id: -1, testField: "value"})
                rawExternalLink = rawExternalLink!.replace(param,
                    String(entityDescriptors[fieldDescriptor.getType()].
                        getField(fieldSplit[fieldSplit.length - 1]).getFieldValue(valueOrEntity)));
            }
        })

        window.open(rawExternalLink)
    }

    renderMessage(message: Message) {
        return <Message className="less-margin-top-bottom" compact color={message.color}>{message.icon ? <Icon name={message.icon} /> : null} {message.text}</Message>;
    }

    renderHeader(params: CrudEditorPageRenderHeaderParams): ReactNode {
        const props = this.props;
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        // duplicated in CrudViewerInEditor :(
        // in theory, an editor may be non-modal. E.g. dashboard; in these case these buttons should appear?
        // const showBack = AppMetaTempGlobals.locationPathnamePrevious && AppMetaTempGlobals.locationPathnamePrevious !== this.props.dispatchers.getSlice().entityDescriptor.getEntityTableUrl();
        return (<>
            <div className="flex-container">{props.messages.map(message => this.renderMessage(message))}</div>
            <Segment className="buttonBar EntityEditorFormSimple_bar">
                {/* {!this.modalProps && <>
                    {showBack && <Button icon="arrow left" content={_msg("dto_crud.back")} onClick={this.onBack} />}
                    <Button icon={!showBack && "arrow left"} content={showBack ? _msg("dto_crud.goToTable") : _msg("dto_crud.backToTable")} onClick={this.onGotoTable} />
                </>}
                <div className="EntityTablePage_barDivider" /> */}
                {params.columnConfig && props.columnConfigDropdown &&
                    <>
                        <SliceEntityEditorPage.ColumnConfigDropdown {...props.columnConfigDropdown} dispatchers={(props.dispatchers as any).columnConfigDropdown} source={ColumnConfigDropdownSource.EDITOR} entityDescriptor={entityDescriptor} />
                        <div className="EntityTablePage_barDivider" />
                    </>
                }
                {this.renderButtons(params)}
            </Segment>
            {this.props.duplicateFromId !== undefined ? <Message>
                <Message.Header>{_msg('dto_crud.duplicate.message.header')}</Message.Header>
                <p>{_msg('dto_crud.duplicate.message.content', props.entity ? entityDescriptor.toMiniString(props.entity) : entityDescriptor.getLabel())}</p>
            </Message> : null}
        </>);
    }

    protected getMainRoutePath(): string {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return entityDescriptor.getEntityEditorUrl(this.props.match?.params.id);  // the ? for storybook
    }

    protected getMainPaneSubPath() {
        return "edit";
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return { icon: "list alternate outline", content: _msg("entityCrud.editor.form"), "data-testid": "EntityEditorPage_edit" };
    }

    protected getPropsForFormSimple(): CrudFormInEditorProps {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        let columnsVisibleMap: { [field: string]: boolean } | undefined = undefined;
        if (this.props.columnConfigDropdown?.columnConfig) {
            columnsVisibleMap = {}
            // embedded in EntityEditorPage, so let's look at the column configs
            const columns = this.props.columnConfigDropdown.columnConfig.configObject.columns!;
            // convert the list into a map to use it easily below
            columnsVisibleMap = columns.reduce((map, current) => { map[current.name] = true; return map; }, {} as typeof columnsVisibleMap);
        }

        let autoFocusOnField: string | undefined = undefined;

        // Extract from url the field that should be focused
        if (this.props.location && this.props.location.search.length > 0) {
            const urlSearchParams = new URLSearchParams(this.props.location!.search);
            autoFocusOnField = urlSearchParams.get(FOCUS_FIELD_SEARCH_PARAM) || undefined;
        }

        return {
            ref: this.refFormSimple, editor: this, hideButtonBar: true, autoFocusOnField,
            entityDescriptor, entity: this.props.entity, columnsVisibleMap, columns: this.props.columnConfigDropdown?.columnConfig?.configObject.columns!
        }
    }

    /**
     * This is invoked when entity exists. For load => after load. For add => immediately.
     */
    protected renderForm() {
        return React.createElement(this.editorFormSimpleClass, this.getPropsForFormSimple());
    }

    protected isSaveEnabled(): boolean {
        return (this.props.dispatchers.getSlice() as SliceEntityEditorPage).isSaveAuthorized(this.props.mode);
    }

    protected getContainerCssClasses() {
        let noScroll = this.props.scrollOnlyContentInEditor;
        if (noScroll === undefined) {
            noScroll = AppMetaTempGlobals.appMetaInstance.scrollOnlyContentInEditor;
        }
        const outer = "EntityEditorPage_container " + (noScroll ? "flex-grow" : " ");
        const inner = "EntityEditorPage_segment " + (noScroll ? "EntityEditorPage_containerOverflow" : "");
        return { outer: outer, inner: inner }
    }

    protected renderMain() {
        if (this.props.mode === EditMode.ADD && !AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_ADD, this.props.dispatchers.getSlice().entityDescriptor.name]))) {
            return <></>;
        }
        const cls = this.getContainerCssClasses();
        return <Container className={cls.outer} fluid>
            <Segment loading={this.props.mode === EditMode.SAVING} className={cls.inner}>
                {this.renderHeader(this.props.renderHeaderParams ? this.props.renderHeaderParams! : { columnConfig: true })}
                {this.props.entity ? this.renderForm() : <p>{_msg("general.loading")}</p>}
            </Segment>
        </Container>;
    };

    protected commitMain() {
        if (!this.refFormSimple.current) { return; }
        if (this.refFormSimple.current.formikContext.dirty) { this.props.dispatchers.setInReduxState({ dirty: true }) }
        this.props.dispatchers.setInReduxState({ entity: this.getEntityValuesFromForm() });
    }

    protected getEntityValuesFromForm() {
        return this.refFormSimple.current!.formikContext.values;
    }

    protected renderViewer() {
        const cls = this.getContainerCssClasses();
        return <Container className={cls.outer} fluid>
            <Segment className={cls.inner}>
                <CrudViewerInEditor editor={this} />
            </Segment>
        </Container>;
    }

    protected getAuditTabPanes() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const result = [];
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_AUDIT, entityDescriptor.name]), false)) {
            if (this.props.dispatchers.getSlice().entityDescriptor.canAddAuditTabs()) {
                const auditEntityDescriptor = entityDescriptors["Audit"];
                const menuItemProps: MenuItemProps = { icon: auditEntityDescriptor.icon, content: auditEntityDescriptor.getLabel(true) };
                const tabRouterPane: TabRouterPane = { menuItemProps: menuItemProps };
                // CS: not very OK to have the route from messages. Maybe we'll change this in the future, and the user has links w/ the old route
                tabRouterPane.routeProps = { path: "/" + auditEntityDescriptor.getLabel() };
                tabRouterPane.render = () => <AuditTablePageRRC ref={auditEntityDescriptor.entityTablePage} id={"auditTablePage-editorTab"} embeddedMode entityDescriptor={auditEntityDescriptor} itemsHidedFromCell={["add"]}
                    oneToManyMode={{ field: "entityId", entity: this.props.entity, entityDescriptor, entityField: undefined }} currentLocation={AppMetaTempGlobals.history.location}
                />;
                result.push(tabRouterPane);
            }
            if (this.props.dispatchers.getSlice().entityDescriptor.canAddAuditTabs()) {
                result.push({
                    routeProps: { path: "/auditGraph" },
                    menuItemProps: { icon: "chart line", content: _msg("AuditGraph.title") },
                    render: () => this.props.entity?.id ? <AuditGraphRRC id="auditGraph_EntityEditorPage" entities={[this.props.entity]} entityDescriptor={this.props.dispatchers.getSlice().entityDescriptor} /> : undefined
                })
            }
            if (this.historyCompareConnectedPageInfo) {
                result.push({
                    routeProps: { path: "/historyCompare" },
                    menuItemProps: { icon: "chart line", content: _msg("HistoryCompare.title") },
                    render: () => this.props.entity?.id ? <ConnectedComponentInSimpleComponent info={this.historyCompareConnectedPageInfo!} editor={this}
                        entityName={this.props.dispatchers.getSlice().entityDescriptor.name} filter={Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [Filter.createForClient("id", FilterOperators.forNumber.equals, this.props.entity.id)])}
                        sorts={[]} /> : undefined
                })
            }
        }
        return result;
    }

    protected createOneToManyTabPane(oneToManyEntityName: string, field: string, oneToManyModeExtraProps?: Partial<OneToManyMode>) {
        let entityDescriptor = entityDescriptors[oneToManyEntityName];
        let defaultTableForEntity = entityDescriptor.renderTable();

        return {
            routeProps: { path: "/" + entityDescriptor.getLabel(true) },
            menuItemProps: { content: entityDescriptor.getLabel(true), icon: entityDescriptor.icon },
            render: () => <defaultTableForEntity.type {...defaultTableForEntity.props} id={oneToManyEntityName + "Tab-entityTablePage"}
                ref={entityDescriptor.entityTablePage} embeddedMode oneToManyMode={{
                    field,
                    entity: this.props.entity,
                    entityDescriptor: this.props.dispatchers.getSlice().entityDescriptor,
                    ...oneToManyModeExtraProps,
                }} />
        };
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        let result: (TabRouterPane | null)[] = [...super.getExtraTabPanes()];

        if (AppMetaTempGlobals.appMetaInstance.showViewPropertiesTab) {
            result.push({
                routeProps: { path: `/viewer` },
                menuItemProps: { icon: "list alternate", content: _msg("entityCrud.editor.viewer") },
                render: () => this.renderViewer()
            });
        }
        // if tabs were added above, set next the editor's place in list
        if (result.length > 0) {
            result.push(null);
        }

        return result;
    }

    protected getExtraTabPanesInternal() {
        if (this.props.mode === EditMode.ADD) {
            return null;
        } else {
            return super.getExtraTabPanesInternal()?.concat(this.getAuditTabPanes());
        }
    }

    protected renderAttachedDashboard(id: number) {
        return <DashboardTabRRC id={"attachedDashboard_" + id} dashboardId={id} entityForAttachedDashboard={this.props.entity} />;
    }

    render() {
        return <AppContainerContext.Consumer>{context =>
            <>
                <Utils.Observer value={context.initializationsForClient.currentOrganizationDropdownValue} didUpdate={() => this.props.match?.params.id && this.onMatchChanged(this.props.match.params)} />
                {super.render()}
            </>
        }</AppContainerContext.Consumer>;
    }
}
"../AppMetaTempGlobals""./CrudHeader""./CrudViewerInEditor""../reduxHelpers""../utils/Utils""../CompMeta""../AppMeta""../AppContainerContext"