import { Point } from "@crispico/react-timeline-10000/types/components/ContextMenu/ContextMenu";
import { castWithHw, ComponentWithScriptableUi, HW, ScriptableUi, ScriptableUiImpl } from "@famiprog-foundation/scriptable-ui";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { Button, Icon, ModalContent } from "semantic-ui-react";
import { apolloClient, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, FOCUS_FIELD_SEARCH_PARAM } from "..";
import { ModalExt } from "../components/ModalExt/ModalExt";
import { EDITOR_PAGE_ICON, ID } from "./entityCrudConstants";
import { SaveFieldsParams } from "./SaveFieldsParams";
import { CrudGlobalSettings } from "./CrudGlobalSettings";

export type EntityEditorInlineState = {
};

export type EntityEditorInlineProps = {
    entity: any,
    entityDescriptor: EntityDescriptor,
    fieldDescriptor?: FieldDescriptor,
    editorPosition: Point | undefined,
    onClose: (withRefresh?: boolean) => void,
    goToFullEditor: (url: string) => void
};

export namespace ScriptableUiEntityEditorInline {
    export class Main extends ScriptableUi<Main> {
        getComponentName() { return "ScriptableUiEntityEditorInline.Main" };

        goToFullEditor() { }
    }
}

export class EntityEditorInline<P, S extends EntityEditorInlineState = EntityEditorInlineState> extends ComponentWithScriptableUi<ScriptableUiEntityEditorInline.Main, P & EntityEditorInlineProps, S> {
    
    protected scriptableUiClass = ScriptableUiEntityEditorInline.Main;
    protected scriptableUiImpl: ScriptableUiImpl<ScriptableUiEntityEditorInline.Main> = {
        goToFullEditor: () => {
            this.props.goToFullEditor(this.props.entityDescriptor.getEntityEditorUrl(this.props.entity.id) + "/edit?" + FOCUS_FIELD_SEARCH_PARAM + "=" + this.props.fieldDescriptor?.name);
            this.props.onClose();
        }
    }

    // Similar with SliceEntityEditorPage.getJavaIdType
    protected getJavaIdType() {
        return this.props.entityDescriptor && this.props.entityDescriptor.javaIdType ?
            this.props.entityDescriptor.javaIdType :
            CrudGlobalSettings.INSTANCE.defaultJavaIdType;
    }

    protected async onUpdateFieldValue(entity: any, entityDescriptorName: string, fieldName: string) {
        let mutation = gql(`mutation q($params: SaveParams_${this.getJavaIdType()}Input) { 
            ${_.lowerFirst(entityDescriptorName)}Service_save(params: $params) {
                __typename
            }
        }`);

        let fieldsAndValues: any = {};

        fieldsAndValues[fieldName] = entity[fieldName];
        if (!fieldsAndValues[fieldName] && entity[fieldName] === undefined) {
            // custom fields values are saved by EntityEditorFormSimple in a 'customFields' field and we need
            // to move them on save in main formik 
            fieldsAndValues[fieldName] = entity["customFields"][fieldName];
        } else if (Array.isArray(fieldsAndValues[fieldName])) {
            // If fields is an array (one-to-many/many-to-many field), we have a list of partial entities (id + miniFields).
            // But for save we need to use only id. So, we need to convert the list of partial objects to a list of ids.
            fieldsAndValues[fieldName] = fieldsAndValues[fieldName].map((fieldsAndValue: any) => fieldsAndValue[ID]);
        }

        const saveFieldsParams: SaveFieldsParams = {
            id: entity.id,
            duplicateFromId: null,
            fieldsAndValues
        };

        await apolloClient.mutate({
            mutation: mutation,
            variables: { params: saveFieldsParams },
        });

        this.props.onClose(true);
    }

    renderWithScriptableUi(s: ScriptableUiEntityEditorInline.Main): React.ReactNode {
        let visibleColumns: { [field: string]: boolean } = {};
        let autoFocusOnField: string | undefined = undefined;

        if (this.props.fieldDescriptor) {
            autoFocusOnField = this.props.fieldDescriptor.name;
            visibleColumns[this.props.fieldDescriptor.name] = true;
        }
        
        return (<ModalExt
            size="tiny"
            className='EntityEditorInline_Modal'
            closeOnDimmerClick={false}
            closeIcon={true}
            header={
                <div className="EntityEditorInline_header">
                    <Icon name={EDITOR_PAGE_ICON} />
                    {_msg("entityCrud.inlineEditor.edit")}
                    <HW id="goToButton"
                        children={hw => <Button content={_msg("entityCrud.inlineEditor.edit.goToButton")}
                            onClick={() => castWithHw(s).goToFullEditor(hw)} />}
                    />
                </div>
            }
            content={
                <ModalContent>
                    <EntityEditorFormSimple entity={this.props.entity as any} entityDescriptor={this.props.entityDescriptor as EntityDescriptor}
                        columnsVisibleMap={visibleColumns} buttonBarPosition="bottom" onCancelHandler={() => this.props.onClose()} autoFocusOnField={autoFocusOnField}
                        onSubmitHandler={(entity) => this.onUpdateFieldValue(entity, this.props.entityDescriptor.name, this.props.fieldDescriptor ? this.props.fieldDescriptor.name : "")} />
                </ModalContent>
            }
            open={!this.props.editorPosition ? false : [this.props.editorPosition.x, this.props.editorPosition.y]}
            onClose={() => this.props.onClose()}
        />);
    }
}