import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { AUDIT_FULL, SETTINGS_FULL, SCHEDULED_TASK_FULL, DELETE_ALL_FILTERED_ROWS_MENU_ENTRY, FILE_BROWSER, FILE_BROWSER_FULL, CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, LOGGED_IN } from "@crispico/foundation-react/utils/Utils";
 
/**
 * Mirror method on java side `PermissionService.getPermissionLabel(...)`. Keep both updated!
 * 
 * includeEntityInfo === false -> label = Access editor page add deny
 * includeEntityInfo === true -> label = Department: Access editor page add deny
*/
export function getPermissionLabel(permission: string, includeEntityInfo?: boolean) {
    if (AppMetaTempGlobals.appMetaInstance.globalPermissions.has(permission)
        || permission === LOGGED_IN
        || permission === AUDIT_FULL
        || permission === SCHEDULED_TASK_FULL
        || permission === SETTINGS_FULL
        || permission === DELETE_ALL_FILTERED_ROWS_MENU_ENTRY
        || permission === FILE_BROWSER
        || permission === FILE_BROWSER_FULL
        || permission === CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY) {
        return _msg("Role.permission." + permission + ".label");
    }

    if (permission.startsWith("ENT_")) {
        const parts = permission.split('|');
        if (includeEntityInfo) {
            if (parts[1] == "global") {
                return _msg("general.general") + ":" + _msg("Role.global." + parts[0] + ".label");
            }
            if (parts.length > 2) {
                return parts[1] + " : " + _msg("Role.global." + parts[0] + '.label', _msg("Role." + parts[2]));
            }
            return parts[1] + " : " + _msg("Role.global." + parts[0] + '.label', _msg("Role.allow"));
        }
        return _msg("Role.global." + parts[0] + ".label", _msg("Role." + ((parts.length > 2) ? parts[2] : "allow")));
    }

    const parts = permission.split('.');
    if (includeEntityInfo) {
        if (parts[0] === 'global') {
            return _msg("general.general") + " : " + _msg("Role." + permission, "");
        }
        if (parts[parts.length - 1] === 'allow' || parts[parts.length - 1] === 'deny') {
            return parts[1] + " : " + _msg("Role.global." + parts[2] + '.' + parts[3], _msg("Role." + parts[4]));
        }
        return parts[1] + "." + EntityDescriptorForServerUtils.getFieldDescriptor(parts[1], parts[parts.length - 1])?.getLabel() + " : " + _msg("Role.one." + parts[2]);
    }

    if (parts.length > 4) {
        return _msg("Role.global." + parts[2] + '.' + parts[3], _msg("Role." + parts[4]));
    }
    return _msg("Role." + permission.split("|")[0], "");
}
"../../AppMetaTempGlobals""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils""../../utils/Utils"