import { DocumentNode } from "graphql";
import { Location } from "history";
import lodash from "lodash";
import { RedirectProps, RouteProps } from "react-router-dom";
import { ENT_READ, Optional, Utils } from "..";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { ModalExtOpen } from "../components/ModalExt/ModalExt";
import { TabbedPage, TabbedPageLocationState, TabbedPageProps, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { Dashboard } from "../pages/dashboard/DashboardEntityDescriptor";

import { Reducers, RRCProps, State } from "../reduxReusableComponents/ReduxReusableComponents";
import { ID } from "./entityCrudConstants";
import { EntityDescriptor } from "./EntityDescriptor";

let DashboardTabRRC: any;

// TODO RM35382: De redenumit fisierul, cf. pct 2/
init();

async function init() {
    const tab = await import("../pages/dashboard/dashboardTab/DashboardTab");
    DashboardTabRRC = tab.DashboardTabRRC;
}

export type AbstractCrudPageLocationState = TabbedPageLocationState & {
    dontCloseDrawer?: boolean
}

class AbstractCrudPageState extends State {
    modalOpen: ModalExtOpen = false;
    attachedDashboards: Dashboard[] = [];
}

class AbstractCrudPageReducers<S extends AbstractCrudPageState = AbstractCrudPageState> extends Reducers<S> {

    /**
     * Now it doesn't do much. But this is an API method called by users; and maybe in
     * the future (when many users will use it) we'll want to do staff here.
     */
    setModalOpen(modalOpen: ModalExtOpen) {
        this.s.modalOpen = modalOpen;
    }
}

export type AbstractCrudPageProps = TabbedPageProps & RRCProps<AbstractCrudPageState, AbstractCrudPageReducers> & {
    entityDescriptor: EntityDescriptor,
    currentLocation: Location;
};

export abstract class AbstractCrudPage<P extends AbstractCrudPageProps> extends TabbedPage<P> {

    constructor(props: P) {
        super(props);
        this.onModalClose = this.onModalClose.bind(this);
    }

    // TODO by CS: these are not used any more in the table; because they are calculated at each invocation;
    // I think we should remove from the editor page as well; and then from here
    loadQuery!: DocumentNode;
    loadOperationName!: string;

    loadParamType!: string;

    /**
     * Returns fields in column name format, example: ["name", "color", "object.type"]
     * Extend this method to add additional fields
     */
    getFieldsToRequest() {
        return Object.keys(this.props.entityDescriptor.fields);
    }

    /**
     * Returns fields in GraphQL format, example: `object { name color }`
     * Override this if the fields/expression cannot be added with the method above
     */
    getAdditionalGraphQl() {
        return "";
    }

    /**
     * Called when the component is created. The overridden function will create the query(es), if they are
     * not already created. This way, this happens as late as possible, when all the descriptors are "stable", i.e.
     * the ones needing override were already overridden. And this, because this process also looks at other
     * descriptors (e.g. Leave -> LeaveType) in getFieldsToRequest(), so the descriptors need to be both there; otherwise
     * the loading order would matter, and there would of course be "race" cases.
     */
    initIfNeeded(): void { };

    getId(entity: any) {
        return entity[ID];
    }

    protected onModalClose() {
        this.props.r.setModalOpen(false);
    }

    componentDidUpdate(prevProps: any) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: P) {
        const { props } = this;

        const locationState: Optional<AbstractCrudPageLocationState> = AppMetaTempGlobals.history.location?.state as AbstractCrudPageLocationState;
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, "Dashboard"])) && this.props.entityDescriptor.hasAttachedDashboards && AppMetaTempGlobals.appMetaInstance.dashboardsAvailable &&
            locationState?.redirectToMain && !lodash.isEqual(prevProps?.s.attachedDashboards, props.s.attachedDashboards)) {
            // it's important to give location.state in order to have the values declared in 
            AppMetaTempGlobals.history.push({ pathname: this.getMainRoutePath(), state: locationState });
            return;
        }
    }

    protected renderAttachedDashboard(id: number, additionalProps: {}) {
        return <DashboardTabRRC id={"attachedDashboard_" + id} dashboardId={id} {...additionalProps} />
    }

    protected getRedirectToFirstPaneRouteProps(redirectProps: RedirectProps): RouteProps {
        const location = AppMetaTempGlobals.history.location;
        // prepare redirect location props by getting them from current location
        const redirectToLocation = redirectProps.to as Location<any>;
        if (location.search) {
            redirectToLocation.search = location.search;
        }
        (redirectToLocation.state as AbstractCrudPageLocationState).dontCloseDrawer = (location?.state as AbstractCrudPageLocationState)?.dontCloseDrawer;

        return super.getRedirectToFirstPaneRouteProps(redirectProps);
    }

    render() {
        if (this.propsCasted.modalProps) {
            // not 100% fond of this way of passing params to super; but let's leave it like this for now
            this.modalProps = { ...this.propsCasted.modalProps, open: this.props.s.modalOpen, onClose: this.onModalClose };
        }
        return super.render();
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const dashboardTabs: Optional<(TabRouterPane | null)[]> = [];
        this.props.s.attachedDashboards?.forEach((attachedDashboard, index) => {
            const dashboard = { ...attachedDashboard };
            dashboardTabs.push({
                routeProps: { path: "/dashboard_" + (index + 1) },
                menuItemProps: { icon: dashboard.icon ? dashboard.icon : "chart pie", content: dashboard.name ? dashboard.name : _msg("Dashboard.label") },
                render: () => this.renderAttachedDashboard(dashboard.id, {})
            });
        });

        return dashboardTabs;
    }

}