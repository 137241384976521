import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";
import TimezoneSelect from 'react-timezone-select';
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export class TimezoneFieldEditor extends FieldEditor<any, FieldEditorProps> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        if (!this.getValue()) {
            s.setFieldValue(hw, "GMT");
        }
        return <TimezoneSelect fluid value={this.getValue()}
            onChange={(tz: any) => s.setFieldValue(hw, tz.value)} labelStyle={'abbrev'} />
    }

}

export default TimezoneFieldEditor;