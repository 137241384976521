import { Optional } from "@crispico/foundation-react";
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import { Plugins, TableProps } from "fixed-data-table-2";
import { Cell, CellProps, Column, ColumnHeaderProps, Table } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';
import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";
import Measure from "react-measure";
import { NavLink } from "react-router-dom";
import { Button, Card, Divider, Header, Icon, Popup, SemanticSIZES } from "semantic-ui-react";
import { Filter } from "../components/CustomQuery/Filter";
import { FilterForm } from "../components/CustomQuery/FilterForm";
import { Sort } from "../components/CustomQuery/SortBar";
import { Severity } from "../components/ModalExt/ModalExt";
import { EnhancedRefObject, Reducers, ReduxReusableComponents, RRCProps, State } from "../reduxReusableComponents/ReduxReusableComponents";
import { FIELDS_READ, Utils } from "../utils/Utils";
import { CrudViewer } from "./CrudViewer";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { EntityNumberDisplayed } from "./EntityNumberDisplayed";
import { EntityTableMode, EntityTableOptions, EntityTablePageProps } from "./EntityTablePage";
import { ContextMenu } from "@crispico/react-timeline-10000";
import { IAction, IActionParamForRun } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import { CustomQueryBar } from "../components/CustomQuery/CustomQueryBar";

export interface ColumnConfigConfig {
    columns: ColumnDefinition[] | null;
}

export interface ColumnDefinition {
    width: number;
    name: string;
}

const CARDS_MAX_FIELDS = 10;
export const CONTENT_MENU_COLUMN_WIDTH = 60;

type LocalState = {
    measuredWidth: number | undefined,
    measuredHeight: number | undefined,
}

export interface ITableActionParamForRun extends IActionParamForRun {
    currentField?: string
}

export const entityTableSimpleTestids = createTestids("EntityTableSimple", {
    headerCell: "",
});

export type EntityTableCommonProps = {
    onSelectItem?: (entityId: any) => void
}

export class EntityTableSimpleState extends State {
    entities = [] as any[];
    /**
     * @see EntityTableSimpleProps.selectedIsRowIndex
     */
    selected = undefined as unknown as number;
    actionParam = undefined as ITableActionParamForRun | undefined;
}

export class EntityTableSimpleReducers<S extends EntityTableSimpleState = EntityTableSimpleState> extends Reducers<S> {

}

export type EntityTableSimpleProps = {
    entityDescriptor: EntityDescriptor,
    columns?: ColumnDefinition[],
    onDoubleClickItem?: (entity: any, rowIndex: number) => void,
    provideActionsForRow?: (actionParam: ITableActionParamForRun) => IAction[],
    provideActionsForCell?: (actionParam: ITableActionParamForRun) => IAction[],
    renderFooter?: () => ReactNode,
    // CS: this was added for Gantt, because it creates empty rows
    // 1/ the name is not good. Maybe renderEmptyCell? 
    // 2/ But maybe a simple boolean e.g. `displayPendingForEmptyCells` would be better
    // 3/ But the above implies that we have a binary behavior: "Pending", for normal tables (actually TablePage), and "", for Gantt tables
    // but, we want in the future to have TablePage (paginated) + Gantt. It means we'll have the 2 cases. Some empty cells will arrive
    // later; some will always be "", because they are added by the Gantt. Let's modify this prop when we arrive at this case. And let's
    // demo it in found also; now the corresponding *.stories, doesn't use this
    renderEmptyRow?: () => ReactNode,

    /**
     * If present => inside ...Page; else => embedded mode
     */
    parentProps?: EntityTablePageProps;
    tableProps?: Partial<TableProps>;
    screen?: string;
    onColumnMoved?: (event: any) => void;
    onColumnResized?: (width: number, name: string) => void;

    /**
     * By default, `selected` (in the Redux state) means the ID of the selected element. 
     * This has usually advantages. However, there are cases where it's more convenient to 
     * remember the selection not by ID but by row index. E.g. if we add multiple (new) elements,
     * ID is not usable, because it's 0 for all new elements.
     */
    selectedIsRowIndex?: boolean,
    mode?: EntityTableMode,
    compactBar?: ReactNode,

    "data-testid"?: string;

    /**
     * When used as ConnectedComponentInSimpleComponent,
     * needed to provide entities + params.
     */
    entitiesAsParams?: any[];
    selectedAsParams?: number;

    getEntityAt?: (index: number) => any | undefined;
    getNextEntities?: (entity: any) => void;
    renderMainElementNormalModeFunction?: (table: ReactElement<Table>) => ReactElement;
    customQueryBarRef?: EnhancedRefObject<CustomQueryBar>;
    options?: EntityTableOptions;
} & RRCProps<EntityTableSimpleState, EntityTableSimpleReducers> & EntityTableCommonProps;

export namespace ScriptableUiEntityTableSimple {
    export class CellComponent extends ScriptableUi<CellComponent> {
        getComponentName() { return "ScriptableUiEntityTableSimple.CellComponent" };

        openCellContextMenu(clientX: number, clientY: number) { }
    }
}

export class EntityTableSimple<P extends EntityTableSimpleProps = EntityTableSimpleProps> extends React.Component<P, LocalState> {
    /**
     * Maybe people need to override this. Hence as a public field. If more customization is needed, should be 
     * tranfsformed into a function receiving params.
     */
    cellComponentType: new (props: any) => Cell = Cell;

    constructor(props: P) {
        super(props);
        this.state = { measuredWidth: undefined, measuredHeight: undefined };
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Readonly<EntityTableSimpleProps>) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: EntityTableSimpleProps) {
        if (this.props.entitiesAsParams && (!prevProps || !_.isEqual(prevProps.entitiesAsParams, this.props.entitiesAsParams))) {
            this.props.r.setInReduxState({ entities: this.props.entitiesAsParams });
        }
        if (this.props.selectedAsParams !== undefined && prevProps && prevProps.selectedAsParams !== this.props.selectedAsParams) {
            this.props.r.setInReduxState({ selected: this.props.selectedAsParams });
        }
    }

    /**
     * @param fieldDescriptorChain Usually we receive only one `FieldDescriptor`. But the user may have chosen a composed/sub field,
     * e.g. for `equipmentResource`: `type.name`. In this case we receive 2 descriptors:
     * * `EquipmentResource.type`
     * * `EquipmentResourceType.name`
     * 
     * We need both of them, in order to properly display the label
     */

    filterFormRef = React.createRef<FilterForm>();
    renderHeaderCell(props: ColumnHeaderProps, fieldDescriptorChain: FieldDescriptor[], index: number, propsColumns: ColumnDefinition[]) {
        const simpleField = fieldDescriptorChain.length === 1 ? fieldDescriptorChain[0] : undefined;
        let composedFieldLabel = '';
        let composedFieldName = '';
        const icon = fieldDescriptorChain[fieldDescriptorChain.length - 1].getIcon();
        if (fieldDescriptorChain.length > 1) {
            for (const fd of fieldDescriptorChain) {
                const l = fd.getLabel();
                composedFieldLabel += l + '.';
                composedFieldName += fd.name + '.';
            }
            composedFieldLabel = composedFieldLabel.substring(0, composedFieldLabel.length - 1);
            composedFieldName = composedFieldName.substring(0, composedFieldName.length - 1);
        }

        const sort = this.props.customQueryBarRef?.current?.props.dispatchers.getState().sortBar.sorts.find((s: Sort) => {
            return s.field === (simpleField ? simpleField?.name : composedFieldName);
        });
        const filter = this.props.customQueryBarRef?.current?.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter.filters?.find((f: Filter) =>
            f.enabled && f.field === (simpleField ? (simpleField!.name + (simpleField!.filterAsManyToMany ? "." + simpleField!.filterAsManyToMany : "")) : composedFieldName));

        let titleColor = '';
        if (filter) {
            titleColor = 'ColumnHeader_hasFilter';
        } else if (sort) {
            titleColor = 'ColumnHeader_hasSort';
        }

        let label: string;
        let isAbbreviated: boolean = false;
        if (!simpleField) {
            let { fieldLabel, abbreviated } = this.props.entityDescriptor.getLabelMaybeAbbreviated(fieldDescriptorChain, propsColumns);
            label = fieldLabel;
            isAbbreviated = abbreviated;
        }
        else {
            label = simpleField.getLabel();
        }
        const content = <this.cellComponentType data-testid={entityTableSimpleTestids.headerCell + "_" + index} {...props} onClick={(e) => this.handleHeaderClicks(e, simpleField?.name, composedFieldName)}>
            <div className='flex-center'>
                <label className={titleColor}>
                    {icon}
                    <Popup
                        wide="very"
                        on='click'
                        content={composedFieldLabel}
                        trigger={<span onClick={(event) => event.stopPropagation()}><u>{isAbbreviated ? "..." : null}</u></span>}
                    />
                    {label}
                </label>
                {sort ? <Icon name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} /> : null}
            </div>
        </this.cellComponentType>;

        if (!this.props.onColumnResized && !this.props.onColumnMoved) {
            return content;
        } else {
            if (this.props.onColumnResized && this.props.onColumnMoved) {
                return <Plugins.ReorderCell
                    {...props}
                    onColumnReorderEnd={(event: any) => this.props.onColumnMoved?.(event)}
                >
                    <Plugins.ResizeCell
                        onColumnResizeEnd={(newColumnWidth: number, columnKey: string) => this.props.onColumnResized?.(newColumnWidth, columnKey)}
                    >
                        {content}
                    </Plugins.ResizeCell>
                </Plugins.ReorderCell>
            } else if (this.props.onColumnResized) {
                return <Plugins.ResizeCell
                    {...props}
                    onColumnResizeEnd={(newColumnWidth: number, columnKey: string) => this.props.onColumnResized?.(newColumnWidth, columnKey)}
                >
                    {content}
                </Plugins.ResizeCell>;
            } else {
                return <Plugins.ReorderCell
                    {...props}
                    onColumnReorderEnd={(event: any) => this.props.onColumnMoved?.(event)}
                >
                    {content}
                </Plugins.ReorderCell>;
            }
        }
    }

    // TODO: delete this after we put back Scriptable on cells
    protected openCellContextMenu(clientX: number, clientY: number, currentField: string, rowIndex?: number) {
        // In EntityTableLight, renderCell (that call this method) wouldn't receive a fieldDescriptorChain.
        // In this case, we can not form a currentField for this method.
        if (currentField === "") {
            return;
        }

        this.props.r.setInReduxState({
            actionParam: {
                currentField,
                eventPoint: { x: clientX, y: clientY },
                selection: rowIndex !== undefined ? [rowIndex] : [],
                closeContextMenu: () => { }
            },
            // In past, rowIndex was set in `selected`, but this caused issues on delete in EntityTablePage, because it needed entity id for delete.
            // Now, we will check to see if selected is a row index to set it to rowIndex (in case of EntityTableLight), or it will
            // contain id of entity from that index.
            selected: this.props.selectedIsRowIndex ? rowIndex : (rowIndex !== undefined ? this.getEntity(rowIndex).id : -1)
        });
    }

    /**
     * May be overridden e.g. to add props directly to `<Cell>`, e.g. a style, etc.
     */
    protected renderCell(props: CellProps, fieldDescriptorChain?: FieldDescriptor[]) {
        const cellContent = this.getCellContent(props, fieldDescriptorChain);
        const fd = cellContent?.fieldDescriptor;
        // Uncommnet also following line, when uncomment Scriptable code 
        // const that = this;
        let currentField = "";
        // This function is also called in EntityTableLight and there we cannot provide a fieldDescriptorChain 
        if (fieldDescriptorChain) {
            for (let i = 0; i < fieldDescriptorChain.length; i++) {
                currentField += fieldDescriptorChain[i].name + (i !== fieldDescriptorChain.length - 1 ? "." : "");
            }
        }

        return (
            // <ScriptableUiEntityTableSimple.CellComponent id={`col_${props.columnKey}_row_${props.rowIndex}_${this.props.entityDescriptor.name}`}
            //     implementation={{
            //     openCellContextMenu(clientX: number, clientY: number) {
            //         if (currentField !== "") {
            //             that.props.r.setInReduxState({
            //                 actionParam: {
            //                     ...that.props.s.actionParam,
            //                     currentField,
            //                     contextMenuPosition: { x: clientX, y: clientY },
            //                     selection: props.rowIndex !== undefined ? [props.rowIndex] : [],
            //                 },
            //                 selected: props.rowIndex
            //             });
            //         }
            //     }
            // }}>
            // {s => <HW id={`col-${props.columnKey}-row-${props.rowIndex}`}>

            // In past we used `onContextMenuCapture` to introduce the context menu, but it has the disadvantage to propagate also
            // the menu on cells that can render modals and the modals couldn't stop the propagation, because the `onContextMenuCapture`
            // stops the possibility of stopping propagation from children. More details can be seen on https://react.dev/reference/react-dom/components/common,
            // sections regarding `onContextMenu` and `onContextMenuCapture`
            <this.cellComponentType {...props}
                onContextMenu={(event) => { event.preventDefault(); this.openCellContextMenu(event.clientX, event.clientY, currentField, props.rowIndex); }}
                className={this.props.s.selected !== undefined && (this.props.s.selected === this.getEntity(props.rowIndex!)?.id || (this.props.selectedIsRowIndex && this.props.s.selected === props.rowIndex)) ? `selectedItem ${props.columnKey}` : `${props.columnKey}`}
                style={{ backgroundColor: fd?.getFieldColors(fd.getFieldValue(cellContent?.entity)).backgroundColor, ...props.style }}
                data-testid={`col-${props.columnKey}-row-${props.rowIndex}`}>
                {cellContent?.content}
            </this.cellComponentType>
            //     </HW>}
            // </ScriptableUiEntityTableSimple.CellComponent>
        );
    }

    protected getCellContent({ rowIndex, columnKey }: CellProps, fieldDescriptorChain?: FieldDescriptor[]): Optional<{ fieldDescriptor?: Optional<FieldDescriptor>, content: any, entity?: any }> {
        let rowObject = this.getEntity(rowIndex!);
        if (!rowObject) {
            return { content: this.props.renderEmptyRow ? this.props.renderEmptyRow() : _msg("entityCrud.table.pending") };
        } else if (fieldDescriptorChain) {
            for (let index = 0; index < fieldDescriptorChain.length - 1; index++) {
                rowObject = rowObject[fieldDescriptorChain[index].getFieldName()]
                if (!rowObject) {
                    break;
                }
            }
            const fd = fieldDescriptorChain[fieldDescriptorChain.length - 1];
            return { fieldDescriptor: fd, entity: rowObject, content: fd.renderField(rowObject) };
        }
        const fd = this.props.entityDescriptor.getField(columnKey as string);
        return { fieldDescriptor: fd, entity: rowObject, content: fd.renderField(rowObject) };
    }

    onSelectItem(itemId: any) {
        this.props.onSelectItem?.call(null, itemId);
    }

    render() {
        return this.renderNewest()
    }

    // CS: :( attributes directly in class; fake double click
    clickCount = 0;
    singleClickTimer: any;
    field = '';

    openFilterFormWithField(entityDescriptor: EntityDescriptor, field: string, position: boolean | [number, number], filterFormRef: React.RefObject<FilterForm>) {
        // CS: :( unfortunately we do here the job of filterBar; we should simply called some function in the filter bar that would 
        // do all the necessary steps
        const filterBar = this.props.customQueryBarRef?.current?.props.dispatchers.filterBar;
        filterBar?.openFilterFormWithField({ entityName: entityDescriptor.name, field: field, rootFilter: this.props.customQueryBarRef?.currentNotNull.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter!, position: position })
    }

    //CZ: I didn't use Cell's handlers because on double click -> onClick handle is called twice and after the double click handler once.
    //That's why i used this approach (setTimeout/clearTimeout)
    handleHeaderClicks(e: any, field: string | undefined, composedFieldName: string) {
        if (!this.props.parentProps || (!field && composedFieldName === "")) {
            return;
        }

        this.clickCount++;

        if (this.clickCount === 1) {
            this.field = field ? field : composedFieldName;
            this.singleClickTimer = setTimeout(() => {
                if (this.field) {
                    if (this.props.entityDescriptor.getField(this.field).sortable) {
                        this.props.customQueryBarRef?.currentNotNull.props.dispatchers.addSortFromColumnHeader(this.field)
                    } else {
                        Utils.showGlobalAlert({ message: _msg('dto_crud.error.notSortable'), severity: Severity.INFO });
                    }
                }
                this.clickCount = 0;
            }, 400);
        } else if (this.clickCount === 2) {
            if (this.field === field ? field : composedFieldName) {
                clearTimeout(this.singleClickTimer);
                if (!field || this.props.entityDescriptor.getField(field).filterable) {
                    let filterIndex = this.props.customQueryBarRef?.currentNotNull.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter.filters?.findIndex((f: Filter) => f.field === (field ? field : composedFieldName));
                    if (filterIndex !== undefined && filterIndex >= 0) {
                        this.props.customQueryBarRef?.currentNotNull.props.dispatchers.filterBar.openFilterForm({ rootFilter: this.props.customQueryBarRef.currentNotNull.props.dispatchers.getState().customQuery?.customQueryDefinitionObject.filter!, index: filterIndex, position: [e.clientX, e.clientY] });
                    } else {
                        this.openFilterFormWithField(this.props.entityDescriptor, field ? field : composedFieldName, [e.clientX, e.clientY], this.filterFormRef);
                    }
                } else {
                    Utils.showGlobalAlert({ message: _msg('dto_crud.error.notFilterable'), severity: Severity.INFO });
                }
                this.clickCount = 0;
            } else {
                this.clickCount = 1;
                this.singleClickTimer = setTimeout(() => {
                    if (field) {
                        if (this.props.entityDescriptor.getField(field).sortable) {
                            this.props.customQueryBarRef?.currentNotNull.props.dispatchers.addSortFromColumnHeader(field)
                        } else {
                            Utils.showGlobalAlert({ message: _msg('dto_crud.error.notSortable'), severity: Severity.INFO });
                        }
                    }
                    this.clickCount = 0;
                }, 400);
            }
        }
    }

    private getRowCount(): number {
        const { parentProps } = this.props;
        if (parentProps) {
            const newRowCount: number = parentProps.s.loaded !== -1 ? parentProps.s.loaded + this.props.options?.bulkSize! : 0;
            return parentProps.s.totalCount !== -1 ? Math.min(newRowCount, parentProps.s.totalCount) : newRowCount;
        }
        return this.props.s.entities.length;
    }

    protected getHeight(): number {
        return this.state.measuredHeight ? this.state.measuredHeight - (this.props.renderFooter ? 50 : 0) : 0;
    }

    public getEntity(rowIndex: number): any | undefined {
        return this.props.getEntityAt?.(rowIndex) || this.props.s.entities[rowIndex];
    }

    protected getContextMenuColumn(): ReactNode {
        return <Column width={CONTENT_MENU_COLUMN_WIDTH} key="menu" cell={props => {
            const { rowIndex } = props;
            return <Cell key={rowIndex}>{this.renderContextMenu(rowIndex)}</Cell>
        }
        } />;
    }

    protected renderContextMenu(index: number, size?: SemanticSIZES) {
        // Type="button" is needed in case the table is inside a form, to overpass the following problem: 
        // 'You submitted a Formik form using a button with an unspecified type attribute. If this is not a submit button, please add type="button"'
        return <Button data-testid={this.props["data-testid"] + "_deleteTableEntryButton" + index} primary icon="bars" type="button"
            size={size} onClick={e => this.props.r.setInReduxState({
                actionParam: {
                    eventPoint: { x: e.clientX, y: e.clientY },
                    currentField: "",
                    selection: [index],
                    closeContextMenu: () => { }
                }
            })} />;
    }

    protected renderColumns() {
        const entityDescriptor = this.props.entityDescriptor;
        let columns: ReactNode[] = [];
        if (this.props.provideActionsForRow) {
            columns.push(this.getContextMenuColumn());
        }

        const defaultCC = entityDescriptor.getDefaultColumnConfig();
        let propsColumns = !Utils.isNullOrEmpty(this.props.columns) ? this.props.columns! : defaultCC.configObject.columns!;
        const authorizedFields = entityDescriptor.getAuthorizedFields(FIELDS_READ, propsColumns.map((column) => column.name))
        propsColumns = propsColumns.filter(column => authorizedFields[column.name]);

        propsColumns.forEach((column, index) => {
            const fieldDescriptorChain = entityDescriptor.getFieldDescriptorChain(column.name);
            if (fieldDescriptorChain.length > 0) {
                columns.push(<Column key={column.name} columnKey={column.name}
                    allowCellsRecycling width={column.width}
                    flexGrow={index === propsColumns.length - 1 ? 1 : undefined}
                    header={props => this.renderHeaderCell(props, fieldDescriptorChain, index, propsColumns.slice(0, index).concat(propsColumns.slice(index + 1)))}
                    cell={props => this.renderCell(props, fieldDescriptorChain)} />)
            }
        });
        return columns;
    }

    protected getNumberOfEntities(): number {
        const { parentProps } = this.props;
        if (parentProps) {
            const loaded: number = parentProps.s.loaded !== -1 ? parentProps.s.loaded : 0;
            return parentProps.s.totalCount !== -1 ? Math.max(loaded, parentProps.s.totalCount) : loaded;
        }
        return this.props.s.entities.length;
    }

    protected renderCard(index: number) {
        const props = this.props;
        const entity = props.s.entities[index];
        return entity ? <Card className="EntityTablePage_card">
            <Card.Content>
                <Card.Header>
                    <span className="flex-container-row">
                        <span className="flex-grow-shrink-no-overflow"><Header size="small" as={NavLink} to={props.entityDescriptor.getEntityEditorUrl(entity.id)}>{props.entityDescriptor.toMiniString(entity)}</Header></span>
                        <span>{this.renderContextMenu(index, "mini")}</span>
                    </span>
                </Card.Header>
                <Card.Description>
                    <CrudViewer entityDescriptor={props.entityDescriptor} maxFields={CARDS_MAX_FIELDS} showEmpty={true}
                        entity={entity} fields={props.columns?.filter(c => c?.name !== undefined).map(c => c.name) as string[]} />
                </Card.Description>
            </Card.Content>
        </Card> : null;
    }

    protected renderCardsMode() {
        const props = this.props;
        const size = props.s.entities.length;

        return <>
            <Card.Group centered data-testid="EntityTablePage_cards">
                {size > 0 ? [...Array(size).keys()].map(index => this.renderCard(index)) : null}
            </Card.Group>
            <Divider />
            <EntityNumberDisplayed ed={props.entityDescriptor} displayed={size} total={this.getNumberOfEntities()} />
            {size !== this.getNumberOfEntities() ? <Button icon="angle double down" content={_msg("general.loadMore")} onClick={() => this.props.getNextEntities?.(props.s.entities[size - 1])} /> : null}
        </>;
    }

    protected renderTable() {
        const columns = this.renderColumns();
        return <Table rowsCount={this.getRowCount()} rowHeight={this.props.tableProps?.rowHeight || 50} width={this.state.measuredWidth || 50} maxHeight={this.getHeight()} headerHeight={this.props.tableProps?.headerHeight || 50} touchScrollEnabled={true}
            onRowClick={(_, rowIndex) => {
                let selectedItem = this.props.selectedIsRowIndex ? rowIndex : this.getEntity(rowIndex)?.id;
                if (selectedItem !== this.props.s.selected) {
                    this.props.r.setInReduxState({ selected: selectedItem });
                    this.onSelectItem(selectedItem);
                }
            }}
            onRowDoubleClick={(_, rowIndex) => this.props.onDoubleClickItem?.(this.getEntity(rowIndex), rowIndex)}
            rowClassNameGetter={index => "row" + index}
            // @ts-ignore 
            rowAttributesGetter={index => { return { "data-testid": "EntityTableSimple_row" + index, "role": "EntityTableSimple_row" } }}
            {...this.props.tableProps}>
            {columns}
        </Table>;
    }

    protected renderNormalMode() {
        const table = this.renderTable();
        return this.props.renderMainElementNormalModeFunction ? this.props.renderMainElementNormalModeFunction(table) :
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                    {table}
                    {this.props.renderFooter ? <div className="EntityTableSimple_footer">{this.props.renderFooter?.()}</div> : null}
                </div>)}
            </Measure>;
    }

    renderNewest() {
        let actions: IAction[] = [];

        if (this.props.s.actionParam) {
            actions = this.props.provideActionsForCell && this.props.s.actionParam.currentField ?
                this.props.provideActionsForCell(this.props.s.actionParam) : [];

            if (this.props.s.actionParam?.currentField && actions.length > 0) {
                actions.push({ renderInMenu: (param) => <Divider /> });
            }

            this.props.provideActionsForRow?.(this.props.s.actionParam).map((action) => {
                actions.push(action);
            });
        }

        return (<>
            <div className="flex-container flex-grow">
                {this.props.compactBar ? this.props.compactBar : null}
                {this.props.mode === EntityTableMode.CARDS ? this.renderCardsMode() : this.renderNormalMode()}
            </div>
            <ContextMenu actions={actions} positionToOpen={this.props.s.actionParam?.eventPoint}
                paramsForAction={{ ...this.props.s.actionParam!, position: "bottom left" }} onClose={() => { this.clearActionParamFields(); }} />
        </>);
    }

    getSelected(): number {
        return this.props.s.selected;
    }

    setSelected(selected: number | undefined) {
        return this.props.r.setInReduxState({ selected });
    }

    clearActionParamFields() {
        this.props.r.setInReduxState({
            actionParam: {
                eventPoint: undefined,
                currentField: undefined,
                selection: [] as any[],
                closeContextMenu: () => { }
            }
        });
    }

    getEntities(): any[] {
        return this.props.s.entities;
    }

    setEntities(entities: any[]) {
        return this.props.r.setInReduxState({ entities });
    }

    addEntity(entity: any) {
        const entities = _.cloneDeep(this.props.s.entities);
        entities.push(entity);
        this.props.r.setInReduxState({ entities: entities });
        return entities;
    }

    updateEntity(entity: any, index: number) {
        const entities = _.cloneDeep(this.props.s.entities);
        entities[index] = entity;
        this.props.r.setInReduxState({ entities: entities });
        return entities;
    }

    deleteSelected() {
        const entities = _.cloneDeep(this.props.s.entities);
        entities.splice(this.props.s.selected, 1);
        this.props.r.setInReduxState({ entities: entities });
        return entities;
    }

}

export const EntityTableSimpleRRC = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, EntityTableSimple);"./.."